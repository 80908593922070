import { UserOutlined } from '@ant-design/icons';
import { Card, Empty, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectIsCreatingUserChangesLoading, selectUserChangesList } from 'redux/slices/analysis';
import { DateTime } from 'luxon';

export default function UserChangesList() {
  const navigate = useNavigate();
  const isCreatingUserChangesLoading = useSelector(selectIsCreatingUserChangesLoading);
  const userChangesList = useSelector(selectUserChangesList);

  const handleLoadUserChanges = (userChangesId: string) => {
    navigate(`userChangesId/${userChangesId}`);
  };

  const parseDateTime = (dateString: Date) => {
    return dateString ? DateTime.fromISO(dateString as unknown as string).toLocaleString(DateTime.DATETIME_MED) : '-';
  };

  return (
    <>
      {userChangesList.length > 0 ? (
        userChangesList.map((userChanges, index) => (
          <Card
            key={index}
            type="inner"
            title={userChanges.name}
            onClick={() => handleLoadUserChanges(userChanges.id)}
            extra={
              <Tooltip title={`Created by ${userChanges.user}`} placement="topRight">
                <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              </Tooltip>
            }
            hoverable={!isCreatingUserChangesLoading}
            style={{ marginTop: '16px' }}
          >
            <div>
              Updated: {parseDateTime(userChanges.updated)}
              <br />
              Created: {parseDateTime(userChanges.created)}
            </div>
          </Card>
        ))
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="There are no User Changesets available for this WSI Result"
          />
        </div>
      )}
    </>
  );
}
