import { Col, Form, Row, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAIScenarios,
  fetchScenarioInfo,
  selectActiveAIScenario,
  selectAIScenarios,
  selectDisplayDiffType,
  selectIsAIScenariosLoading,
  selectIsROIAnalysisLoading,
  selectWSIResultData,
  setActiveAIScenario,
} from 'redux/slices/analysis';
import { AppDispatch } from 'redux/store';
import { useEffect } from 'react';
import { useAuth } from '../auth/AuthProvider';
const { Option } = Select;

export default function AnalysisSelectAIScenario() {
  const { accessToken, aiToken } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const isROIAnalysisLoading = useSelector(selectIsROIAnalysisLoading);
  const activeAIScenario = useSelector(selectActiveAIScenario);
  const isAIScenariosLoading = useSelector(selectIsAIScenariosLoading);
  const aiScenarios = useSelector(selectAIScenarios);
  const activeWSIResult = useSelector(selectWSIResultData);
  const displayDiffType = useSelector(selectDisplayDiffType);

  useEffect(() => {
    if (!accessToken || !activeWSIResult) return;
    dispatch(fetchAIScenarios({ accessToken }));
  }, [dispatch, accessToken, activeWSIResult]);

  const handleAiScenarioChanged = (newValue: string) => {
    dispatch(setActiveAIScenario(newValue));
  };

  useEffect(() => {
    if (!activeAIScenario || !accessToken || !aiToken || !activeWSIResult) return;

    dispatch(
      fetchScenarioInfo({
        aiToken,
        aiScenario: activeAIScenario,
        aiLabId: activeWSIResult?.version.model.split('.')[1],
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiToken, accessToken, activeAIScenario]);

  return (
    <div style={{ margin: '8px' }}>
      <Typography.Text strong>AI Analysis Scenario</Typography.Text>
      <Form.Item style={{ marginTop: '8px' }}>
        <Row>
          <Col flex="1">
            <Select
              value={activeAIScenario}
              onChange={handleAiScenarioChanged}
              disabled={isROIAnalysisLoading || displayDiffType !== 'none'}
              loading={isAIScenariosLoading}
            >
              {aiScenarios.map(aiScenario => {
                return (
                  <Option key={aiScenario.endpoint} value={aiScenario.endpoint}>
                    {aiScenario.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
}
