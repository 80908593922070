import { Badge, Radio, RadioChangeEvent, Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { selectClassifications } from 'redux/slices/analysis';
import {
  selectActiveAnnotationClassification,
  selectIsAnnotatingEnabled,
  setActiveAnnotationClassification,
} from 'redux/slices/viewer';
import { useAppDispatch } from 'utils/hooks';
import { useEffect } from 'react';

export default function ClassificationSelector() {
  const dispatch = useAppDispatch();
  const classifications = useSelector(selectClassifications);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);

  const handleClassificationChange = (e: RadioChangeEvent) => {
    dispatch(setActiveAnnotationClassification(e.target.value));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const selectedClassification = classifications.find(item => item.hot_key.toString() === event.key);

    if (!selectedClassification) {
      return;
    }

    dispatch(setActiveAnnotationClassification(selectedClassification.name));
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotatingEnabled]);

  return (
    <Radio.Group
      onChange={handleClassificationChange}
      defaultValue={classifications.length > 0 ? classifications[0].name : ''}
      disabled={!isAnnotatingEnabled}
      buttonStyle="solid"
      value={activeAnnotationClassification}
      className="annotation-class-selection"
    >
      {classifications.length > 0 &&
        classifications.map(classification => {
          return (
            <Radio.Button key={classification.name} value={classification.name}>
              <Tooltip placement="bottom" title={`${classification.name.replace(/_/g, ' ')}`}>
                <Badge
                  className="classification-button-badge"
                  color={isAnnotatingEnabled ? classification.color : '#aaaaaa'}
                />
                <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                  {classification.hot_key}
                </Tag>
              </Tooltip>
            </Radio.Button>
          );
        })}
    </Radio.Group>
  );
}
