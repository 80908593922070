import { Feature } from 'ol';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import UndoRedo from 'ol-ext/interaction/UndoRedo';
import { Circle, Fill, Stroke, Style } from 'ol/style';

/**
 * Applies a list of cell changes to an OpenLayers VectorSource that contains cells.
 * @param changeset the list of changes to process
 * @param source the OpenLayers Source to apply changes on
 * @param cellSourceWSIResultsOnly readonly layer
 * @param undoInteraction if changeset should be included in undoInteraction for undo/redo functionality
 * @param isManual is changeset manual or from initial WSI Results
 */
export const applyChangesetToSource = (
  changeset: CellChange[],
  source: VectorSource<Point>,
  cellSourceWSIResultsOnly: VectorSource<Point>,
  undoInteraction: UndoRedo | null,
  isManual: boolean,
) => {
  changeset.forEach(change => {
    const sourceFeature = source.getFeatureById(change.id);
    switch (change.type) {
      case 'ADD': {
        const { id, x, y, type, is_reviewed } = change.payload;
        if (sourceFeature) {
          sourceFeature.set('is_selected', 'false');
          sourceFeature.set('is_reviewed', true);
          sourceFeature.set('type', type);
          sourceFeature.set('manual', isManual);
        } else {
          const newAnnotation = new Feature<Point>();
          newAnnotation.setId(id);
          if (x && y) newAnnotation.setGeometry(new Point([x, -y]));
          newAnnotation.set('type', type);
          newAnnotation.set('is_reviewed', is_reviewed);
          newAnnotation.set('is_selected', 'false');
          newAnnotation.set('manual', isManual);
          source.addFeature(newAnnotation);
          if (!isManual) {
            cellSourceWSIResultsOnly.addFeature(newAnnotation);
          }
          if (undoInteraction) {
            undoInteraction.push('ADD', { before: newAnnotation, after: newAnnotation }, 'ADD');
          }
        }
        break;
      }
      case 'DELETE':
        if (sourceFeature) {
          source.removeFeature(sourceFeature);
          if (undoInteraction) {
            undoInteraction.push('DELETE', { before: sourceFeature, after: sourceFeature }, 'DELETE');
          }
        } else {
          console.error('Trying to delete a cell that does not exist on the OL Map');
        }
        break;
      case 'CHANGE_POSITION':
        if (sourceFeature) {
          const newGeometry = new Point([change.payload.x, -change.payload.y]);
          if (undoInteraction) {
            undoInteraction.push(
              'CHANGE_POSITION',
              {
                before: { id: sourceFeature.getId(), geometry: sourceFeature.getGeometry() },
                after: { id: sourceFeature.getId(), geometry: newGeometry },
              },
              'CHANGE_POSITION',
            );
          }
          sourceFeature.setGeometry(newGeometry);
          sourceFeature.set('manual', isManual);
        } else {
          console.error('Trying to edit a cell that does not exist on the OL Map', change);
        }
        break;
      case 'CHANGE_REVIEW_STATUS':
        if (sourceFeature) {
          if (undoInteraction) {
            undoInteraction.push(
              'CHANGE_REVIEW_STATUS',
              {
                before: { id: sourceFeature.getId(), isReviewed: sourceFeature.get('is_reviewed') },
                after: { id: sourceFeature.getId(), isReviewed: change.payload.is_reviewed },
              },
              'CHANGE_REVIEW_STATUS',
            );
          }
          sourceFeature.set('is_reviewed', change.payload.is_reviewed);
          sourceFeature.set('manual', isManual);
        } else {
          console.error('Trying to edit a cell that does not exist on the OL Map', change);
        }
        break;
      case 'CHANGE_TYPE':
        if (sourceFeature) {
          if (undoInteraction) {
            undoInteraction.push(
              'CHANGE_TYPE',
              {
                before: { id: sourceFeature.getId(), type: sourceFeature.get('type') },
                after: { id: sourceFeature.getId(), type: change.payload.type },
              },
              'CHANGE_TYPE',
            );
          }
          sourceFeature.set('type', change.payload.type);
          sourceFeature.set('manual', isManual);
        } else {
          console.error('Trying to edit a cell that does not exist on the OL Map', change);
        }
        break;
      default:
        console.error(`Unknown change type is not implemented`);
        break;
    }
  });
};

/**
 *
 * @param classes Array of Annotation Classifications
 */
export const generateOLAnnotationStyle = (classes: Classification[]) => {
  const stroke = ({ isSelected, isReviewed }: { isSelected: boolean; isReviewed: boolean }) =>
    new Stroke({
      color: isSelected ? '#00ff00' : '#000000',
      width: isSelected ? 3 : isReviewed ? 0 : 1.25,
    });

  return classes.reduce((styles, cls) => {
    // Not selected, not reviewed.
    styles[cls.name + '_false_false'] = new Style({
      image: new Circle({
        fill: new Fill({ color: cls.color }),
        stroke: stroke({ isSelected: false, isReviewed: false }),
        radius: 5,
      }),
    });
    // Not selected, but reviewed.
    styles[cls.name + '_false_true'] = new Style({
      image: new Circle({
        fill: new Fill({ color: cls.color }),
        radius: 5,
      }),
    });
    // Selected but not reviewed
    styles[cls.name + '_true_false'] = new Style({
      image: new Circle({
        fill: new Fill({ color: cls.color }),
        stroke: stroke({ isSelected: true, isReviewed: false }),
        radius: 5,
      }),
    });
    // Selected and reviewed
    styles[cls.name + '_true_true'] = new Style({
      image: new Circle({
        fill: new Fill({ color: cls.color }),
        stroke: stroke({ isSelected: true, isReviewed: true }),
        radius: 5,
      }),
    });
    return styles;
  }, {} as Record<string, Style>);
};
