import { Layout, Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from 'components/auth/AuthProvider';
import { ReactComponent as LogoSVG } from 'assets/images/mindpeak-logoform-digital-light-borderless-ai-calibration-tool.svg';
import { LogoutOutlined } from '@ant-design/icons';

const { Header: AntdHeader } = Layout;

export default function Header() {
  const auth = useAuth();

  const menuItems: MenuProps['items'] = [
    {
      key: 'username',
      label: auth?.user?.username,
      disabled: true,
    },
    {
      key: 'logout',
      label: 'Log Out',
      icon: <LogoutOutlined />,
      onClick: () => {
        auth.logOut();
      },
    },
  ];

  return (
    <AntdHeader
      className="header"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Link to="/">
        <LogoSVG style={{ float: 'left', margin: '8px 24px 16px 0' }} height={48} />
      </Link>
      <div>
        <Menu items={menuItems} theme="dark" mode="horizontal" style={{ minWidth: 0, flex: 'auto' }} />
      </div>
    </AntdHeader>
  );
}
