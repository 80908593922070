import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAnalysisError } from 'redux/slices/analysis';

export default function ErrorPage() {
  const analysisError = useSelector(selectAnalysisError);

  useEffect(() => {
    if (!analysisError) return;
    if (analysisError instanceof Error) {
      Sentry.captureException(analysisError);
    } else {
      Sentry.captureException(new Error(analysisError.message));
    }
    notification['error']({
      key: 'analysisError',
      message: 'Error analysing Image',
      description: analysisError.message,
      duration: 5,
    });
  }, [analysisError]);

  return null;
}
