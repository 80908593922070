import { Tag, Tooltip } from 'antd';
import { Control } from 'ol/control';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { INTERACTION } from 'utils/constants';
import ViewerContext from 'components/Viewer/ViewerContext';
import './style.css';
import { removeFromActiveInteractions, selectIsROIToolActive, setActiveInteractions } from 'redux/slices/userInterface';
import { RootState } from 'redux/store';
import { GatewayOutlined } from '@ant-design/icons';
import { selectIsAnnotatingEnabled } from 'redux/slices/viewer';

export default function CustomROITool() {
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const { map } = useContext(ViewerContext);
  const isROIToolActive = useSelector(selectIsROIToolActive);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);

  const CustomROITool = useRef(new Control({}));

  const createCustomROITool = () => {
    const button = document.createElement('button');
    button.id = 'custom-roi-tool-button';

    const customROIToolSpan = document.createElement('span');
    const root = createRoot(customROIToolSpan);
    root.render(
      <Tooltip
        title={
          <span>
            ROI Tool
            <Tag color="#fafafa" style={{ marginLeft: 8, color: '#000000', border: '1px solid #d9d9d9' }}>
              r
            </Tag>
          </span>
        }
        placement="right"
      >
        <GatewayOutlined />
      </Tooltip>,
    );
    button.appendChild(customROIToolSpan);

    const customROIToolContainerDiv = document.createElement('div');
    customROIToolContainerDiv.className = 'custom-roi-tool ol-unselectable ol-control';

    customROIToolContainerDiv.style.top = '64px';

    customROIToolContainerDiv.appendChild(button);

    button.addEventListener('click', () => {
      const state = store.getState();
      const isROIToolActive = selectIsROIToolActive(state);
      if (isROIToolActive) {
        dispatch(removeFromActiveInteractions(INTERACTION.DRAW_ROI));
      } else {
        dispatch(setActiveInteractions([INTERACTION.DRAW_ROI]));
      }
    });
    return new Control({ element: customROIToolContainerDiv });
  };

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === 'r') {
      const state = store.getState();
      const isROIToolActive = selectIsROIToolActive(state);
      if (isROIToolActive) {
        dispatch(removeFromActiveInteractions(INTERACTION.DRAW_ROI));
      } else {
        dispatch(setActiveInteractions([INTERACTION.DRAW_ROI]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map || !CustomROITool.current) return;

    if (isAnnotatingEnabled) {
      map.addControl(CustomROITool.current);
      document.addEventListener('keydown', __handleKeyDown);
    } else {
      map.removeControl(CustomROITool.current);
      //reset CustomROITool so when control is re-added it is a 'new' instance
      CustomROITool.current = createCustomROITool();
      document.removeEventListener('keydown', __handleKeyDown);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isAnnotatingEnabled]);

  useEffect(() => {
    if (isROIToolActive) {
      const button = document.getElementById('custom-roi-tool-button');
      if (button) {
        button.id = 'custom-roi-tool-button-focus';
      }
    } else {
      const button = document.getElementById('custom-roi-tool-button-focus');
      if (button) {
        button.id = 'custom-roi-tool-button';
      }
    }
  }, [isROIToolActive]);

  return null;
}
