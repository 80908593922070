export const LOCAL_STORAGE_KEY = 'mindpeakFineTuningToolUser';

export const DEFAULT_MICRONS_PER_PIXEL = 0.25;

export const ANALYSIS_STATUS = {
  STARTED: 'started',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  TERMINATED: 'terminated',
  ERROR: 'error',
};

export const DOUBLE_DOTS_PREVENTION_RADIUS_MICRONS = 1.5;

export const OL_LAYER_NAME = {
  ANNOTATIONS: 'ol-annotations-layer',
  ANNOTATIONS_WSI_RESULTS_ONLY: 'ol-annotations-wsi-results-only-layer',
  ERASER_TOOL: 'eraser-tool',
  HIGHLIGHT: 'ol-highlight-layer',
  HEATMAP: 'heatmap-layer',
  HEATMAP_WSI_RESULTS_ONLY: 'heatmap-layer-wsi-results-only',
};

export const TILE_SIDE_LENGTH_INCLUDING_OFFSET = 1536; //Changed due to new model preference for 256 divisible patches
export const MIN_TILE_SIDE_LENGTH = 256; //Changed due to new model preference for 256 divisible patches
export const MAX_SELECTION_AREA = Math.pow(10000, 2);

export const INTERACTION = {
  ANNOTATE: 'annotate',
  DRAW_ROI: 'draw_roi',
  ERASER_TOOL: 'eraser_tool',
};

export const HEATMAP_LAYER_MAX_ZOOM = 5;
export const ANNOTATION_LAYER_MIN_ZOOM = 4;
