import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAnnotationsVisible, setIsAnnotatingEnabled } from 'redux/slices/viewer';
import { ANNOTATION_LAYER_MIN_ZOOM, HEATMAP_LAYER_MAX_ZOOM, OL_LAYER_NAME } from 'utils/constants';
import ViewerContext from 'components/Viewer/ViewerContext';
import { useAppDispatch } from 'utils/hooks';
import VectorImageLayer from 'ol/layer/VectorImage';

import {
  clearActiveWSIResult,
  selectAnnotationOpacity,
  selectChangesetWithPointer,
  selectClassifications,
  selectDisplayDiffType,
  selectIgnoredCellTypes,
  selectUserChanges,
} from 'redux/slices/analysis';
import { useLocation } from 'react-router-dom';

import { generateOLAnnotationStyle } from 'utils/ol';

export default function AnnotationLayerWSIResultsOnly({ source }: AnnotationLayerProps) {
  const { map } = useContext(ViewerContext);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const annotationLayer = useRef<VectorImageLayer<VectorSource<Point>> | null>(null);
  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);
  const classes = useSelector(selectClassifications);
  const displayDiffType = useSelector(selectDisplayDiffType);
  const annotationOpacity = useSelector(selectAnnotationOpacity);
  const userChanges = useSelector(selectUserChanges);
  const changesetWithPointer = useSelector(selectChangesetWithPointer);
  const ignoredCellTypes = useSelector(selectIgnoredCellTypes);

  useEffect(() => {
    if (annotationLayer.current) {
      if (displayDiffType === 'diff' || displayDiffType === 'wsi_results_only') {
        annotationLayer.current?.setVisible(isAnnotationsVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotationsVisible]);

  useEffect(() => {
    if (!annotationLayer.current || !userChanges) return;

    const annotationLayerStyles = generateOLAnnotationStyle(classes);

    if (displayDiffType === 'diff') {
      const reverseOpacityChange = userChanges.changeset
        ? userChanges.changeset.filter(change => change.type === 'DELETE')
        : [];
      const reverseOpacityChangeIds = reverseOpacityChange.map(change => change.id);

      const sessionChanges = changesetWithPointer.filter(change => change.type === 'DELETE');
      const sessionChangesIds = sessionChanges.map(change => change.id);

      const allChangesIds = [...reverseOpacityChangeIds, ...sessionChangesIds];

      annotationLayer.current?.setStyle(feature => {
        //Because we use cells directly from the source, we 'hide' the filtered cells rather than removing them
        if (ignoredCellTypes.includes(feature.get('type'))) return undefined;

        const styleKey = `${feature.get('type')}_false_false`;
        const style = annotationLayerStyles[styleKey];
        if (allChangesIds.includes(feature.getId() as string)) {
          style.getImage().setOpacity(1 - annotationOpacity);
        }
        return style;
      });
    } else {
      annotationLayer.current?.setStyle(feature => {
        //Because we use cells directly from the source, we 'hide' the filtered cells rather than removing them
        if (ignoredCellTypes.includes(feature.get('type'))) return undefined;
        const styleKey = `${feature.get('type')}_false_false`;
        return annotationLayerStyles[styleKey];
      });
    }

    annotationLayer.current?.changed();
  }, [annotationOpacity, displayDiffType, changesetWithPointer, classes, userChanges, ignoredCellTypes]);

  useEffect(() => {
    dispatch(clearActiveWSIResult());
    dispatch(setIsAnnotatingEnabled(false));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (!annotationLayer.current) return;
    if (displayDiffType === 'none') {
      annotationLayer.current?.setVisible(false);
    }

    if (displayDiffType === 'diff') {
      annotationLayer.current?.setVisible(true);
    }

    if (displayDiffType === 'wsi_results_only') {
      annotationLayer.current?.setVisible(true);
    }
  }, [displayDiffType]);

  useEffect(() => {
    if (!map || !classes || classes.length === 0) return;

    const annotationLayerStyles = generateOLAnnotationStyle(classes);

    annotationLayer.current = new VectorImageLayer({
      properties: {
        name: OL_LAYER_NAME.ANNOTATIONS_WSI_RESULTS_ONLY,
      },
      className: OL_LAYER_NAME.ANNOTATIONS_WSI_RESULTS_ONLY,
      source,
      zIndex: 1,
      minZoom: ANNOTATION_LAYER_MIN_ZOOM,
      style: feature => {
        //Because we use cells directly from the source, we 'hide' the filtered cells rather than removing them
        if (ignoredCellTypes.includes(feature.get('type'))) return undefined;
        const styleKey = `${feature.get('type')}_${feature.get('is_selected')}_${feature.get('is_reviewed')}`;
        return annotationLayerStyles[styleKey];
      },
    });

    map.addLayer(annotationLayer.current);

    map.getView().on('change:resolution', event => {
      if (!annotationLayer.current) return;

      // 'Fade in' Layer as user zooms in
      const newZoom = event.target.getZoom();
      if (newZoom < HEATMAP_LAYER_MAX_ZOOM && newZoom > ANNOTATION_LAYER_MIN_ZOOM) {
        const newOpacity = 1 - (HEATMAP_LAYER_MAX_ZOOM - newZoom);
        annotationLayer.current.setOpacity(newOpacity);
      }
    });

    annotationLayer.current?.setVisible(false);

    return () => {
      if (map && annotationLayer.current) {
        map.removeLayer(annotationLayer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, map, source, classes]);

  return null;
}
