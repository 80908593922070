import { backendUrl, getFromBackendPromise, getRequestWithAuthToken } from 'service/api';
import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from 'redux/store';

export const fetchAssignmentsImageServer = createAsyncThunk<Array<any>, string, { state: RootState }>(
  'assignments/fetchAssignmentsImageServer',
  async (accessToken, thunkAPI) => {
    thunkAPI.dispatch(setIsCasesLoading(true));
    thunkAPI.dispatch(setActiveImage(null));

    const { cases: existingCases } = thunkAPI.getState().assignments;

    // If cases have already been loaded, don't call endpoint again
    if (existingCases && existingCases.length > 0) return existingCases;

    const getUserProjectsAssignmentsUrl = backendUrl('fine_tuning/get_user_fine_tuning_project_assignments/');

    const projectAssignments: Array<any> = await getFromBackendPromise(
      getUserProjectsAssignmentsUrl,
      thunkAPI.signal,
      accessToken,
    );

    let projectsImages: Array<Image> = [];

    const projectsAndCases = [
      ...projectAssignments.reduce((acc, a) => {
        if (!a.project) return acc;
        const { cases, images } = a.project;
        acc.push(...cases);
        projectsImages.push(...images);
        return acc;
      }, [] as Case[]),
    ].reduce((acc, c) => {
      acc[c.id] = c;
      return acc;
    }, {});

    projectsImages.forEach(image => {
      if (!image.case_id) {
        if (!('null' in projectsAndCases)) {
          projectsAndCases['null'] = {
            id: 'null',
            name: 'No Case',
            description: 'All images that do not have a case associated',
            images: [],
          };
        }
        projectsAndCases['null'].images.push(image);
      } else {
        if (projectsAndCases[image.case_id]) {
          projectsAndCases[image.case_id].images.push(image);
        } else {
          projectsAndCases[image.case_id] = { id: image.case_id, name: image.case_name, images: [image] };
        }
      }
    });

    return Object.values(projectsAndCases) as Case[];
  },
);

export const fetchActiveCaseDetails = createAsyncThunk<
  any,
  { activeCaseId: string; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('assignments/fetchActiveCaseDetails', async ({ activeCaseId, accessToken }, thunkAPI) => {
  if (!activeCaseId) {
    thunkAPI.rejectWithValue('No Case selected');
  }

  const { cases } = thunkAPI.getState().assignments;

  let caseAssignment = null;
  if (cases && cases.length > 0) {
    caseAssignment = cases.find((caseData: any) => caseData.id === activeCaseId);
  }

  if (caseAssignment) {
    return caseAssignment;
  } else {
    const caseDetailsUrl = backendUrl('fine_tuning/get_fine_tuning_case_details');
    caseDetailsUrl.searchParams.append('id', activeCaseId);
    const caseDetailsResponse = await getRequestWithAuthToken(caseDetailsUrl, thunkAPI.signal, accessToken);

    if (caseDetailsResponse.status > 400) {
      return thunkAPI.rejectWithValue(null);
    }

    return await caseDetailsResponse.json();
  }
});

export const fetchActiveImageDetails = createAsyncThunk<
  Image,
  { activeCaseId: string; activeImageId: string; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('calibration/fetchActiveImageDetails', async (payload, thunkAPI) => {
  const { activeCaseId, activeImageId, accessToken } = payload;

  if (!activeCaseId) {
    thunkAPI.rejectWithValue('No Case selected');
  }

  if (!activeImageId) {
    thunkAPI.rejectWithValue('No Image selected');
  }

  const { cases } = thunkAPI.getState().assignments;

  let activeCase = null;
  if (cases && cases.length > 0) {
    activeCase = cases.find((caseData: any) => caseData.id === activeCaseId);
  }

  if (activeCase) {
    const activeImage = activeCase.images.find((image: Image) => image.id === activeImageId);
    if (activeImage) {
      return activeImage;
    } else {
      return thunkAPI.rejectWithValue('Image does not exist in Case');
    }
  } else {
    const caseDetailsUrl = backendUrl('fine_tuning/get_fine_tuning_case_details');
    caseDetailsUrl.searchParams.append('id', activeCaseId);
    const caseDetailsResponse = await getRequestWithAuthToken(caseDetailsUrl, thunkAPI.signal, accessToken);
    const caseDetails = await caseDetailsResponse.json();
    const activeImage = caseDetails.images.find((image: Image) => image.id === activeImageId);

    if (activeImage) {
      return activeImage;
    } else {
      return thunkAPI.rejectWithValue('Image does not exist in Case');
    }
  }
});

export interface AssignmentsState {
  activeCaseId: string | undefined;
  activeCase: Case | null;
  isActiveCaseLoading: boolean;
  activeCaseError: SerializedError | null;
  activeImage: Image | null;
  isActiveImageLoading: boolean;
  activeImageError: SerializedError | null;
  cases: Case[];
  images?: Image[];
  isCasesLoading: boolean;
  showWSIPolygons: boolean;
}

export const initialState: AssignmentsState = {
  activeCaseId: undefined,
  activeCase: null,
  isActiveCaseLoading: true,
  activeCaseError: null,
  activeImage: null,
  isActiveImageLoading: true,
  activeImageError: null,
  cases: [],
  images: undefined,
  isCasesLoading: true,
  showWSIPolygons: false,
};

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    setActiveImage: (state, action) => {
      state.activeImage = action.payload;
    },
    setIsCasesLoading: (state, action: PayloadAction<boolean>) => {
      state.isCasesLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAssignmentsImageServer.fulfilled, (state, action) => {
      state.cases = action.payload;
      state.isCasesLoading = false;
    });

    builder.addCase(fetchAssignmentsImageServer.rejected, state => {
      state.isCasesLoading = false;
    });

    builder.addCase(fetchActiveCaseDetails.pending, state => {
      state.isActiveCaseLoading = true;
    });

    builder.addCase(fetchActiveCaseDetails.fulfilled, (state, action) => {
      state.isActiveCaseLoading = false;
      state.activeCaseError = null;
      state.activeCase = action.payload;
    });

    builder.addCase(fetchActiveCaseDetails.rejected, (state, payload) => {
      const { error } = payload;

      if (!error.message || error.message === '') {
        error.message = 'Unable to load project - please try again later';
      }

      state.activeCase = null;
      state.isActiveCaseLoading = false;
      state.activeCaseError = error;
    });

    builder.addCase(fetchActiveImageDetails.pending, state => {
      state.isActiveImageLoading = true;
    });

    builder.addCase(fetchActiveImageDetails.fulfilled, (state, action) => {
      state.isActiveImageLoading = false;
      state.activeImage = action.payload;
    });

    builder.addCase(fetchActiveImageDetails.rejected, (state, payload) => {
      const { error } = payload;

      if (!error.message || error.message === '') {
        error.message = 'Unable to load Image - please try again later';
      }

      state.isActiveImageLoading = false;
      state.activeImageError = error;
    });
  },
});

export const { setActiveImage, setIsCasesLoading } = assignmentsSlice.actions;

export default assignmentsSlice.reducer;

export const selectActiveImage = (state: RootState) => state.assignments.activeImage;
export const selectIsActiveImageLoading = (state: RootState) => state.assignments.isActiveImageLoading;
export const selectCases = (state: RootState) => state.assignments.cases;
export const selectIsCasesLoading = (state: RootState) => state.assignments.isCasesLoading;
export const selectActiveCase = (state: RootState) => state.assignments.activeCase;
export const selectActiveCaseError = (state: RootState) => state.assignments.activeCaseError;

export const selectCasesAndNoCase = createSelector([selectCases], cases => {
  if (!cases) return [];
  return cases.map(c => ({
    ...c,
    images: [...c.images].sort((a, b) => {
      // Images are sorted by name, except HE staining comes first.
      if (a.staining === 'HE' && b.staining !== 'HE') {
        return -1;
      }
      if (a.staining !== 'HE' && b.staining === 'HE') {
        return 1;
      }
      if (!a.name) return 0;
      return a.name.localeCompare(b.name);
    }),
  }));
});

export const selectAllImages = createSelector([selectCases], cases => {
  if (!cases) return [];

  let images: Array<Image> = [];
  cases.forEach(c => {
    images.push(...c.images);
  });

  //Filter out duplicates
  images = images.filter((value, index, self) => index === self.findIndex(t => t.name === value.name));

  return images.sort((a, b) => a.name.localeCompare(b.name));
});
