import { useDispatch, useSelector } from 'react-redux';
import { Switch, Tag, Tooltip } from 'antd';
import { selectIsHeatmapEnabled, setIsAnnotatingPrevented, setIsHeatmapEnabled } from 'redux/slices/viewer';
import { useCallback, useEffect } from 'react';
import { selectOpenModal } from 'redux/slices/userInterface';
import { selectDisplayDiffType } from '../../../redux/slices/analysis';

export default function HeatmapSwitch() {
  const dispatch = useDispatch();
  const isHeatmapEnabled = useSelector(selectIsHeatmapEnabled);
  const openModal = useSelector(selectOpenModal);
  const displayDiffType = useSelector(selectDisplayDiffType);

  const handleEnableDisableHeatmap = (isChecked: boolean) => {
    dispatch(setIsHeatmapEnabled(isChecked));
    dispatch(setIsAnnotatingPrevented(isChecked));
  };

  const __handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (openModal) return;
      event.preventDefault();
      if (event.key === 'h') {
        dispatch(setIsHeatmapEnabled(!isHeatmapEnabled));
      }
    },
    [dispatch, isHeatmapEnabled, openModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', __handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
    };
  }, [__handleKeyDown]);

  if (displayDiffType !== 'none') return null;

  return (
    <Tooltip
      title={
        <span>
          Enable/Disable Heatmap
          <Tag style={{ marginLeft: 4, marginRight: 0, color: 'white' }}>h</Tag>
        </span>
      }
    >
      Heatmap:
      <Switch
        checked={isHeatmapEnabled}
        checkedChildren="On"
        unCheckedChildren="Off"
        defaultChecked
        onClick={handleEnableDisableHeatmap}
        style={{ marginLeft: '4px' }}
      />
    </Tooltip>
  );
}
