import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Radio, RadioChangeEvent, Tag, Tooltip } from 'antd';
import { faCircle, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';
import { DiffAnnotationOpacitySlider } from 'components/ViewerToolbar/DiffAnnotationOpacitySlider';
import { useDispatch, useSelector } from 'react-redux';
import { selectDisplayDiffType, selectUserChanges, setDisplayDiffType } from 'redux/slices/analysis';
import { setActiveInteractions } from 'redux/slices/userInterface';
import { setIsAnnotatingEnabled } from 'redux/slices/viewer';
import { useCallback, useEffect } from 'react';

export default function DiffSelector() {
  const dispatch = useDispatch();
  const displayDiffType = useSelector(selectDisplayDiffType);
  const activeUserChanges = useSelector(selectUserChanges);

  const handleOnDiffSelectorChange = (e: RadioChangeEvent) => {
    const newDisplayDiffType = e.target.value as DisplayDiffType;
    dispatch(setDisplayDiffType(newDisplayDiffType));

    if (newDisplayDiffType !== 'none') {
      dispatch(setIsAnnotatingEnabled(false));
      dispatch(setActiveInteractions(['none']));
    }
  };

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === 'f') {
      dispatch(setDisplayDiffType('none'));
    }

    if (event.key === 'd') {
      dispatch(setDisplayDiffType('diff'));
      dispatch(setIsAnnotatingEnabled(false));
      dispatch(setActiveInteractions(['none']));
    }

    if (event.key === 'w') {
      dispatch(setDisplayDiffType('wsi_results_only'));
      dispatch(setIsAnnotatingEnabled(false));
      dispatch(setActiveInteractions(['none']));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', __handleKeyDown);

    return () => {
      document.removeEventListener('keydown', __handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Radio.Group
      onChange={handleOnDiffSelectorChange}
      defaultValue={displayDiffType}
      buttonStyle="solid"
      value={displayDiffType}
      className="annotation-class-selection"
      disabled={!activeUserChanges}
    >
      <Radio.Button key="none" value="none">
        <Tooltip
          title={
            <span>
              Finetuned
              <Tag style={{ marginLeft: 4, marginRight: 0, color: 'white' }}>f</Tag>
            </span>
          }
        >
          Finetuned <FontAwesomeIcon icon={faCircle} />
        </Tooltip>
      </Radio.Button>
      <Radio.Button key="diff" value="diff">
        <Popover
          content={<DiffAnnotationOpacitySlider />}
          title="Finetuned Annotation Visibility"
          trigger={displayDiffType === 'diff' ? 'click' : []}
        >
          <Tooltip
            title={
              <span>
                Diff
                <Tag style={{ marginLeft: 4, marginRight: 0, color: 'white' }}>d</Tag>
              </span>
            }
          >
            Diff <FontAwesomeIcon icon={faCircleHalfStroke} />
          </Tooltip>
        </Popover>
      </Radio.Button>
      <Radio.Button key="wsi_results_only" value="wsi_results_only">
        <Tooltip
          title={
            <span>
              WSI Results Only
              <Tag style={{ marginLeft: 4, marginRight: 0, color: 'white' }}>w</Tag>
            </span>
          }
        >
          WSI Results Only <FontAwesomeIcon icon={faCircleEmpty} />
        </Tooltip>
      </Radio.Button>
    </Radio.Group>
  );
}
