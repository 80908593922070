import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { getRequestWithAuthToken, readerUrl } from 'service/api';
import { notification } from 'antd';

export const fetchImageInfoJSON = createAsyncThunk<
  ImageInfo,
  { currentImage: Image; accessToken: string },
  { state: RootState }
>('annotationDetails/fetchImageInfoJSON', async ({ currentImage, accessToken }, thunkAPI) => {
  const imageInfoUrl = readerUrl(`${currentImage.name}${currentImage.file_type}/info.json`);
  const response = await getRequestWithAuthToken(imageInfoUrl, thunkAPI.signal, accessToken);
  if (response.ok) {
    return response.json();
  } else {
    thunkAPI.rejectWithValue('Unable to get Image Info');
    return null;
  }
});

interface ImageInfoSlice {
  imageInfo: ImageInfo;
}

export const initialState = {
  imageInfo: null as unknown as ImageInfo,
} as ImageInfoSlice;

export const imageInfo = createSlice({
  name: 'annotationDetails',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchImageInfoJSON.fulfilled, (state, action) => {
      state.imageInfo = action.payload;
    });
    builder.addCase(fetchImageInfoJSON.rejected, () => {
      notification['error']({
        message: 'Failed to load Image information',
      });
    });
  },
});

export const selectImageInfo = (state: RootState) => state.imageInfo.imageInfo;

export default imageInfo.reducer;
