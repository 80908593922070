import { notification } from 'antd';
import ViewerContext from 'components/Viewer/ViewerContext';
import IIIFInfo from 'ol/format/IIIFInfo';
import OLTileLayer from 'ol/layer/Tile';
import Projection from 'ol/proj/Projection';
import IIIF from 'ol/source/IIIF';
import View from 'ol/View';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchImageInfoJSON, selectImageInfo } from 'redux/slices/imageInfo';
import { incrementTileLoadProgressLoaded, incrementTileLoadProgressLoading } from 'redux/slices/userInterface';
import { authenticatedTileLoader } from 'service/api';
import { DEFAULT_MICRONS_PER_PIXEL } from 'utils/constants';
import { useAuth } from 'components/auth/AuthProvider';
import { useAppDispatch } from 'utils/hooks';
import { selectActiveImage } from 'redux/slices/assignments';

export default function WholeSlideImageLayer() {
  const dispatch = useAppDispatch();
  const { map } = useContext(ViewerContext);
  const auth = useAuth();
  const [source, setSource] = useState<IIIF>();
  const currentImage = useSelector(selectActiveImage);
  const imageInfo = useSelector(selectImageInfo);

  const wholeSlideImageLayer = new OLTileLayer({
    className: 'whole-slide-image-layer',
    preload: Infinity,
  });

  useEffect(() => {
    if (!currentImage || !auth.accessToken) return;
    dispatch(fetchImageInfoJSON({ currentImage, accessToken: auth.accessToken }));
  }, [dispatch, currentImage, auth.accessToken]);

  useEffect(() => {
    if (!imageInfo) return;

    let imageInfoJSONUnfrozen = JSON.parse(JSON.stringify(imageInfo));
    let iiifInfo = new IIIFInfo(imageInfoJSONUnfrozen).getTileSourceOptions();

    if (iiifInfo === undefined || iiifInfo.version === undefined) {
      notification['error']({
        message: 'Error loading Image',
      });
      return;
    }

    iiifInfo.zDirection = -1;

    const iiifTileSource = new IIIF(iiifInfo);
    iiifTileSource.setTileLoadFunction((tile, src) => authenticatedTileLoader(tile, src, auth.accessToken));
    setSource(iiifTileSource);
  }, [imageInfo, auth.accessToken]);

  useEffect(() => {
    if (!map || !source || !imageInfo) return;

    wholeSlideImageLayer.setSource(source);

    map.addLayer(wholeSlideImageLayer);
    wholeSlideImageLayer.setZIndex(0);

    const micronsPerPixel = imageInfo.micronsPerPixel ?? DEFAULT_MICRONS_PER_PIXEL;

    const projection = new Projection({
      code: 'custom_projection',
      getPointResolution(resolution) {
        return resolution * micronsPerPixel * 0.000001;
      },
      units: 'pixels',
      extent: [0, 0, imageInfo.width, imageInfo.height],
    });

    map.setView(
      new View({
        projection,
        maxZoom: 20,
        minZoom: 0,
        extent: source.getTileGrid()!.getExtent(),
        showFullExtent: true,
        smoothExtentConstraint: false,
      }),
    );

    map.getView().fit(source.getTileGrid()!.getExtent());

    map.getView().on('change:rotation', event => {
      const view = event.target;

      const CustomRotationArrow = document.getElementsByClassName(
        'custom-rotation-arrow ol-unselectable ol-control',
      )[0] as HTMLElement;
      //this check deals with hiding CustomRotationArrow correctly if radian value is >(2 * Math.PI) or <(2 * Math.PI)...
      // e.g. a user has rotated many times without resetting the rotation
      if (view.getRotation() === 0 || view.getRotation() % (2 * Math.PI) === 0) {
        CustomRotationArrow.style.opacity = '0.3';
        const CustomRotationArrowArrow = document.getElementById('custom-rotation-arrow-icon');
        if (CustomRotationArrowArrow) {
          CustomRotationArrowArrow.style.transform = `rotate(${view.getRotation()}rad)`;
        }
      } else {
        CustomRotationArrow.style.opacity = '1';
        const CustomRotationArrowArrow = document.getElementById('custom-rotation-arrow-icon');
        if (CustomRotationArrowArrow) {
          CustomRotationArrowArrow.style.transform = `rotate(${view.getRotation()}rad)`;
        }
      }
    });

    map.updateSize();

    source.on('tileloadstart', function () {
      dispatch(incrementTileLoadProgressLoading());
    });
    source.on(['tileloadend', 'tileloaderror'], function () {
      dispatch(incrementTileLoadProgressLoaded());
    });

    return function cleanup() {
      if (map) {
        map.removeLayer(wholeSlideImageLayer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return null;
}
