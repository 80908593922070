import HeatmapLegend from 'components/HeatmapLegend';
import Interactions from 'components/Interactions';
import AnnotationInteraction from 'components/Interactions/AnnotationInteraction';
import Layers from 'components/Layers';
import AnnotationLayerWSIResultsOnly from 'components/Layers/AnnotationLayerWSIResultsOnly';
import AnnotationLayerHeatmap from 'components/Layers/AnnotationLayerHeatmap';
import AnnotationLayerWSIResultsOnlyHeatmap from 'components/Layers/AnnotationLayerWSIResultsOnlyHeatmap';
import WholeSlideImageLayer from 'components/Layers/WholeSlideImageLayer';
import Viewer from 'components/Viewer';
import TileLoadProgressBar from 'components/TileLoadProgressBar';
import AnnotationLayer from 'components/Layers/AnnotationLayer';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import { Polygon as OLPolygon } from 'ol/geom';
import { Empty, Spin } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectCellSource,
  selectCellSourceWSIResultsOnly,
  selectIsAnalysisResultsLoading,
  selectIsSavingChangeset,
  selectIsUserChangesLoading,
} from 'redux/slices/analysis';
import { selectActiveImage, selectIsActiveImageLoading } from 'redux/slices/assignments';
import './style.css';
import { useState } from 'react';
import ROISelectionInteraction from 'components/Interactions/ROISelectionInteraction';
import CustomROITool from '../CustomControls/CustomROITool';
import CustomControls from '../CustomControls';
import EraserToolLayer from 'components/Layers/EraserToolLayer';
import EraserToolInteraction from 'components/Interactions/EraserToolInteraction';
import CustomEraserTool from 'components/CustomControls/CustomEraserTool';

export default function ViewerContainer() {
  const activeImage = useSelector(selectActiveImage);
  const isAnalysisResultsLoading = useSelector(selectIsAnalysisResultsLoading);
  const isSavingChangeset = useSelector(selectIsSavingChangeset);
  const isUserChangesLoading = useSelector(selectIsUserChangesLoading);
  const isLoadingActiveImage = useSelector(selectIsActiveImageLoading);

  const cellSource = useSelector(selectCellSource);
  const cellSourceWSIResultsOnly = useSelector(selectCellSourceWSIResultsOnly);
  const [eraserToolSource] = useState<VectorSource<Point>>(new VectorSource({ features: [] }));
  const [freehandMultiSelectSource] = useState<VectorSource<OLPolygon>>(new VectorSource({ features: [] }));

  const roiSelectionSource = new VectorSource({
    features: [],
  });

  let spinnerText = '';
  if (isAnalysisResultsLoading) {
    spinnerText = 'Loading Analysis...';
  }
  if (isUserChangesLoading) {
    spinnerText = 'Loading User Changes...';
  }

  if (isSavingChangeset) {
    spinnerText = 'Saving Changes...';
  }

  const spinOnClick = (e: MouseEvent) => {
    e.preventDefault();
  };

  if (isLoadingActiveImage)
    return (
      <Spin
        // @ts-ignore - using to fix Property 'onContextMenu' does not exist on type 'IntrinsicAttributes & SpinProps'.
        id="viewer-spin-container"
        spinning
        tip="Loading Image Data..."
        // @ts-ignore - using to fix Property 'onContextMenu' does not exist on type 'IntrinsicAttributes & SpinProps'.
        onContextMenu={spinOnClick}
        style={{ height: '100%' }}
      />
    );

  return (
    <>
      {activeImage ? (
        <Spin
          // @ts-ignore - using to fix Property 'onContextMenu' does not exist on type 'IntrinsicAttributes & SpinProps'.
          id="viewer-spin-container"
          spinning={isAnalysisResultsLoading || isUserChangesLoading || isSavingChangeset}
          tip={spinnerText}
          // @ts-ignore - using to fix Property 'onContextMenu' does not exist on type 'IntrinsicAttributes & SpinProps'.
          onContextMenu={spinOnClick}
          style={{ height: '100%' }}
        >
          <Viewer>
            <Layers>
              <WholeSlideImageLayer />
              <AnnotationLayerWSIResultsOnly source={cellSourceWSIResultsOnly} />
              <AnnotationLayer source={cellSource.source} />
              <AnnotationLayerHeatmap source={cellSource.source} />
              <AnnotationLayerWSIResultsOnlyHeatmap source={cellSourceWSIResultsOnly} />
              <EraserToolLayer source={eraserToolSource} />
            </Layers>
            <Interactions>
              <AnnotationInteraction source={cellSource.source} freehandMultiSelectSource={freehandMultiSelectSource}/>
              <ROISelectionInteraction source={roiSelectionSource} cellSource={cellSource.source} />
              <EraserToolInteraction eraserToolSource={eraserToolSource} cellSource={cellSource.source} />
            </Interactions>
            <CustomControls>
              <CustomROITool />
              <CustomEraserTool />
              <HeatmapLegend />
            </CustomControls>
            <TileLoadProgressBar />
          </Viewer>
        </Spin>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#ffffff',
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={<span>Please select an Image from the sidebar</span>}
          />
        </div>
      )}
    </>
  );
}
