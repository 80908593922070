import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal } from 'antd';
import logo from 'assets/images/mindpeak_logoform_digital_purple_borderless.png';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'components/auth/AuthProvider';
import { parseJwt } from 'utils/utils';
import { selectOpenModal, setOpenModal } from 'redux/slices/userInterface';

export const ReAuthDialog = () => {
  const dispatch = useDispatch();
  const { accessToken, loggingIn, loginError, logIn, refreshAuthentication } = useAuth();
  const modalOpen = useSelector(selectOpenModal);

  useEffect(() => {
    if (!accessToken) return;
    const parsedAccessToken = parseJwt(accessToken);
    const { exp, iat } = parsedAccessToken;
    const checkAuthState = () => {
      // If there is an "iat" field, the token is potentially refreshable
      if (iat) {
        // ... if the token is not expired
        if (exp * 1000 > Date.now()) {
          // If the token is older than 12 hours, we want to refresh it
          if ((iat + 43200) * 1000 < Date.now()) {
            refreshAuthentication(() => {
              dispatch(setOpenModal('re-auth-modal'));
            });
          }
          // The token is still valid, or got refreshed. Everything is fine.
        }
      } else {
        // If we reach here, we need to show the re-auth dialog
        dispatch(setOpenModal('re-auth-modal'));
      }
    };

    const _onLoadListener = () => checkAuthState();

    const _onVisibilityChangeListener = () => {
      if (document.visibilityState === 'visible') {
        checkAuthState();
      }
    };

    if (document.readyState === 'complete') {
      checkAuthState();
    } else {
      window.addEventListener('load', _onLoadListener);
    }

    window.addEventListener('visibilitychange', _onVisibilityChangeListener);

    return () => {
      // Clean up when unmounting component.
      window.removeEventListener('load', _onLoadListener);
      window.removeEventListener('visibilitychange', _onVisibilityChangeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessToken]);

  function handleSubmit(values: { username: string; password: string }) {
    logIn({ username: values.username.trim(), password: values.password }, () => {
      dispatch(setOpenModal(null));
    });
  }

  return (
    <Modal
      open={modalOpen === 're-auth-modal'}
      okText="Login"
      footer={null}
      style={{ overflowY: 'hidden' }}
      closable={false}
      maskClosable={false}
      keyboard
    >
      <Form initialValues={{ remember: true }} layout="vertical" onFinish={handleSubmit}>
        <Form.Item style={{ textAlign: 'center' }}>
          <img width={360} src={logo} alt="MindPeak Logo" />
        </Form.Item>
        <Form.Item>
          <Alert message="Your session has expired. Please log in again." type="warning" showIcon />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" size="large" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            style={{ width: '100%', marginTop: 16 }}
            loading={loggingIn}
            size="large"
          >
            {loggingIn ? 'Logging In' : 'Log in'}
          </Button>
        </Form.Item>
        {loginError && <Alert message={loginError.name} description={loginError.message} type="error" showIcon />}
      </Form>
    </Modal>
  );
};

export default ReAuthDialog;
