import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAnnotationClassification, setIsAnnotatingEnabled } from 'redux/slices/viewer';
import {
  backendUrl,
  getAIScenarioRequest,
  getRequestWithAuthToken,
  postImageDataRequest,
  simplePostRequest,
} from 'service/api';
import { AppDispatch, RootState } from 'redux/store';
import VectorSource from 'ol/source/Vector';
import { Polygon as OLPolygon, Point } from 'ol/geom';
import { applyChangesetToSource } from 'utils/ol';
import {
  DEFAULT_MICRONS_PER_PIXEL,
  MAX_SELECTION_AREA,
  MIN_TILE_SIDE_LENGTH,
  TILE_SIDE_LENGTH_INCLUDING_OFFSET,
} from 'utils/constants';
import { polygon as TurfPolygon, Position } from '@turf/helpers';
import bbox from '@turf/bbox';
import booleanIntersects from '@turf/boolean-intersects';
import bboxPolygon from '@turf/bbox-polygon';
import { v4 as uuidv4 } from 'uuid';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Feature } from 'ol';
import { notification } from 'antd';
import UndoRedo from 'ol-ext/interaction/UndoRedo';
import jsonLogic, { RulesLogic } from 'json-logic-js';
import { setShouldShowHeatmapLegend } from 'redux/slices/userInterface';
import { sliceChangesetIntoChunks } from '../../utils/utils';

export const fetchWSIResultsListForImage = createAsyncThunk<
  any,
  { image: Image; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchWSIResultsListForImage', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setIsWSIResultsListLoading(true));
  thunkAPI.dispatch(clearActiveWSIResult());
  thunkAPI.dispatch(setIsAnnotatingEnabled(false));

  const { image, accessToken } = payload;
  const getWSIResultsListUrl = backendUrl('fine_tuning/get_wsi_results_list_for_image');
  getWSIResultsListUrl.searchParams.append('id', image.id);
  const response = await getRequestWithAuthToken(getWSIResultsListUrl, thunkAPI.signal, accessToken);
  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }
  const wsiResultsList = await response.json();
  return wsiResultsList as Array<FineTuningWSIResult>;
});

export const fetchWSIResultsData = createAsyncThunk<
  { wsiResultResponse: WSIResultResponse; classificationScenario: ClassificationScenario; allowHeatmapRender: boolean },
  { fineTuningWSIResultId: string; accessToken: string; userChangesId: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchWSIResultsData', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setIsAnalysisResultsLoading(true));

  const { fineTuningWSIResultId, accessToken, userChangesId } = payload;

  if (!fineTuningWSIResultId) {
    return thunkAPI.rejectWithValue('No WSI Result selected');
  }

  const { activeWSIResult } = thunkAPI.getState().analysis;

  let wsiResult = activeWSIResult?.wsi_result;
  let classificationScenario: ClassificationScenario = activeWSIResult?.classification_scenario;

  if (!wsiResult || !classificationScenario) {
    const fineTuningWSIResultUrl = backendUrl('fine_tuning/get_fine_tuning_wsi_result');
    fineTuningWSIResultUrl.searchParams.append('id', fineTuningWSIResultId);

    const response = await getRequestWithAuthToken(fineTuningWSIResultUrl, thunkAPI.signal, accessToken);

    const fineTuningWSIResult: FineTuningWSIResult = await response.json();
    wsiResult = fineTuningWSIResult.wsi_result;
    classificationScenario = fineTuningWSIResult.classification_scenario;
  }

  if (!wsiResult) {
    return thunkAPI.rejectWithValue('WSI Result does not exist in Case');
  }

  const dataRequestUrl = wsiResult.lab_id
    ? backendUrl(
        `v1/wsi/result/${wsiResult.image_id}/${wsiResult.ai_scenario}?token=${accessToken}&ai_lab_id=${wsiResult.lab_id}`,
      )
    : backendUrl(`v1/wsi/result/${wsiResult.image_id}/${wsiResult.ai_scenario}?token=${accessToken}`);

  const response = await getRequestWithAuthToken(dataRequestUrl, thunkAPI.signal, accessToken);

  const dataResult: WSIResultResponse = await response.json();

  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }

  if (dataResult.error && dataResult.error === 'WSI results not found') {
    throw new Error('WSI results not found');
  }

  if (classificationScenario.classes && classificationScenario.classes.length > 0) {
    thunkAPI.dispatch(setActiveAnnotationClassification(classificationScenario.classes[0].name));
  }

  const desiredClassNames = classificationScenario.classes.map(c => c.name);

  const addCellsChangeset = dataResult.results.cells
    .filter(c => desiredClassNames.includes(c.type))
    .map<CellChangeAdd>((cell, index) => {
      return {
        type: 'ADD',
        id: index.toString(),
        payload: {
          ...cell,
          id: index.toString(),
          is_reviewed: false,
          manual: false,
        },
      };
    });

  const chunkedArray = sliceChangesetIntoChunks(addCellsChangeset, 1000);
  chunkedArray.forEach(changesetChunk => {
    thunkAPI.dispatch(applyChangeset({ changeset: changesetChunk, addToUndoRedo: false, isManual: false }));
  });

  return {
    wsiResultResponse: dataResult,
    classificationScenario: classificationScenario,
    allowHeatmapRender: userChangesId === 'none', //Allow heatmap to render even if there are no user changes
  };
});

export const fetchUserChangesListForWSIResult = createAsyncThunk<
  UserChangesList,
  { fineTuningWSIResultId: string; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchUserChangesListForWSIResult', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setIsUserChangesListLoading(true));

  const { fineTuningWSIResultId, accessToken } = payload;

  const userChangesListUrl = backendUrl('fine_tuning/get_user_changes_list_for_wsi_result');
  userChangesListUrl.searchParams.append('id', fineTuningWSIResultId);

  const response = await getRequestWithAuthToken(userChangesListUrl, thunkAPI.signal, accessToken);

  const userChangesList: UserChangesList = await response.json();

  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }

  return userChangesList;
});

export const fetchCreateNewUserChanges = createAsyncThunk<
  UserChanges,
  { fineTuningWSIResultId: string; name?: string; userChangesId?: string; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchCreateNewUserChanges', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setIsCreatingUserChangesLoading(true));

  let createNewUserChangesUrl = backendUrl('fine_tuning/create_new_user_change_for_fine_tuning/');
  const { fineTuningWSIResultId, name, userChangesId, accessToken } = payload;
  const data = {
    fine_tuning_wsi_result_id: fineTuningWSIResultId,
    name: name,
    user_changes_id: userChangesId,
  };
  const response = await simplePostRequest(createNewUserChangesUrl, data, thunkAPI.signal, accessToken);
  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }

  return await response.json();
});

export const fetchUserChangesData = createAsyncThunk<
  UserChanges,
  { userChangesId: string; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchUserChangesData', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setIsUserChangesLoading(true));

  const { userChangesId, accessToken } = payload;

  const userChangesDetailsUrl = backendUrl('fine_tuning/get_user_changes_details');
  userChangesDetailsUrl.searchParams.append('id', userChangesId);
  const response = await getRequestWithAuthToken(userChangesDetailsUrl, thunkAPI.signal, accessToken);
  const userChanges: UserChanges = await response.json();

  thunkAPI.dispatch(applyChangeset({ changeset: userChanges.changeset, addToUndoRedo: false, isManual: true }));

  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }

  thunkAPI.dispatch(setShouldShowHeatmapLegend(true));

  return userChanges;
});

export const fetchSaveUserChanges = createAsyncThunk<
  UserChanges,
  { changeset: Array<CellChange>; userChangesId?: string; accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchSaveUserChanges', async (payload, thunkAPI) => {
  let saveUserChangesUrl = backendUrl('fine_tuning/save_user_changes/');

  thunkAPI.dispatch(setIsSavingChangeset(true));

  const { isSavingDisabled } = thunkAPI.getState().analysis;

  if (isSavingDisabled) {
    notification['error']({
      message: 'Please wait for change action to complete before saving',
    });
    return thunkAPI.rejectWithValue(null);
  }

  const { changeset, userChangesId, accessToken } = payload;

  const data = {
    id: userChangesId,
    changeset,
  };

  const response = await simplePostRequest(saveUserChangesUrl, data, thunkAPI.signal, accessToken);

  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }
  return await response.json();
});

export const fetchAIScenarios = createAsyncThunk<
  Array<AIScenario>,
  { accessToken: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('assignments/fetchAiScenarios', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setIsAIScenariosLoading(true));

  const getAIScenariosUrl = backendUrl('images/get_ai_scenarios/');
  const { accessToken } = payload;
  const response = await getRequestWithAuthToken(getAIScenariosUrl, thunkAPI.signal, accessToken);
  const aiScenarios: Array<AIScenario> = await response.json();

  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }

  return aiScenarios;
});

export const truncatedBoundingBox = (polygon: number[][], imageWidth: number, imageHeight: number) => {
  try {
    const turfPolygon = TurfPolygon([polygon]);
    const boundingBox = bbox(turfPolygon);
    if (boundingBox[0] < 0) {
      boundingBox[0] = 0;
    }
    if (boundingBox[1] < 0) {
      boundingBox[1] = 0;
    }
    if (boundingBox[2] > imageWidth) {
      boundingBox[2] = imageWidth;
    }
    if (boundingBox[3] > imageHeight) {
      boundingBox[3] = imageHeight;
    }
    return {
      boundingBox: boundingBox.map(Math.round) as BoundingBox,
      turfPolygon,
    };
  } catch (e) {
    throw new Error('Analysis area is too small. Please select a larger area.');
  }
};

export const regionForBoundingBox = (bbox: BoundingBox) => {
  const [minX, minY, maxX, maxY] = bbox;
  return {
    x: Math.round(minX),
    y: Math.round(minY),
    w: Math.round(maxX - minX),
    h: Math.round(maxY - minY),
  } as ImageRegion;
};

export const regionWithOffset = (region: ImageRegion, offset: number) =>
  ({
    x: region.x - offset,
    y: region.y - offset,
    w: region.w + 2 * offset,
    h: region.h + 2 * offset,
  } as ImageRegion);

const checkAnalysisAreaSize = (boundingBox: BoundingBox, scaledOffset: number, imageScale: number) => {
  const selectionRegion = regionForBoundingBox(boundingBox);
  const scaledSelectionRegion = {
    ...selectionRegion,
    w: selectionRegion.w / imageScale,
    h: selectionRegion.h / imageScale,
  };
  const selectionRegionWithOffset = regionWithOffset(scaledSelectionRegion, scaledOffset);
  const { w: selectionWidth, h: selectionHeight } = selectionRegionWithOffset;

  if (selectionWidth * selectionHeight > MAX_SELECTION_AREA) {
    throw new Error('Analysis area is too large. Please select a smaller area.');
  }
};

/**
 * Calculates a (square) grid over a bounding box.
 * @param bbox axis-aligned bounding box like [minX, minY, maxX, maxY]
 * @param tileSideLength desired width and height of the square tiles
 * @param minTileSideLength minimum side length of tile
 * @returns {*[]} a list of tiles in bounding box format [minX, minY, maxX, maxY]
 */
const tileGrid = (
  bbox: BoundingBox,
  tileSideLength: number,
  minTileSideLength = MIN_TILE_SIDE_LENGTH,
): Array<BoundingBox> => {
  let [minX, minY, maxX, maxY] = bbox;

  minX = Math.min(minX - 256, 0);
  minY = Math.min(minY - 256, 0);
  maxX = maxX + 256;
  maxY = maxY + 256;

  let xDiff = maxX - minX;
  if (xDiff % 256 !== 0) {
    maxX = minX + Math.ceil(xDiff / 256) * 256;
  }

  let yDiff = maxY - minY;
  if (yDiff % 256 !== 0) {
    maxY = minY + Math.ceil(yDiff / 256) * 256;
  }

  let currentX = minX;
  let tileHeight, tileWidth;
  const tiles = [];
  while (currentX < maxX) {
    let currentY = minY;
    tileWidth = Math.max(Math.min(tileSideLength, maxX - currentX), minTileSideLength);
    while (currentY < maxY) {
      tileHeight = Math.max(Math.min(tileSideLength, maxY - currentY), minTileSideLength);
      const tile: BoundingBox = [currentX, currentY, currentX + tileWidth, currentY + tileHeight];
      tiles.push(tile);
      currentY += tileHeight;
    }
    currentX += tileWidth;
  }
  return tiles;
};

const createAnalysisSelectionHandler = (
  signal: any,
  accessToken: string,
  username: string,
  aiToken: string,
  imageInfo: ImageInfo,
  imageId: string,
  disableMppScaling?: boolean,
  aiLabId?: string,
): AnalysisSelectionHandler => {
  const { width: imageWidth, height: imageHeight, profile: imageProfile, extraFormats: imageExtraFormats } = imageInfo;

  const imageMpp = imageInfo.micronsPerPixel ?? DEFAULT_MICRONS_PER_PIXEL;

  return (selectionPolygon, aiScenario, scenarioInfo) => {
    const imageOffset = scenarioInfo.image_offset;

    // If model_mpp is not set, we only downsample images having higher resolution than 40x
    let imageScale = scenarioInfo['model_mpp']
      ? scenarioInfo['model_mpp'] / imageMpp
      : imageMpp < DEFAULT_MICRONS_PER_PIXEL
      ? imageMpp / DEFAULT_MICRONS_PER_PIXEL
      : 1.0;

    if (disableMppScaling) {
      imageScale = 1.0;
    }

    const { boundingBox: selectionBoundingBox, turfPolygon: turfSelectionPolygon } = truncatedBoundingBox(
      selectionPolygon,
      imageWidth,
      imageHeight,
    );

    // IIIF profile level2 already includes png support by default
    const isSupportingPNG = imageProfile === 'level2' || (imageExtraFormats && imageExtraFormats.includes('png'));
    const format = isSupportingPNG ? 'png' : 'jpg';

    const scaledImageOffset = Math.round(imageOffset * imageScale);
    checkAnalysisAreaSize(selectionBoundingBox, scaledImageOffset, imageScale);
    const tileSideLength = Math.round(TILE_SIDE_LENGTH_INCLUDING_OFFSET * imageScale) - 2 * scaledImageOffset;
    const tiles = tileGrid(selectionBoundingBox, tileSideLength, MIN_TILE_SIDE_LENGTH * imageScale);

    return tiles.reduce<Array<AIResponsePromise>>((aiResponsePromises, tileBbox) => {
      if (!booleanIntersects(bboxPolygon(tileBbox), turfSelectionPolygon)) return aiResponsePromises;

      const tileRegion = regionForBoundingBox(tileBbox);
      const tileRegionWithOffset = regionWithOffset(tileRegion, scaledImageOffset);

      const { x, y, w, h } = tileRegionWithOffset;
      const sizeStr = `${Math.round(w / imageScale)},${Math.round(h / imageScale)}`;
      const regionStringIIIF = `${x},${y},${w},${h}`;

      const readerUrl = `${window._env_.REACT_APP_READER_API_URL}/iiif`;
      const aiModelsUrl = window._env_.REACT_APP_AI_MODELS_URL;

      const imageDataUrl = new URL(`${readerUrl}/${imageId}/${regionStringIIIF}/${sizeStr}/0/default.${format}`);
      const aiAnalysisShift = `${tileRegion.x},${tileRegion.y}`;
      const submitAnalysisImageUrl = aiLabId
        ? new URL(
            `${aiModelsUrl}/${aiScenario}?shift=${aiAnalysisShift}&scale=${imageScale}&image_id=${imageId}&lab_id=${aiLabId}`,
          )
        : new URL(`${aiModelsUrl}/${aiScenario}?shift=${aiAnalysisShift}&scale=${imageScale}&image_id=${imageId}`);

      const region = [x, y, w, h];

      aiResponsePromises.push(
        new Promise((resolve, reject) => {
          const fetchAIResult = async () => {
            const imageDataResponse = await getRequestWithAuthToken(imageDataUrl, signal, accessToken);
            if (!imageDataResponse.ok) {
              const { details } = await imageDataResponse.json();
              reject(new Error(details));
            }
            const imageDataBlob = await imageDataResponse.blob();
            const aiAPIKey = window._env_.REACT_APP_API_KEY;
            const aiUserId = `${window._env_.REACT_APP_AI_PREFIX}_${username}`;
            const aiResultResponse = await postImageDataRequest(
              submitAnalysisImageUrl,
              imageDataBlob,
              aiToken,
              aiAPIKey,
              aiUserId,
            );
            const aiResponse: AIResponse = await aiResultResponse.json();
            resolve({ aiResponse, region });
          };
          fetchAIResult();
        }),
      );

      return aiResponsePromises;
    }, []);
  };
};

type SelectionAIResultRequest = {
  selectionPolygon: Polygon;
  roiFeature: Feature<OLPolygon>;
  username: string | undefined;
  accessToken: string | null;
  aiToken: string | null;
  imageId: string;
  aiLabId: string | undefined;
};

export const fetchSelectionAIResult = createAsyncThunk<
  void,
  SelectionAIResultRequest,
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchSelectionAIResult', async (payload, { dispatch, getState, signal }) => {
  const { selectionPolygon, roiFeature, username, accessToken, aiToken, imageId, aiLabId } = payload;

  const { imageInfo } = getState().imageInfo;
  const {
    scenarioInfo,
    classificationScenario,
    activeAIScenario,
    mutable: { cellSource },
  } = getState().analysis;

  if (
    !imageInfo ||
    !scenarioInfo ||
    !accessToken ||
    !aiToken ||
    !username ||
    !classificationScenario ||
    !activeAIScenario
  )
    return;

  try {
    dispatch(setIsAnalysisLoading(true));

    const analysisSelectionHandler = createAnalysisSelectionHandler(
      signal,
      accessToken,
      username,
      aiToken,
      imageInfo,
      imageId,
      false,
      aiLabId,
    );

    const aiResponsePromises = analysisSelectionHandler(selectionPolygon, activeAIScenario, scenarioInfo);

    // Find and delete all cells inside ROI Polygon
    const roiFeatureCoordinates = roiFeature.getGeometry()!.getCoordinates()[0] as unknown as Position[];
    const selectionTurfPolygon = TurfPolygon([roiFeatureCoordinates]);

    let cellsToDeleteChangeset: Array<CellChangeDelete> = [];

    const featuresInExtent = cellSource.source.getFeaturesInExtent(roiFeature.getGeometry()!.getExtent()) as Array<
      Feature<Point>
    >;

    featuresInExtent.forEach(feature => {
      const featureCoordinates = feature.getGeometry()!.getCoordinates();

      if (booleanPointInPolygon(featureCoordinates, selectionTurfPolygon)) {
        cellsToDeleteChangeset.push({
          type: 'DELETE',
          id: feature.getId()!.toString(),
          payload: {},
        });
      }
    });

    const fetchAIResultForTile = async (aiResponsePromise: AIResponsePromise) => {
      const { aiResponse } = await aiResponsePromise;

      dispatch(increaseAnalysisProgress(100 / aiResponsePromises.length));

      const cells = aiResponse['response']['results']['cells'];

      const desiredClassNames = classificationScenario.classes.map(c => c.name);

      const mappedChangeCells = cells
        .filter(c => desiredClassNames.includes(c.type) && booleanPointInPolygon([c.x, -c.y], selectionTurfPolygon))
        .map<CellChangeAdd>(cell => {
          const newId = uuidv4();
          return {
            type: 'ADD',
            id: newId,
            payload: {
              ...cell,
              id: newId,
              is_reviewed: false,
              manual: false,
            },
          };
        });

      dispatch(applyChangesetAction(mappedChangeCells));
    };

    if (window._env_.REACT_APP_AI_REQUESTS_ENABLE_PARALLEL.toLowerCase() === 'true') {
      await Promise.all(aiResponsePromises.map(fetchAIResultForTile));
      dispatch(applyChangesetAction(cellsToDeleteChangeset));
      return;
    } else {
      const reduceApiEndpoints = async (previous: Promise<void>, aiResponsePromise: AIResponsePromise) => {
        await previous;
        return fetchAIResultForTile(aiResponsePromise);
      };

      await aiResponsePromises.reduce(reduceApiEndpoints, Promise.resolve());
      dispatch(applyChangesetAction(cellsToDeleteChangeset));
      return;
    }
  } catch (e) {
    dispatch(resetAnalysisProgress());
    dispatch(setIsAnalysisLoading(false));
    throw e;
  }
});

export const fetchScenarioInfo = createAsyncThunk<
  ScenarioInfo,
  {
    aiToken: string;
    aiScenario: string;
    aiLabId: string | undefined;
  },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('analysis/fetchScenarioInfo', async (payload, thunkAPI) => {
  const { aiToken, aiScenario, aiLabId } = payload;
  const aiModelsUrl = window._env_.REACT_APP_AI_MODELS_URL;

  const scenarioInfoUrl = aiLabId
    ? new URL(`${aiModelsUrl}/${aiScenario}?lab_id=${aiLabId}`)
    : new URL(`${aiModelsUrl}/${aiScenario}`);

  const aiAPIKey = window._env_.REACT_APP_API_KEY;
  const response = await getAIScenarioRequest(scenarioInfoUrl, thunkAPI.signal, aiToken, aiAPIKey);
  if (response.status > 400) {
    return thunkAPI.rejectWithValue(null);
  }

  const scenarioInfo: ScenarioInfo = await response.json();

  return scenarioInfo;
});

type AnalysisState = {
  activeWSIResult: FineTuningWSIResult | undefined;
  wsiResultsList: Array<FineTuningWSIResult>;
  isWSIResultsListLoading: boolean;
  wsiResultsData: WSIResultResponse | undefined;
  isAnalysisResultsLoading: boolean;
  isUserChangesListLoading: boolean;
  userChangesList: UserChangesList;
  isUserChangesLoading: boolean;
  userChanges: UserChanges | undefined;
  isCreatingUserChangesLoading: boolean;
  classificationScenario: ClassificationScenario | undefined;
  isSavingDisabled: boolean;
  isSavingChangeset: boolean;
  changeset: Array<CellChange>;
  changesetPointer: number | null;
  annotationReviewFilters: Array<ReviewFilterTypes>;
  mutable: {
    cellSource: {
      lastChanged: number | undefined;
      source: VectorSource<Point>;
    };
    cellSourceWSIResultsOnly: VectorSource<Point>;
    undoInteraction: UndoRedo | null;
  };
  aiScenarios: Array<AIScenario>;
  activeAIScenario: string | null;
  isAIScenariosLoading: boolean;
  scenarioInfo: ScenarioInfo | undefined;
  isAIScenarioLoading: boolean;
  isROIAnalysisLoading: boolean;
  analysisProgress: number;
  error: {
    message: string;
  } | null;
  shouldCancelAIRequest: boolean;
  displayDiffType: DisplayDiffType;
  annotationOpacity: number;
  allowHeatmapRender: boolean;
  ignoredCellTypes: Array<string>;
};

export const initialState = {
  activeWSIResult: undefined,
  wsiResultsList: [],
  isWSIResultsListLoading: false,
  wsiResultsData: undefined,
  isAnalysisResultsLoading: false,
  isUserChangesListLoading: false,
  userChangesList: [],
  isUserChangesLoading: false,
  userChanges: undefined,
  isCreatingUserChangesLoading: false,
  classificationScenario: undefined,
  isSavingDisabled: false,
  isSavingChangeset: false,
  changeset: [],
  changesetPointer: null,
  annotationReviewFilters: ['show_reviewed', 'show_unreviewed'],
  mutable: {
    cellSource: {
      lastChanged: undefined,
      source: new VectorSource({
        features: [],
        wrapX: false,
      }),
    },
    cellSourceWSIResultsOnly: new VectorSource({
      features: [],
      wrapX: false,
    }),
    undoInteraction: null,
  },
  aiScenarios: [],
  activeAIScenario: null,
  isAIScenariosLoading: false,
  scenarioInfo: undefined,
  isAIScenarioLoading: false,
  isROIAnalysisLoading: false,
  analysisProgress: 0,
  error: null,
  shouldCancelAIRequest: false,
  displayDiffType: 'none',
  annotationOpacity: 1,
  allowHeatmapRender: false,
  ignoredCellTypes: [],
} as AnalysisState;

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setIsWSIResultsListLoading: (state, action) => {
      state.isWSIResultsListLoading = action.payload;
    },
    setIsUserChangesListLoading: (state, action) => {
      state.isUserChangesListLoading = action.payload;
    },
    setIsAnalysisResultsLoading: (state, action) => {
      state.isAnalysisResultsLoading = action.payload;
    },
    setIsUserChangesLoading: (state, action) => {
      state.isUserChangesLoading = action.payload;
    },
    setLastFeatureUpdate: state => {
      state.mutable.cellSource = {
        ...state.mutable.cellSource,
        lastChanged: new Date().getTime(),
      };
    },
    clearActiveWSIResult: state => {
      state.wsiResultsData = initialState.wsiResultsData;
      state.userChanges = initialState.userChanges;
      state.changeset = initialState.changeset;
      state.mutable.cellSource.source.clear();
      state.mutable.cellSource.lastChanged = initialState.mutable.cellSource.lastChanged;
      state.mutable.cellSourceWSIResultsOnly.clear();
      state.annotationOpacity = initialState.annotationOpacity;
      state.displayDiffType = initialState.displayDiffType;
      state.allowHeatmapRender = initialState.allowHeatmapRender;
      state.ignoredCellTypes = initialState.ignoredCellTypes;
    },
    setIsCreatingUserChangesLoading: (state, action) => {
      state.isCreatingUserChangesLoading = action.payload;
    },
    setIsSavingDisabled: (state, action) => {
      state.isSavingDisabled = action.payload;
    },
    setIsSavingChangeset: (state, action) => {
      state.isSavingChangeset = action.payload;
    },
    extendChangeset: (state, action: PayloadAction<CellChange[]>) => {
      if (state.changesetPointer === 0) {
        state.changeset = action.payload;
      } else {
        state.changeset.push(...action.payload);
      }
      state.changesetPointer = null;
    },
    incrementChangesetPointer: state => {
      if (state.changesetPointer === null) return;
      if (state.changesetPointer === state.changeset.length) state.changesetPointer = null;
      if (state.changesetPointer) {
        state.changesetPointer += 1;
      } else {
        state.changesetPointer = 1;
      }
    },
    decrementChangesetPointer: state => {
      if (state.changesetPointer === 0) return;
      if (state.changesetPointer === null) state.changesetPointer = state.changeset.length;
      state.changesetPointer -= 1;
    },
    setAnnotationReviewFilters: (state, action: PayloadAction<Array<ReviewFilterTypes>>) => {
      state.annotationReviewFilters = action.payload;
    },
    applyChangeset: (
      state,
      action: PayloadAction<{ changeset: CellChange[]; addToUndoRedo: boolean; isManual: boolean }>,
    ) => {
      const { changeset, addToUndoRedo, isManual } = action.payload;

      if (!state.mutable.cellSource.source || !changeset) return;
      const undoInteraction = addToUndoRedo ? state.mutable.undoInteraction : null;
      applyChangesetToSource(
        changeset,
        state.mutable.cellSource.source as VectorSource<Point>,
        state.mutable.cellSourceWSIResultsOnly as VectorSource<Point>,
        undoInteraction as UndoRedo,
        isManual,
      );
    },
    setUndoInteraction: (state, action) => {
      state.mutable.undoInteraction = action.payload;
    },
    setIsAIScenariosLoading: (state, action) => {
      state.isAIScenariosLoading = action.payload;
    },
    setActiveAIScenario: (state, action) => {
      state.activeAIScenario = action.payload;
    },
    setIsAIScenarioLoading: (state, action) => {
      state.isAIScenarioLoading = action.payload;
    },
    setIsAnalysisLoading: (state, action: PayloadAction<boolean>) => {
      state.isROIAnalysisLoading = action.payload;
    },
    increaseAnalysisProgress: (state, action) => {
      state.analysisProgress += action.payload;
    },
    resetAnalysisProgress: state => {
      state.analysisProgress = 0;
    },
    setAnalysisError: (state, action) => {
      state.error = action.payload;
    },
    clearAnalysisError: state => {
      state.error = null;
    },
    setShouldCancelAIRequest: (state, action) => {
      state.shouldCancelAIRequest = action.payload;
    },
    setDisplayDiffType: (state, action: PayloadAction<DisplayDiffType>) => {
      state.displayDiffType = action.payload;
      if (state.displayDiffType === 'none') {
        state.annotationOpacity = 1;
      } else if (state.displayDiffType === 'diff') {
        state.annotationOpacity = 0.5;
      } else if (state.displayDiffType === 'wsi_results_only') {
        state.annotationOpacity = 0;
      }
    },
    setAnnotationOpacity: (state, action) => {
      state.annotationOpacity = action.payload;
    },
    updateIgnoredCellType: (state, action: PayloadAction<string>) => {
      if (state.ignoredCellTypes.includes(action.payload)) {
        state.ignoredCellTypes = state.ignoredCellTypes.filter(entry => entry !== action.payload);
      } else {
        state.ignoredCellTypes.push(action.payload);
      }
    },
    setAllowHeatmapRender: (state, action) => {
      state.allowHeatmapRender = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWSIResultsListForImage.fulfilled, (state, action) => {
      state.wsiResultsList = action.payload;
      state.isWSIResultsListLoading = false;
    });
    builder.addCase(fetchWSIResultsListForImage.rejected, state => {
      state.wsiResultsList = [];
      state.isWSIResultsListLoading = false;
    });
    builder.addCase(
      fetchWSIResultsData.fulfilled,
      (
        state,
        action: PayloadAction<{
          wsiResultResponse: WSIResultResponse;
          classificationScenario: ClassificationScenario;
          allowHeatmapRender: boolean;
        }>,
      ) => {
        const { wsiResultResponse, classificationScenario, allowHeatmapRender } = action.payload;

        state.wsiResultsData = wsiResultResponse;
        state.activeAIScenario = wsiResultResponse?.properties.ai_type;
        state.isAnalysisResultsLoading = false;
        state.classificationScenario = classificationScenario;
        state.allowHeatmapRender = allowHeatmapRender;
      },
    );
    builder.addCase(fetchWSIResultsData.rejected, (state, { error }) => {
      if (error.message && error.message !== 'Aborted') {
        state.error = { ...error, message: error.message.toString() };
      }
      state.isAnalysisResultsLoading = false;
    });
    builder.addCase(fetchUserChangesListForWSIResult.fulfilled, (state, action) => {
      state.userChangesList = action.payload;
      state.isUserChangesListLoading = false;
    });
    builder.addCase(fetchUserChangesListForWSIResult.rejected, state => {
      state.isUserChangesListLoading = false;
    });

    builder.addCase(fetchCreateNewUserChanges.fulfilled, (state, action) => {
      state.userChanges = action.payload;
      state.userChangesList.unshift(action.payload);
      state.isCreatingUserChangesLoading = false;
    });

    builder.addCase(fetchCreateNewUserChanges.rejected, state => {
      state.isCreatingUserChangesLoading = false;
    });

    builder.addCase(fetchUserChangesData.fulfilled, (state, action) => {
      state.userChanges = action.payload;
      state.isUserChangesLoading = false;
      state.allowHeatmapRender = true;
      state.mutable.cellSource = {
        ...state.mutable.cellSource,
        lastChanged: new Date().getTime(),
      };
    });
    builder.addCase(fetchUserChangesData.rejected, state => {
      state.isUserChangesLoading = false;
    });

    builder.addCase(fetchSaveUserChanges.fulfilled, (state, action) => {
      state.userChanges = action.payload;
      state.isSavingChangeset = false;
      state.changeset = [];
      state.changesetPointer = null;
    });

    builder.addCase(fetchSaveUserChanges.rejected, (state, { error }) => {
      if (error.message && error.message !== 'Aborted') {
        state.error = { ...error, message: error.message.toString() };
      }
      state.isSavingChangeset = false;
    });

    builder.addCase(fetchSelectionAIResult.fulfilled, state => {
      state.isROIAnalysisLoading = false;
      state.analysisProgress = 0;
    });
    builder.addCase(fetchSelectionAIResult.rejected, (state, { error }) => {
      if (error.message && error.message !== 'Aborted') {
        state.error = { ...error, message: error.message.toString() };
      } else {
        state.isROIAnalysisLoading = false;
        state.analysisProgress = 0;
      }
    });

    builder.addCase(fetchAIScenarios.fulfilled, (state, action) => {
      state.isAIScenariosLoading = false;
      state.aiScenarios = action.payload;
    });
    builder.addCase(fetchAIScenarios.rejected, (state, { error }) => {
      if (error.message && error.message !== 'Aborted') {
        state.error = { ...error, message: error.message.toString() };
      } else {
        state.isAIScenariosLoading = false;
        state.aiScenarios = [];
      }
    });
    builder.addCase(fetchScenarioInfo.fulfilled, (state, action) => {
      state.scenarioInfo = action.payload;
    });
    builder.addCase(fetchScenarioInfo.rejected, (state, action) => {
      const { error } = action;
      state.error = { ...error, message: error.message || 'Failed to fetch Scenario Info' };
    });
  },
});

export const {
  setIsWSIResultsListLoading,
  setIsUserChangesListLoading,
  setIsAnalysisResultsLoading,
  setIsUserChangesLoading,
  clearActiveWSIResult,
  setLastFeatureUpdate,
  setIsSavingDisabled,
  setIsSavingChangeset,
  applyChangeset,
  extendChangeset,
  incrementChangesetPointer,
  decrementChangesetPointer,
  setAnnotationReviewFilters,
  setIsCreatingUserChangesLoading,
  setUndoInteraction,
  setIsAIScenariosLoading,
  setActiveAIScenario,
  setIsAnalysisLoading,
  increaseAnalysisProgress,
  resetAnalysisProgress,
  setAnalysisError,
  clearAnalysisError,
  setShouldCancelAIRequest,
  setDisplayDiffType,
  setAnnotationOpacity,
  updateIgnoredCellType,
  setAllowHeatmapRender,
} = analysisSlice.actions;

function savingDisabledPromise(isSavingDisabled: boolean) {
  return (dispatch: any) => {
    return new Promise<void>(resolve => {
      dispatch(setIsSavingDisabled(isSavingDisabled));
      resolve();
    });
  };
}

function applyChangesetPromise(changeset: any) {
  return (dispatch: any) => {
    return new Promise<void>(resolve => {
      dispatch(applyChangeset({ changeset, addToUndoRedo: true, isManual: true }));
      resolve();
    });
  };
}

function setLastFeatureUpdatePromise() {
  return (dispatch: any) => {
    return new Promise<void>(resolve => {
      dispatch(setLastFeatureUpdate());
      resolve();
    });
  };
}

function extendChangesetPromise(changeset: any) {
  return (dispatch: any) => {
    return new Promise<void>(resolve => {
      dispatch(extendChangeset(changeset));
      resolve();
    });
  };
}

export function applyChangesetAction(changeset: Array<CellChange>) {
  return function (dispatch: (dispatch: any) => any, getState: () => any) {
    const { isSavingChangeset } = getState().analysis;

    if (isSavingChangeset) {
      notification['error']({
        message: 'Please wait for saving to complete before making additional changes',
      });
      return () => {};
    }

    return dispatch(savingDisabledPromise(true))
      .then(dispatch(applyChangesetPromise(changeset)))
      .then(dispatch(extendChangesetPromise(changeset)))
      .then(dispatch(setLastFeatureUpdatePromise()))
      .then(dispatch(savingDisabledPromise(false)));
  };
}

//We use a thunk here instead of a direct redux action to avoid 'Reducers may not dispatch actions' error thrown when
// accessing undoInteraction directly
export function undoAction() {
  return function (dispatch: (dispatch: any) => any, getState: () => any) {
    const { undoInteraction } = getState().analysis.mutable;
    undoInteraction.undo();
  };
}

//We use a thunk here instead of a direct redux action to avoid 'Reducers may not dispatch actions' error thrown when
// accessing undoInteraction directly
export function redoAction() {
  return function (dispatch: (dispatch: any) => any, getState: () => any) {
    const { undoInteraction } = getState().analysis.mutable;
    undoInteraction.redo();
  };
}

export const selectAnnotationReviewFilters = (state: RootState) => state.analysis.annotationReviewFilters;
export const selectChangeset = (state: RootState) => state.analysis.changeset;
export const selectChangesetPointer = (state: RootState) => state.analysis.changesetPointer;

export const selectChangesetWithPointer = createSelector(
  [selectChangeset, selectChangesetPointer],
  (changeset, changesetPointer) => {
    if (changesetPointer === null) return changeset;
    return changeset.slice(0, changesetPointer);
  },
);

export const selectClassificationScenario = (state: RootState) => state.analysis.classificationScenario;
export const selectIsAnalysisResultsLoading = (state: RootState) => state.analysis.isAnalysisResultsLoading;
export const selectIsCreatingUserChangesLoading = (state: RootState) => state.analysis.isCreatingUserChangesLoading;
export const selectIsSavingDisabled = (state: RootState) => state.analysis.isSavingDisabled;
export const selectIsSavingChangeset = (state: RootState) => state.analysis.isSavingChangeset;
export const selectIsUserChangesListLoading = (state: RootState) => state.analysis.isUserChangesListLoading;
export const selectIsUserChangesLoading = (state: RootState) => state.analysis.isUserChangesLoading;
export const selectIsWSIResultsListLoading = (state: RootState) => state.analysis.isWSIResultsListLoading;
export const selectUserChanges = (state: RootState) => state.analysis.userChanges;
export const selectUserChangesList = (state: RootState) => state.analysis.userChangesList;
export const selectWSIResultsList = (state: RootState) => state.analysis.wsiResultsList;
export const selectWSIResultData = (state: RootState) => state.analysis.wsiResultsData;
export const selectCellSource = (state: RootState) => state.analysis.mutable.cellSource;
export const selectCellSourceWSIResultsOnly = (state: RootState) => state.analysis.mutable.cellSourceWSIResultsOnly;
export const selectActiveAIScenario = (state: RootState) => state.analysis.activeAIScenario;
export const selectAIScenarios = (state: RootState) => state.analysis.aiScenarios;
export const selectIsAIScenariosLoading = (state: RootState) => state.analysis.isAIScenariosLoading;
export const selectScenarioInfo = (state: RootState) => state.analysis.scenarioInfo;
export const selectIsROIAnalysisLoading = (state: RootState) => state.analysis.isROIAnalysisLoading;
export const selectAnalysisProgress = (state: RootState) => state.analysis.analysisProgress;
export const selectAnalysisError = (state: RootState) => state.analysis.error;
export const selectShouldCancelAIRequest = (state: RootState) => state.analysis.shouldCancelAIRequest;

export const selectClassifications = createSelector(selectClassificationScenario, classificationScenario => {
  if (classificationScenario && classificationScenario.classes) {
    return classificationScenario.classes;
  } else {
    return [];
  }
});

export const selectDisplayDiffType = (state: RootState) => state.analysis.displayDiffType;
export const selectAnnotationOpacity = (state: RootState) => state.analysis.annotationOpacity;

export const selectCellCounts = createSelector(
  [selectCellSource, selectScenarioInfo, selectDisplayDiffType, selectCellSourceWSIResultsOnly],
  (cellSource, scenarioInfo, displayDiffType, cellSourceWSIResultsOnly) => {
    if (displayDiffType === 'diff') return null;

    let cells;
    if (displayDiffType === 'none') {
      cells = cellSource.source.getFeatures();
    } else {
      cells = cellSourceWSIResultsOnly.getFeatures();
    }
    if (!scenarioInfo || Object.keys(cells).length === 0) return null;
    return Object.values(cells).reduce<CellCounts>(
      (counts, cell) => {
        if (cell.get('type') in counts) {
          counts[cell.get('type')] += 1;
        }
        return counts;
      },
      Object.values(scenarioInfo['classes']).reduce<CellCounts>((acc, cls) => {
        acc[cls.id] = 0;
        return acc;
      }, {}),
    );
  },
);

export const selectScores = createSelector(
  [selectScenarioInfo, selectCellCounts, selectWSIResultData],
  (scenarioInfo, cellCounts, wsiResultData) => {
    if (!scenarioInfo || !wsiResultData) return {};
    jsonLogic.add_operation('#', (...typesToCount) => {
      if (!cellCounts) return null;
      return Object.entries(cellCounts).reduce((count, [classification, classCellCount]) => {
        count += typesToCount.includes(classification) ? classCellCount : 0;
        return count;
      }, 0);
    });
    return Object.entries(scenarioInfo.scores).reduce<Scores>((scores, [scoreKey, scoreRule]) => {
      scores[scoreKey] = jsonLogic.apply(scoreRule as RulesLogic, {
        global: wsiResultData.results.global,
        control_layouts: {},
      });
      return scores;
    }, {});
  },
);

export const selectViewLayouts = createSelector([selectScenarioInfo, selectScores], (scenarioInfo, scores) => {
  if (!scenarioInfo) return {};

  return Object.entries(scenarioInfo.view_layouts as ViewLayouts).reduce<ViewLayouts>(
    (viewLayouts, [layoutName, viewLayout]) => {
      const computedValue = jsonLogic.apply(viewLayout.value, { scores });
      if (Number.isNaN(computedValue) || computedValue === null) {
        return viewLayouts;
      }
      viewLayouts[layoutName] = {
        ...viewLayout,
        value: computedValue,
      };
      return viewLayouts;
    },
    {},
  );
});

export const selectAllowHeatmapRender = (state: RootState) => state.analysis.allowHeatmapRender;
export const selectIgnoredCellTypes = (state: RootState) => state.analysis.ignoredCellTypes;

export default analysisSlice.reducer;
