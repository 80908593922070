import Collection from 'ol/Collection';
import { FeatureLike } from 'ol/Feature';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import DragBox from 'ol/interaction/DragBox';
import Style from 'ol/style/Style';
import { platformModifierKeyOnly, singleClick } from 'ol/events/condition';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import ViewerContext from 'components/Viewer/ViewerContext';
import { applyChangesetAction, selectAnnotationReviewFilters, selectIgnoredCellTypes } from 'redux/slices/analysis';
import { selectImageInfo } from 'redux/slices/imageInfo';
import {
  appendToSelectedAnnotationIds,
  removeFromSelectedAnnotationIds,
  selectActiveAnnotationClassification,
  selectIsAnnotatingEnabled,
  selectSelectedAnnotationIds,
  setActiveAnnotationClassification,
  setScrollToAnnotationId,
  setSelectedAnnotationIds,
} from 'redux/slices/viewer';
import { DEFAULT_MICRONS_PER_PIXEL, DOUBLE_DOTS_PREVENTION_RADIUS_MICRONS, OL_LAYER_NAME } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { Feature, MapBrowserEvent } from 'ol';
import { Point } from 'ol/geom';
import { Layer } from 'ol/layer';
import { getRoundedSinglePixel } from 'utils/utils';
import { selectIsAnnotatingActive } from 'redux/slices/userInterface';
import { useAppDispatch } from 'utils/hooks';
import { store } from 'redux/store';
import { getWidth } from 'ol/extent';

export default function AnnotationInteraction({ source, freehandMultiSelectSource }: AnnotationInteractionProps) {
  const { map } = useContext(ViewerContext);
  const dispatch = useAppDispatch();

  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const isAnnotatingInteractionActive = useSelector(selectIsAnnotatingActive);
  const imageInfo = useSelector(selectImageInfo);
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);
  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);
  const ignoredCellTypes = useSelector(selectIgnoredCellTypes);
  const annotationReviewFilters = useSelector(selectAnnotationReviewFilters);
  const drawDotAnnotationRef = useRef<Draw | null>(null);
  const modifyDotAnnotationRef = useRef<Modify | null>(null);
  const modifyStartCoordinates = useRef<Array<number> | null>(null);
  const selectDotAnnotationRef = useRef<Select | null>(null);
  const multiSelectDotAnnotationRef = useRef<DragBox | null>(null);
  const multiSelectDotAnnotationFreehandRef = useRef<Draw | null>(null);
  const abortDotAnnotationRef = useRef(false);
  const handlePointerMove = useRef<((event: MapBrowserEvent<PointerEvent>) => void) | null>(null);
  const isDrawingPolygon = useRef<boolean>(false);

  useEffect(() => {
    if (!selectDotAnnotationRef.current || !activeAnnotationClassification || !isAnnotatingEnabled || !map) return;
    changeSelectedDotAnnotationClassification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnnotationClassification]);

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    event.preventDefault();
    multiSelectDotAnnotationFreehandRef.current?.setActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const __handleKeyUp = useCallback((event: KeyboardEvent) => {
    event.preventDefault();
    multiSelectDotAnnotationFreehandRef.current?.setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', __handleKeyDown);
    document.addEventListener('keyup', __handleKeyUp);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
      document.removeEventListener('keyup', __handleKeyUp);
    };
  }, [__handleKeyDown, __handleKeyUp]);

  useEffect(() => {
    if (!map || !isAnnotatingEnabled || !isAnnotatingInteractionActive) return;

    const selectedFeatures: Collection<Feature> = new Collection(
      source.getFeatures().filter((a: Feature) => selectedAnnotationIds.includes(a.getId() as string)),
    );

    modifyDotAnnotationRef.current = new Modify({
      features: selectedFeatures,
      style: new Style({}),
      pixelTolerance: 10,
    });

    selectDotAnnotationRef.current = new Select({
      layers: (layer: Layer) => {
        return layer.get('name') === OL_LAYER_NAME.ANNOTATIONS;
      },
      condition: e => singleClick(e),
      style: null,
      hitTolerance: 10,
      features: selectedFeatures,
    });

    multiSelectDotAnnotationRef.current = new DragBox({
      condition: event => platformModifierKeyOnly(event) && event.originalEvent.button === 0,
    });

    // Allow freehand selection of Annotations with Ctrl + Right Mouse Button
    multiSelectDotAnnotationFreehandRef.current = new Draw({
      source: freehandMultiSelectSource,
      type: 'Polygon',
      condition: event => platformModifierKeyOnly(event) && event.originalEvent.buttons === 2,
      freehandCondition: event => platformModifierKeyOnly(event) && event.originalEvent.buttons === 2,
    });
    multiSelectDotAnnotationFreehandRef.current?.setActive(false);

    map.on('pointerup' as any, () => {
      if (isDrawingPolygon.current && multiSelectDotAnnotationFreehandRef.current) {
        multiSelectDotAnnotationFreehandRef.current.finishDrawing();
      }
    });

    handlePointerMove.current = (event: MapBrowserEvent<PointerEvent>) => {
      if (!map) return;
      const pixel = map.getEventPixel(event.originalEvent);
      const featureAtPixel = map.getFeaturesAtPixel(pixel, {
        layerFilter: (layer: Layer) => {
          return layer.get('name') === OL_LAYER_NAME.ANNOTATIONS;
        },
      }) as Array<Feature>;
      const cursorStyle = (feature: Feature) => {
        if (!feature) return 'crosshair';
        return 'auto';
      };
      map.getViewport().style.cursor = cursorStyle(featureAtPixel[0]);
    };

    map.addInteraction(modifyDotAnnotationRef.current);
    map.addInteraction(selectDotAnnotationRef.current);
    map.addInteraction(multiSelectDotAnnotationRef.current);
    map.addInteraction(multiSelectDotAnnotationFreehandRef.current);
    map.on('dblclick', handleDoubleClickDotAnnotation);

    modifyDotAnnotationRef.current.on('modifystart', handleModifyDotAnnotationStart);
    modifyDotAnnotationRef.current.on('modifyend', handleModifyDotAnnotationEnd);
    selectDotAnnotationRef.current.on('select', handleClickDotAnnotation);
    multiSelectDotAnnotationRef.current.on('boxstart' as any, handleMultiSelectDotAnnotationStart);
    multiSelectDotAnnotationRef.current.on('boxend' as any, handleMultiSelectDotAnnotationEnd);
    multiSelectDotAnnotationFreehandRef.current.on('drawstart', handleMultiSelectFreehandStart);
    multiSelectDotAnnotationFreehandRef.current.on('drawend', handleMultiSelectFreehandEnd);
    map.on('pointermove', handlePointerMove.current);

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      if (handlePointerMove.current) {
        map.un('pointermove', handlePointerMove.current);
      }

      if (selectDotAnnotationRef.current) {
        selectDotAnnotationRef.current.un('select', handleClickDotAnnotation);
        map.removeInteraction(selectDotAnnotationRef.current);
      }

      if (modifyDotAnnotationRef.current) {
        modifyDotAnnotationRef.current.un('modifyend', handleModifyDotAnnotationEnd);
        modifyDotAnnotationRef.current.un('modifystart', handleModifyDotAnnotationStart);
        map.removeInteraction(modifyDotAnnotationRef.current);
      }

      if (multiSelectDotAnnotationRef.current) {
        multiSelectDotAnnotationRef.current.un('boxstart' as any, handleMultiSelectDotAnnotationStart);
        multiSelectDotAnnotationRef.current.un('boxend' as any, handleMultiSelectDotAnnotationEnd);
        map.removeInteraction(multiSelectDotAnnotationRef.current);
      }

      if (multiSelectDotAnnotationFreehandRef.current) {
        multiSelectDotAnnotationFreehandRef.current.un('drawstart', handleMultiSelectFreehandStart);
        multiSelectDotAnnotationFreehandRef.current.un('drawend', handleMultiSelectFreehandEnd);
        map.removeInteraction(multiSelectDotAnnotationFreehandRef.current);
      }

      map.un('dblclick', handleDoubleClickDotAnnotation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotatingEnabled, selectedAnnotationIds, isAnnotatingInteractionActive, ignoredCellTypes]);

  //If ignoredCellTypes changes, deselect any hidden cells
  useEffect(() => {
    const selectedAnnotationIdsWithoutIgnoredCells = selectedAnnotationIds.filter(featId => {
      const feat = source.getFeatureById(featId);
      if (feat) {
        return !ignoredCellTypes.includes(feat.get('type'));
      }
      return true;
    });
    dispatch(setSelectedAnnotationIds(selectedAnnotationIdsWithoutIgnoredCells));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoredCellTypes]);

  //If annotationReviewFilters changes, deselect any hidden cells
  useEffect(() => {
    let filteredSelectedAnnotationIds = selectedAnnotationIds;

    const shouldFilterOutReviewedCells = !annotationReviewFilters.includes('show_reviewed');
    if (shouldFilterOutReviewedCells) {
      filteredSelectedAnnotationIds = selectedAnnotationIds.filter(featId => {
        const feat = source.getFeatureById(featId);
        if (feat) {
          return !feat.get('is_reviewed');
        }
        return true;
      });
    }

    const shouldFilterOutUnReviewedCells = !annotationReviewFilters.includes('show_unreviewed');
    if (shouldFilterOutUnReviewedCells) {
      filteredSelectedAnnotationIds = selectedAnnotationIds.filter(featId => {
        const feat = source.getFeatureById(featId);
        if (feat) {
          return feat.get('is_reviewed');
        }
        return false;
      });
    }

    dispatch(setSelectedAnnotationIds(filteredSelectedAnnotationIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationReviewFilters]);

  useEffect(() => {
    if (!map || !isAnnotatingEnabled || !activeAnnotationClassification || !isAnnotatingInteractionActive) return;

    drawDotAnnotationRef.current = new Draw({
      source,
      type: 'Point',
      style: new Style({}),
      stopClick: true,
      condition: e => {
        const dotAnnotationsAtPixel = map.getFeaturesAtPixel(e.pixel, {
          hitTolerance: 10,
          layerFilter: (layer: Layer) => layer.get('name') === OL_LAYER_NAME.ANNOTATIONS,
        });
        return e.originalEvent.button === 0 && dotAnnotationsAtPixel.length === 0 && selectedAnnotationIds.length === 0;
      },
    });

    map.addInteraction(drawDotAnnotationRef.current);

    drawDotAnnotationRef.current.on('drawstart', handleBeforeAddDotAnnotation);
    drawDotAnnotationRef.current.on('drawend', handleAfterAddDotAnnotation);

    return () => {
      if (drawDotAnnotationRef.current) {
        map.removeInteraction(drawDotAnnotationRef.current);
        drawDotAnnotationRef.current.un('drawstart', handleBeforeAddDotAnnotation);
        drawDotAnnotationRef.current.un('drawend', handleAfterAddDotAnnotation);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAnnotatingEnabled,
    activeAnnotationClassification,
    selectedAnnotationIds,
    isAnnotatingInteractionActive,
    ignoredCellTypes,
  ]);

  useEffect(() => {
    if (isAnnotatingInteractionActive || !map) return;

    document.removeEventListener('keydown', handleKeyDown);

    if (selectDotAnnotationRef.current) {
      selectDotAnnotationRef.current.un('select', handleClickDotAnnotation);
      map.removeInteraction(selectDotAnnotationRef.current);
    }

    if (modifyDotAnnotationRef.current) {
      modifyDotAnnotationRef.current.un('modifyend', handleModifyDotAnnotationEnd);
      modifyDotAnnotationRef.current.un('modifystart', handleModifyDotAnnotationStart);
      map.removeInteraction(modifyDotAnnotationRef.current);
    }

    if (multiSelectDotAnnotationRef.current) {
      multiSelectDotAnnotationRef.current.un('boxstart' as any, handleMultiSelectDotAnnotationStart);
      multiSelectDotAnnotationRef.current.un('boxend' as any, handleMultiSelectDotAnnotationEnd);
      map.removeInteraction(multiSelectDotAnnotationRef.current);
    }

    if (multiSelectDotAnnotationFreehandRef.current) {
      multiSelectDotAnnotationFreehandRef.current.un('drawstart', handleMultiSelectFreehandStart);
      multiSelectDotAnnotationFreehandRef.current.un('drawend', handleMultiSelectFreehandEnd);
      map.removeInteraction(multiSelectDotAnnotationFreehandRef.current);
    }

    map.un('dblclick', handleDoubleClickDotAnnotation);

    if (drawDotAnnotationRef.current) {
      map.removeInteraction(drawDotAnnotationRef.current);
      drawDotAnnotationRef.current.un('drawstart', handleBeforeAddDotAnnotation);
      drawDotAnnotationRef.current.un('drawend', handleAfterAddDotAnnotation);
    }

    if (handlePointerMove.current) {
      map.un('pointermove', handlePointerMove.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotatingInteractionActive]);

  const deselectDotAnnotations = () => {
    dispatch(setSelectedAnnotationIds([]));
  };

  const checkForDoubleDots = (featureToPlace: Feature) => {
    const geometry = featureToPlace.getGeometry() as Point;
    let coordinates = geometry.getCoordinates();
    const pixelRadius =
      DOUBLE_DOTS_PREVENTION_RADIUS_MICRONS / ((imageInfo && imageInfo.micronsPerPixel) || DEFAULT_MICRONS_PER_PIXEL);
    const extent = [
      coordinates[0] - pixelRadius,
      coordinates[1] - pixelRadius,
      coordinates[0] + pixelRadius,
      coordinates[1] + pixelRadius,
    ];
    const featuresInExtent = source.getFeaturesInExtent(extent);
    for (let i = 0; i < featuresInExtent.length; i += 1) {
      if (featuresInExtent[i].getId() !== featureToPlace.getId()) return true;
    }
    return false;
  };

  const handleBeforeAddDotAnnotation = (event: DrawEvent) => {
    if (!activeAnnotationClassification) {
      notification['error']({
        key: 'noClassificationSelected',
        message: 'No Classification Selected',
        description: 'Please select a classification before you try to create an Annotation',
        duration: 5,
      });
      abortDotAnnotationRef.current = true;
      return;
    }

    if (ignoredCellTypes.includes(activeAnnotationClassification)) {
      notification['error']({
        key: 'cannotAnnotateHiddenClassification',
        message: 'You cannot Annotate with a hidden Classification',
        description: 'Please un-hide the Classification to continue Annotating',
        duration: 5,
      });

      abortDotAnnotationRef.current = true;
      return;
    }

    if (checkForDoubleDots(event.feature)) {
      // TODO: just throw the error and leave handling to the parent application
      notification['error']({
        message: 'You cannot place Dot Annotations this close together. ',
        description: 'The Dot Annotation has not been placed.',
        duration: 5,
      });
      abortDotAnnotationRef.current = true;
    }

    if ((!selectDotAnnotationRef.current || selectedAnnotationIds.length === 0) && activeAnnotationClassification)
      return;
    deselectDotAnnotations();
    abortDotAnnotationRef.current = true;
  };

  const handleAfterAddDotAnnotation = (event: DrawEvent) => {
    //Abort DotAnnotation here as aborting in handleBeforeAddDotAnnotation causes getGeometry == null error
    if (drawDotAnnotationRef.current && abortDotAnnotationRef.current) {
      abortDotAnnotationRef.current = false;
      return;
    }
    if (!activeAnnotationClassification) {
      notification['error']({
        message: 'You must have a Classification selected',
        description: `Please select a Classification before adding more annotations`,
        duration: 5,
      });
      if (drawDotAnnotationRef.current) {
        drawDotAnnotationRef.current.abortDrawing();
      }
      return;
    }

    const geometry = event.feature.getGeometry() as Point;
    let coordinate = geometry.getCoordinates();
    const coordinates = getRoundedSinglePixel(coordinate);

    const newId = uuidv4();
    event.feature.setId(newId);

    const change: CellChangeAdd = {
      type: 'ADD',
      id: newId,
      payload: {
        id: newId,
        x: coordinates.x,
        y: -coordinates.y,
        is_reviewed: true,
        type: activeAnnotationClassification,
      },
    };

    dispatch(applyChangesetAction([change]));
  };

  const handleSelectDotAnnotation = (event: SelectEvent) => {
    dispatch(setScrollToAnnotationId(''));

    const isCtrlPressed = event.mapBrowserEvent.originalEvent.ctrlKey;

    if (!isCtrlPressed) {
      deselectDotAnnotations();
    }

    const selectedFeature = event.target.getFeatures().getArray()[0];

    if (!selectedFeature) return;

    let selectedIds: Array<string> = [];

    const selectedFeatureID = selectedFeature.getId()?.toString();
    if (!selectedFeatureID) throw new Error('Selected Feature does not have an ID');
    selectedIds.push(selectedFeatureID);

    if (isCtrlPressed) {
      if (selectedAnnotationIds.includes(selectedFeatureID)) {
        dispatch(removeFromSelectedAnnotationIds(selectedFeatureID));
      } else {
        dispatch(appendToSelectedAnnotationIds(selectedIds));
      }
    } else {
      dispatch(setActiveAnnotationClassification(selectedFeature.get('type')));
      dispatch(setSelectedAnnotationIds(selectedIds));
    }

    dispatch(setScrollToAnnotationId(selectedFeatureID));

    const isReviewed = selectedFeature.get('is_reviewed');
    if (!isReviewed) {
      const change: CellChangeReviewStatus = {
        type: 'CHANGE_REVIEW_STATUS',
        id: selectedFeatureID,
        payload: {
          is_reviewed: true,
        },
      };
      dispatch(applyChangesetAction([change]));
    }
  };

  const handleMultiSelectDotAnnotationStart = () => {
    // Temporarily remove pointermove event as causes slow-down when drawing Dragbox
    if (map && handlePointerMove.current) {
      map.un('pointermove', handlePointerMove.current);
    }
  };

  const handleMultiSelectDotAnnotationEnd = () => {
    if (!map || !multiSelectDotAnnotationRef.current) return;
    const dragBox = multiSelectDotAnnotationRef.current.getGeometry();
    let annotationClassifications: Array<string> = [];
    let selectedFeatures: Collection<Feature> = new Collection<Feature>();
    let selectedIds: Array<string> = [];

    const featuresInExtent = source.getFeaturesInExtent(dragBox.getExtent());

    const mapExtent = map.getView().getProjection().getExtent();
    const mapWidth = getWidth(mapExtent);

    // If the view is obliquely rotated the box extent will exceed its geometry so both the box and the candidate
    // feature geometries are rotated around a common anchor to confirm that, with the box geometry aligned with its
    // extent, the geometries intersect.
    // If the view is not obliquely rotated the box geometry and its extent are equivalent so intersecting features can
    // be added directly to the collection.
    const rotation = map.getView().getRotation();
    const oblique = rotation % (Math.PI / 2) !== 0;
    if (oblique) {
      const anchor = [0, 0];
      const geometry = dragBox.clone();
      geometry.translate(-0 * mapWidth, 0);
      geometry.rotate(-rotation, anchor);
      const extent = geometry.getExtent();
      featuresInExtent.forEach((feature: Feature) => {
        const geometry = feature!.getGeometry()!.clone();
        geometry.rotate(-rotation, anchor);
        if (geometry.intersectsExtent(extent)) {
          selectedFeatures.push(feature);
        }
      });
    } else {
      selectedFeatures.extend(featuresInExtent);
    }

    selectedFeatures.forEach((feature: Feature) => {
      if (ignoredCellTypes.includes(feature.get('type'))) return;
      selectedIds.push(feature.getId() as string);
      const dotClassification = feature.get('type');
      if (annotationClassifications.indexOf(dotClassification) === -1) {
        annotationClassifications.push(dotClassification);
      }
    });

    dispatch(setActiveAnnotationClassification(null));
    dispatch(appendToSelectedAnnotationIds(selectedIds));

    // Re-add pointer move event
    if (map && handlePointerMove.current) {
      map.on('pointermove', handlePointerMove.current);
    }
  };

  const handleMultiSelectFreehandStart = () => {
    isDrawingPolygon.current = true;
    // Temporarily remove pointermove event as causes slow-down when drawing Freehand Selection
    if (map && handlePointerMove.current) {
      map.un('pointermove', handlePointerMove.current);
    }
  };

  const handleMultiSelectFreehandEnd = (event: DrawEvent) => {
    if (!multiSelectDotAnnotationFreehandRef.current) return;
    if (!event.feature) return;

    isDrawingPolygon.current = false;

    const extent = event.feature!.getGeometry()?.getExtent();

    let annotationClassifications: Array<string> = [];
    let selectedIds: Array<string> = [];
    source.forEachFeatureInExtent(extent, (feature: Feature) => {
      if (ignoredCellTypes.includes(feature.get('type'))) return;
      selectedIds.push(feature.getId() as string);
      const dotClassification = feature.get('type');
      if (annotationClassifications.indexOf(dotClassification) === -1) {
        annotationClassifications.push(dotClassification);
      }
    });

    dispatch(setActiveAnnotationClassification(null));
    dispatch(appendToSelectedAnnotationIds(selectedIds));

    // Re-add pointer move event
    if (map && handlePointerMove.current) {
      map.on('pointermove', handlePointerMove.current);
    }
  };

  const deleteDotAnnotations = () => {
    const state = store.getState();
    const selectedAnnotationIds = selectSelectedAnnotationIds(state);
    if (selectedAnnotationIds.length === 0) return;
    let changesetAddition: Array<CellChange> = [];
    selectedAnnotationIds.forEach(selectedDotAnnotationId => {
      changesetAddition.push({
        type: 'DELETE',
        id: selectedDotAnnotationId,
        payload: {},
      });
    });

    dispatch(applyChangesetAction(changesetAddition));
    dispatch(setSelectedAnnotationIds([]));
    // Force source to visually update
    source.changed();
    notification['info']({
      message: `Removed ${selectedAnnotationIds.length} Dot Annotation${selectedAnnotationIds.length === 1 ? '' : 's'}`,
    });
  };

  const handleClickDotAnnotation = (event: SelectEvent) => {
    if (event.mapBrowserEvent.type === 'singleclick') handleSelectDotAnnotation(event);
  };

  const handleDoubleClickDotAnnotation = (event: MapBrowserEvent<PointerEvent>) => {
    if (!map) return;
    map
      .getFeaturesAtPixel(event.pixel, {
        layerFilter: (layer: Layer) => {
          return layer.get('name') === OL_LAYER_NAME.ANNOTATIONS;
        },
      })
      .forEach((feature: FeatureLike) => {
        let changesetAddition: Array<CellChange> = [];
        const featureId = feature.getId()?.toString();
        if (!featureId) throw new Error('Feature does not have an ID');
        changesetAddition.push({
          type: 'DELETE',
          id: featureId,
          payload: {},
        });

        dispatch(applyChangesetAction(changesetAddition));
        dispatch(setSelectedAnnotationIds([]));
        notification['info']({
          message: 'Removed 1 Dot Annotation',
        });
        // Force source to visually update
        source.changed();
      });
  };

  const handleModifyDotAnnotationStart = (event: ModifyEvent) => {
    const feature = event.features.getArray()[0] as Feature<Point>;
    if (feature && modifyDotAnnotationRef.current) {
      modifyStartCoordinates.current = feature.getGeometry()!.getCoordinates();
    }
  };

  const handleModifyDotAnnotationEnd = (event: ModifyEvent) => {
    const feature = event.features.getArray()[0] as Feature<Point>;
    if (!feature || !modifyDotAnnotationRef.current) return;

    const featureId = feature.getId()?.toString();
    if (!featureId) throw new Error('Modified Cell does not have an ID');

    const coordinates = feature.getGeometry()!.getCoordinates();
    const roundedCoordinates = getRoundedSinglePixel(coordinates);

    const previousCoordinates = modifyStartCoordinates.current;
    if (!previousCoordinates) return;

    // Set geometry back to original position - if there is an error, dot does not move, but if move is successful, this
    // is overridden when the CHANGE_POSITION action is applied. This allows us to access the original position correctly
    // in the undoInteraction changeset applyChangesetToSource() in util/ol.ts
    feature.getGeometry()!.setCoordinates(previousCoordinates);

    if (checkForDoubleDots(feature)) {
      const roundedPixel = getRoundedSinglePixel(previousCoordinates);
      notification['error']({
        message: 'You cannot place Dot Annotations this close together. ',
        description: `The Dot Annotation has been moved back to x: ${roundedPixel.x}, y: ${-roundedPixel.y}`,
        duration: 5,
      });
      return;
    }

    const changes: CellChange[] = [
      {
        type: 'CHANGE_POSITION',
        id: featureId,
        payload: {
          x: roundedCoordinates.x,
          y: -roundedCoordinates.y,
        },
      },
    ];

    const isReviewed = feature.get('is_reviewed');
    if (!isReviewed) {
      changes.push({
        type: 'CHANGE_REVIEW_STATUS',
        id: featureId,
        payload: {
          is_reviewed: true,
        },
      });
    }

    dispatch(applyChangesetAction(changes));
    dispatch(setSelectedAnnotationIds([]));
  };

  const changeSelectedDotAnnotationClassification = () => {
    if (!activeAnnotationClassification) return;
    let changesetAddition: Array<CellChange> = [];
    selectedAnnotationIds.forEach(selectedDotAnnotationID => {
      const cellFeature = source.getFeatureById(selectedDotAnnotationID);
      if (!cellFeature || cellFeature.get('type') === activeAnnotationClassification) return;
      changesetAddition.push({
        type: 'CHANGE_TYPE',
        id: selectedDotAnnotationID,
        payload: {
          type: activeAnnotationClassification,
        },
      });

      const isReviewed = cellFeature.get('is_reviewed');
      if (!isReviewed) {
        changesetAddition.push({
          type: 'CHANGE_REVIEW_STATUS',
          id: selectedDotAnnotationID,
          payload: {
            is_reviewed: true,
          },
        });
      }
    });

    dispatch(applyChangesetAction(changesetAddition));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.preventDefault();
    switch (event.key) {
      case 'Delete':
      case 'Backspace':
        deleteDotAnnotations();
        break;
      case ' ': //Spacebar
        const isAtLeastOneUnReviewed = selectedAnnotationIds.some(selectedDotAnnotationId => {
          const feature = source.getFeatureById(selectedDotAnnotationId);
          return feature && !feature.get('is_reviewed');
        });
        const updatedIsReviewed = isAtLeastOneUnReviewed;
        let changesetAddition: Array<CellChange> = [];
        selectedAnnotationIds.forEach(selectedDotAnnotationId => {
          changesetAddition.push({
            type: 'CHANGE_REVIEW_STATUS',
            id: selectedDotAnnotationId,
            payload: {
              is_reviewed: updatedIsReviewed,
            },
          });
        });
        dispatch(applyChangesetAction(changesetAddition));
        dispatch(setSelectedAnnotationIds([]));
        break;
      default:
        break;
    }
  };

  return null;
}
