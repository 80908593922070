import AnalysisProgress from 'components/AnalysisProgress';
import { useSelector } from 'react-redux';
import { selectIsROIAnalysisLoading } from 'redux/slices/analysis';
import AnalysisSelectAIScenario from '../AnalysisSelectAIScenario';
import Scores from 'components/AnnotationDetail/Scores';
import CellCounts from 'components/AnnotationDetail/CellCounts';

export default function AnnotationsChangeset() {
  const isROIAnalysisLoading = useSelector(selectIsROIAnalysisLoading);

  return (
    <>
      {<AnalysisSelectAIScenario />}
      {isROIAnalysisLoading ? <AnalysisProgress /> : null}
      <Scores />
      <CellCounts />
    </>
  );
}
