import { Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnnotationOpacity, setAnnotationOpacity } from 'redux/slices/analysis';
import type { SliderMarks } from 'antd/es/slider';

const marks: SliderMarks = {
  0: (
    <div style={{ marginLeft: '16px' }}>
      WSIResults <br />
      Only
    </div>
  ),
  1: <div style={{ marginRight: '8px' }}>Finetuned</div>,
};

const formatter = (value: number | undefined) => (value ? `${value * 100}%` : '0%');

export function DiffAnnotationOpacitySlider() {
  const dispatch = useDispatch();
  const annotationOpacity = useSelector(selectAnnotationOpacity);

  const handleDiffSliderOnChange = (value: number) => {
    dispatch(setAnnotationOpacity(value));
  };

  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <Slider
        min={0}
        max={1}
        step={0.1}
        marks={marks}
        tooltip={{ formatter }}
        value={annotationOpacity}
        onChange={handleDiffSliderOnChange}
      />
    </div>
  );
}
