import { Col, Divider, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectCellCounts, selectScenarioInfo } from 'redux/slices/analysis';
import StatisticWithDotAnnotation from 'components/AnnotationDetail/CellCounts/StatisticWithDotAnnotation';

const CellCounts = () => {
  const cellCounts = useSelector(selectCellCounts);
  const scenarioInfo = useSelector(selectScenarioInfo);

  if (!cellCounts || !scenarioInfo) return null;

  const renderCellCounts = () => {
    return scenarioInfo['classes_order'].map(classification => {
      const scenarioClass = scenarioInfo.classes.find(c => c.id === classification);
      if (!scenarioClass) return null;
      return (
        <Col span={24} key={classification} style={{ marginBottom: '8px' }}>
          <StatisticWithDotAnnotation
            title={scenarioClass.label}
            value={cellCounts[classification]}
            color={scenarioClass.color}
            cellType={scenarioClass.id}
          />
        </Col>
      );
    });
  };

  if (!scenarioInfo) return null;

  return (
    <>
      <Divider style={{ marginBottom: '16px' }} />
      <div style={{ margin: '8px' }}>
        <Typography.Text strong>Cell counts</Typography.Text>
        <Row style={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>{renderCellCounts()}</Row>
      </div>
    </>
  );
};

export default CellCounts;
