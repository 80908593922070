import { Tooltip } from 'antd';
import { Map } from 'ol';
import { Control } from 'ol/control';
import { createRoot } from 'react-dom/client';
import './style.css';

interface CustomRotationArrowProps {
  map: Map;
}

export default function CustomRotationArrow({ map }: CustomRotationArrowProps) {
  const button = document.createElement('button');

  const arrowSpan = document.createElement('span');
  const root = createRoot(arrowSpan);
  root.render(
    <Tooltip title="Reset Rotation" placement="right">
      <div id="custom-rotation-arrow-icon">⇧</div>
    </Tooltip>,
  );
  button.appendChild(arrowSpan);

  const element = document.createElement('div');
  element.className = 'custom-rotation-arrow ol-unselectable ol-control';
  element.style.opacity = '0.3';

  element.style.top = '8px';

  element.appendChild(button);

  button.addEventListener('click', () => {
    map.getView().setRotation(0);
  });

  return new Control({ element: element });
}
