import { CheckCircleTwoTone, ExclamationCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Tag, Tooltip } from 'antd';
import { WSIThumbnail } from 'components/WSIThumbnail';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectActiveImage, setActiveImage } from 'redux/slices/assignments';
import { ANALYSIS_STATUS } from 'utils/constants';

import './style.css';

export const ImageCard = ({ image }: { image: Image }) => {
  const dispatch = useDispatch();

  const activeImage = useSelector(selectActiveImage);

  const getAnalysisStatusIcon = (status: string) => {
    switch (status) {
      case ANALYSIS_STATUS.STARTED:
      case ANALYSIS_STATUS.PROCESSING:
        return <LoadingOutlined color="#1890ff" />;
      case ANALYSIS_STATUS.COMPLETE:
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case ANALYSIS_STATUS.TERMINATED:
      case ANALYSIS_STATUS.ERROR:
        return <ExclamationCircleTwoTone twoToneColor="#f5222d" />;
      default:
        return null;
    }
  };

  const handleImageClick = (image: Image) => {
    dispatch(setActiveImage(image));
  };

  return (
    <Col span={24} key={image.id}>
      <Link to={`image/${image.id}`}>
        <Card
          id={image.id}
          className={activeImage?.id === image.id ? 'active' : ''}
          hoverable
          bodyStyle={{ padding: '4px' }}
          cover={
            <div>
              <WSIThumbnail image={image} height={100} onClick={() => handleImageClick(image)} />
              <div
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: '4px',
                  margin: '0px',
                  textAlign: 'right',
                  lineHeight: 'normal',
                  width: '54px',
                }}
              >
                <Tag
                  style={{
                    width: '100%',
                    padding: '0',
                    margin: '0',
                    textAlign: 'center',
                    fontSize: '10px',
                    lineHeight: 'normal',
                  }}
                >
                  {image.scan_counter ? image.scan_counter : '-'}
                </Tag>
                {image.staining && (
                  <Tag
                    color="purple"
                    style={{
                      width: '100%',
                      margin: '0',
                      textAlign: 'center',
                      fontSize: '10px',
                      lineHeight: 'normal',
                      marginBottom: '2px',
                    }}
                  >
                    {image.staining}
                  </Tag>
                )}
                {image.analysis_details && (
                  <Tooltip
                    title={
                      <span>
                        <strong>Analysis Status:</strong> {image.analysis_details.details}
                      </span>
                    }
                    placement="left"
                  >
                    {getAnalysisStatusIcon(image.analysis_details.status)}
                  </Tooltip>
                )}
              </div>
            </div>
          }
        />
      </Link>
    </Col>
  );
};
