import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearActiveWSIResult, selectChangesetWithPointer } from 'redux/slices/analysis';
import { useAppDispatch } from 'utils/hooks';
import { setIsAnnotatingEnabled, setIsHeatmapEnabled } from 'redux/slices/viewer';

const { confirm } = Modal;

export default function GoBackButton() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const changeset = useSelector(selectChangesetWithPointer);

  const handleGoBack = () => {
    dispatch(setIsHeatmapEnabled(true));
    if (changeset.length > 0) {
      confirm({
        title: 'You have unsaved changes',
        content: 'Are you sure you want to leave this Changeset?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          dispatch(clearActiveWSIResult());
          dispatch(setIsAnnotatingEnabled(false));
          navigate(-1);
        },
        onCancel() {},
      });
    } else {
      dispatch(clearActiveWSIResult());
      dispatch(setIsAnnotatingEnabled(false));
      navigate(-1);
    }
  };

  return <Button onClick={handleGoBack} type="text" icon={<ArrowLeftOutlined />} style={{ marginRight: '4px' }} />;
}
