import { ArrowLeftOutlined, CheckCircleTwoTone, ExclamationCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Spin, Tag, Tooltip, Typography } from 'antd';
import { useAuth } from 'components/auth/AuthProvider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchWSIResultsListForImage,
  selectIsWSIResultsListLoading,
  selectWSIResultsList,
} from 'redux/slices/analysis';
import { selectActiveImage } from 'redux/slices/assignments';
import { ANALYSIS_STATUS } from 'utils/constants';
import { useAppDispatch } from 'utils/hooks';

const { Title } = Typography;

export default function ImageDetail() {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const dispatch = useAppDispatch();
  const wsiResultsList = useSelector(selectWSIResultsList);
  const isWSIResultsListLoading = useSelector(selectIsWSIResultsListLoading);
  const activeImage = useSelector(selectActiveImage);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleWSIResultOnClick = (fineTuningWSIResult: FineTuningWSIResult) => {
    navigate(`wsi_result/${fineTuningWSIResult.id}`);
  };

  const getAnalysisStatusIcon = (status: string) => {
    switch (status) {
      case ANALYSIS_STATUS.STARTED:
      case ANALYSIS_STATUS.PROCESSING:
        return <LoadingOutlined color="#1890ff" />;
      case ANALYSIS_STATUS.COMPLETE:
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case ANALYSIS_STATUS.TERMINATED:
      case ANALYSIS_STATUS.ERROR:
        return <ExclamationCircleTwoTone twoToneColor="#f5222d" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!activeImage || !accessToken) return;
    dispatch(fetchWSIResultsListForImage({ image: activeImage, accessToken: accessToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImage, accessToken]);

  if (!activeImage) return null;

  return (
    <Card
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Go back to list of Case Images">
            <Button onClick={handleGoBack} type="text" icon={<ArrowLeftOutlined />} style={{ marginRight: '4px' }} />
          </Tooltip>
          <Tooltip title={activeImage.name}>
            <Title level={4} style={{ margin: 0 }}>
              {activeImage.name}
            </Title>
          </Tooltip>
        </div>
      }
    >
      <>
        <Title level={5} style={{ marginTop: '0' }}>
          Available Analysis Results
        </Title>
        {isWSIResultsListLoading ? (
          <div style={{ height: '128px', width: '100%', display: 'flex', alignItems: 'center' }}>
            <Spin style={{ width: '100%' }} spinning tip="Loading WSIResults" />
          </div>
        ) : wsiResultsList ? (
          wsiResultsList.map((fineTuningWSIResult, index) => {
            const wsiResult = fineTuningWSIResult.wsi_result;

            return (
              <Card
                key={index}
                type="inner"
                title={
                  <>
                    {wsiResult.ai_scenario} {getAnalysisStatusIcon(wsiResult.status)}
                  </>
                }
                extra={wsiResult.lab_id ? <Tag>Lab ID{wsiResult.lab_id}</Tag> : null}
                onClick={() => handleWSIResultOnClick(fineTuningWSIResult)}
                hoverable
              >
                {wsiResult.details}
              </Card>
            );
          })
        ) : null}
      </>
    </Card>
  );
}
