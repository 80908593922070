import ViewerContext from 'components/Viewer/ViewerContext';
import OLVectorLayer from 'ol/layer/Vector';
import { useContext, useEffect } from 'react';
import { OL_LAYER_NAME } from 'utils/constants';

const EraserToolLayer = ({ source }: EraserToolLayerProps) => {
  const { map } = useContext(ViewerContext);

  useEffect(() => {
    if (!map) return;

    const eraserToolLayer = new OLVectorLayer({
      className: OL_LAYER_NAME.ERASER_TOOL,
      properties: { name: OL_LAYER_NAME.ERASER_TOOL },
      source,
      zIndex: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(eraserToolLayer);

    return () => {
      if (map) {
        map.removeLayer(eraserToolLayer);
      }
    };
  }, [map, source]);

  return null;
};

export default EraserToolLayer;
