import { ImageTile, Tile } from 'ol';
import FetchRetry from 'fetch-retry';

export const backendUrl = (endpoint: string): URL => {
  const base = window._env_.REACT_APP_BACKEND_API_URL || 'http://localhost:8000';
  return new URL(`${base}/api/${endpoint}`);
};

export const readerUrl = (endpoint: string): URL => {
  const base = window._env_.REACT_APP_READER_API_URL || 'http://localhost:8008';
  return new URL(`${base}/iiif/${endpoint}`);
};

// noinspection JSUnusedGlobalSymbols
const fetchRetry = FetchRetry(fetch, {
  retryDelay: 1000,
  retryOn: async (attempt: number, error: Error, response: Response) => {
    if (attempt === 1) {
      return false;
    }
    // retry on any network error, or 4xx or 5xx status codes
    if (error !== null || response.status >= 400) {
      return true;
    }
  },
});

export const getThumbnailBlobPromise = (uri: URL, accessToken: string) => {
  return new Promise((resolve, reject) => {
    const options = {
      headers: {
        Authorization: `Mind_Peak_Bearer ${accessToken}`,
      },
    };

    fetch(uri, options)
      .then(res => res.blob())
      .then(resolve)
      .catch(reject);
  });
};

export const getRequestWithAuthToken = (url: URL, signal: AbortSignal | null, accessToken: string | null) => {
  return fetchRetry(url.toString(), {
    headers: {
      Authorization: `Mind_Peak_Bearer ${accessToken}`,
    },
    signal: signal,
  }) as Promise<Response>;
};

export const simplePostRequest = (url: URL, data: any, signal: AbortSignal | null, accessToken: string | null) => {
  return fetchRetry(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Mind_Peak_Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
    signal: signal,
  }) as Promise<Response>;
};

export const postImageDataRequest = (url: URL, data: Blob, aiToken: string, aiAPIKey: string, aiUserId: string) => {
  const formData = new FormData();
  formData.append('image', data);
  return fetchRetry(url.toString(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${aiToken || ''}`,
      'X-API-Key': aiAPIKey || '',
      'MindPeak-User': aiUserId || '',
    },
    body: formData,
  });
};

export const getAIScenarioRequest = (url: URL, signal: AbortSignal, aiToken: string, aiAPIKey: string) => {
  return fetchRetry(url.toString(), {
    headers: {
      Authorization: `Bearer ${aiToken || ''}`,
      'X-API-Key': aiAPIKey || '',
    },
    signal: signal,
  });
};

export const authenticatedTileLoader = (tile: Tile, src: string, accessToken: string | null) => {
  getRequestWithAuthToken(src as any as URL, null, accessToken)
    .then(resp => {
      if (!resp) {
        throw new Error('Error loading tile');
      }
      const response = resp as Response;

      let data = response.blob();

      if (data !== undefined) {
        return data;
      }
      //3 = TileState.ERROR from ol but ol import breaks unit tests
      tile.setState(3);
    })
    .then(result => {
      if (result) {
        ((tile as ImageTile).getImage() as HTMLImageElement).src = URL.createObjectURL(result);
      }
    })
    .catch(error => {
      throw new Error(`error loading tile - ${error.toString()}`);
    });
};

export const getFromBackendPromise = async (url: URL, signal: AbortSignal | null, accessToken: string | null) => {
  const response = await getRequestWithAuthToken(url, signal, accessToken);
  if (response) {
    if (!response.ok) {
      if (response.status === 404) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Error connecting to Image Server. Please try again later.');
      }
      return response.json().then(json => {
        return Promise.reject(json);
      });
    }
    return response.json();
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject('Error connecting to Image Server. Please try again later.');
};
