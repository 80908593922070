import { Divider } from 'antd';
import AnnotationsVisibilitySwitch from 'components/ViewerToolbar/AnnotationVisibilitySwitch';
import DiffSelector from 'components/ViewerToolbar/DiffSelector';
import UndoRedo from 'components/ViewerToolbar/UndoRedo';
import EnableAnnotationCheckbox from 'components/ViewerToolbar/EnableAnnotationCheckbox';
import { useParams } from 'react-router-dom';
import ClassificationSelector from 'components/ViewerToolbar/ClassificationSelector';
import SaveButton from 'components/ViewerToolbar/SaveButton';
import AnnotationReviewFilter from 'components/ViewerToolbar/AnnotationReviewFilter';
import HeatmapSwitch from 'components/ViewerToolbar/HeatmapSwitch';

export default function ViewerToolbar() {
  const { userChangesId } = useParams();

  if (!userChangesId) return <div style={{ background: 'white', height: '88px' }} />;

  return (
    <div style={{ background: 'white', padding: '8px 16px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EnableAnnotationCheckbox /> <Divider type="vertical" /> <UndoRedo /> <Divider type="vertical" /> <SaveButton />{' '}
        <Divider type="vertical" /> <AnnotationsVisibilitySwitch />
        <Divider type="vertical" /> <DiffSelector />
        <Divider type="vertical" /> <HeatmapSwitch />
      </div>
      <div style={{ marginTop: '8px' }}>
        <AnnotationReviewFilter />
        <Divider type="vertical" />
        <ClassificationSelector />
      </div>
    </div>
  );
}
