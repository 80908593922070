import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import HeatmapLayer from 'ol/layer/Heatmap';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllowHeatmapRender,
  selectAnnotationOpacity,
  selectClassifications,
  selectDisplayDiffType,
  selectIgnoredCellTypes,
} from 'redux/slices/analysis';
import { setShouldShowHeatmapLegend } from 'redux/slices/userInterface';
import { setIsAnnotatingEnabled, setIsAnnotatingPrevented } from 'redux/slices/viewer';
import { ANNOTATION_LAYER_MIN_ZOOM, HEATMAP_LAYER_MAX_ZOOM, OL_LAYER_NAME } from 'utils/constants';
import ViewerContext from 'components/Viewer/ViewerContext';

export default function AnnotationLayerWSIResultsOnlyHeatmap({ source }: HeatmapLayerProps) {
  const { map } = useContext(ViewerContext);
  const dispatch = useDispatch();
  const classes = useSelector(selectClassifications);
  const allowHeatmapRender = useSelector(selectAllowHeatmapRender);
  const heatmapLayer = useRef<HeatmapLayer | null>(null);
  const ignoredCellTypes = useSelector(selectIgnoredCellTypes);
  const displayDiffType = useSelector(selectDisplayDiffType);
  const annotationOpacity = useSelector(selectAnnotationOpacity);

  useEffect(() => {
    if (!heatmapLayer.current) return;
    heatmapLayer.current?.setOpacity(0.6 - (annotationOpacity / 10) * 6);
    heatmapLayer.current?.changed();
  }, [annotationOpacity]);

  // Only add/render HeatmapLayer when all annotations have been loaded to source, otherwise there is a huge performance
  // hit if annotations are added to source when Heatmap is already added
  // (e.g. UserChanges added after initial WSI Results load)
  useEffect(() => {
    if (!map || !source || classes.length === 0 || !allowHeatmapRender) return;

    if (displayDiffType === 'none') return;

    // Clone list of features from source so that features are not connected and changes to AnnotationLayer features
    // don't cause re-renders in AnnotationLayerHeatmap even if not visible
    let featuresToCopy;
    if (ignoredCellTypes.length > 0) {
      featuresToCopy = source
        .getFeatures()
        .filter((feat: Feature<Point>) => !ignoredCellTypes.includes(feat.get('type')));
    } else {
      featuresToCopy = source.getFeatures();
    }

    const featureList = featuresToCopy.map((feat: Feature<Point>) => feat.clone());

    const sourceToUse = new VectorSource({ features: featureList });

    const clusterSource: any = new Cluster({
      distance: 10,
      source: sourceToUse,
      wrapX: false,
    });

    heatmapLayer.current = new HeatmapLayer({
      className: OL_LAYER_NAME.HEATMAP_WSI_RESULTS_ONLY,
      source: clusterSource,
      zIndex: 1,
      maxZoom: HEATMAP_LAYER_MAX_ZOOM,
      blur: 20,
      radius: 10,
      properties: { name: OL_LAYER_NAME.HEATMAP_WSI_RESULTS_ONLY },
      opacity: 0.6,
    });

    map.addLayer(heatmapLayer.current);

    map.getView().on('change:resolution', event => {
      if (!heatmapLayer.current) return;

      // 'Fade out' Layer as user zooms in
      const newZoom = event.target.getZoom();
      if (newZoom < HEATMAP_LAYER_MAX_ZOOM && newZoom > ANNOTATION_LAYER_MIN_ZOOM) {
        const newOpacity = -(newZoom - HEATMAP_LAYER_MAX_ZOOM);
        heatmapLayer.current.setOpacity(newOpacity);
      }

      if (newZoom >= HEATMAP_LAYER_MAX_ZOOM) {
        dispatch(setIsAnnotatingPrevented(false));
        dispatch(setShouldShowHeatmapLegend(false));
      } else {
        dispatch(setIsAnnotatingPrevented(true));
        dispatch(setIsAnnotatingEnabled(false));
        dispatch(setShouldShowHeatmapLegend(true));
      }
    });

    return () => {
      if (map && heatmapLayer.current) {
        clusterSource.setSource(null);
        map.removeLayer(heatmapLayer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source, classes, allowHeatmapRender, ignoredCellTypes, displayDiffType]);

  return null;
}
