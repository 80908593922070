import { Skeleton, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';

import './style.css';
import { getThumbnailBlobPromise } from 'service/api';
import { useAuth } from 'components/auth/AuthProvider';

export const WSIThumbnail = ({ image, height, onClick }) => {
  const [thumbnailSize, setThumbnailSize] = useState(null);
  const [thumbnailObjectURL, setThumbnailObjectURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isInViewport, setIsInViewport] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const auth = useAuth();

  useEffect(() => {
    if (!isInViewport) return;
    const getThumbnail = async image => {
      if (!image?.name) return;
      try {
        const thumbnailURI = new URL(
          `${window._env_.REACT_APP_READER_API_URL}/thumbnail/${image.name}${image.file_type}`,
        );
        const thumbnailBlobPromise = await getThumbnailBlobPromise(thumbnailURI, auth.accessToken);

        const thumbnailBlob = URL.createObjectURL(thumbnailBlobPromise);

        const img = new Image();
        img.src = thumbnailBlob;
        img.onload = () => {
          const wsiWidth = img.width;
          const wsiHeight = img.height;
          setThumbnailSize([wsiWidth, wsiHeight]);
          setThumbnailObjectURL(thumbnailBlob);
        };
      } catch (err) {
        setShowSkeleton(true);
      }
      setIsLoading(false);
    };
    getThumbnail(image);
  }, [image, isInViewport, auth.accessToken]);

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      // IntersectionObserver Supported
      let config = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      let observer = new IntersectionObserver(onChange, config);
      observer.observe(imageRef.current);

      function onChange(changes, observer) {
        changes.forEach(change => {
          if (change.intersectionRatio > 0) {
            // Stop watching and load the image
            setIsInViewport(true);
            observer.unobserve(change.target);
          }
        });
      }
    } else {
      // IntersectionObserver NOT Supported
      setIsInViewport(true);
    }
  }, []);

  const fitImageIntoContainer = (imageWidth, imageHeight, containerWidth, containerHeight) => {
    const imageRatio = imageWidth / imageHeight;
    const containerRatio = containerWidth / containerHeight;
    const newDimensions = {};
    if (imageRatio > containerRatio) {
      // Image is more landscape than container - fit to width
      newDimensions.width = containerWidth;
      newDimensions.height = imageHeight * (containerWidth / imageWidth);
    } else {
      // Image is more portrait than container - fit to height
      newDimensions.width = imageWidth * (containerHeight / imageHeight);
      newDimensions.height = containerHeight;
    }
    return newDimensions;
  };

  const generateStyle = () => {
    const style = {};
    if (!thumbnailSize || !containerRef.current) return style;
    if (isLoading) return { display: 'none' };
    const [thumbnailWidth, thumbnailHeight] = thumbnailSize;
    const { offsetWidth: containerWidth } = containerRef.current;
    const imageDimensions = fitImageIntoContainer(thumbnailWidth, thumbnailHeight, containerWidth, height);
    style['width'] = imageDimensions.width;
    style['height'] = imageDimensions.height;
    return style;
  };

  return (
    <div onClick={onClick} className="thumbnail-container" ref={containerRef} style={{ height }}>
      <Spin style={{ width: '100%' }} spinning={isLoading && isInViewport}>
        {!image || showSkeleton ? (
          <Skeleton.Image width="100%" />
        ) : (
          <img src={thumbnailObjectURL} ref={imageRef} style={generateStyle()} alt="" />
        )}
      </Spin>
    </div>
  );
};
