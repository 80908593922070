import { Tooltip } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectClassifications, selectDisplayDiffType, selectUserChanges } from 'redux/slices/analysis';
import { selectIsAnnotatingEnabled, selectIsAnnotatingPrevented, setIsAnnotatingEnabled } from 'redux/slices/viewer';
import { useAppDispatch } from 'utils/hooks';
import { setActiveInteractions } from 'redux/slices/userInterface';
import { INTERACTION } from 'utils/constants';
import { useAuth } from '../../auth/AuthProvider';

export default function EnableAnnotationCheckbox() {
  const dispatch = useAppDispatch();
  const classifications = useSelector(selectClassifications);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const displayDiffType = useSelector(selectDisplayDiffType);
  const isAnnotatingPrevented = useSelector(selectIsAnnotatingPrevented);
  const { userChangesId } = useParams();
  const activeUserChanges = useSelector(selectUserChanges);
  const { user } = useAuth();

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    dispatch(setIsAnnotatingEnabled(event.target.checked));
    dispatch(setActiveInteractions([INTERACTION.ANNOTATE]));
  };

  const changesetDoesNotBelongToUser = activeUserChanges && user && activeUserChanges.user !== user?.username;

  const shouldDisableCheckbox =
    isAnnotatingPrevented ||
    !userChangesId ||
    (userChangesId && userChangesId === 'none') ||
    !!(userChangesId && classifications.length === 0) ||
    displayDiffType !== 'none' ||
    changesetDoesNotBelongToUser;

  let toolTipText = <span />;
  if (isAnnotatingPrevented) {
    toolTipText = <span>You cannot Annotate while the heatmap is active</span>;
  }
  if (!userChangesId) {
    toolTipText = <span>You cannot make changes directly to the WSI Results. Please create a changeset first</span>;
  }
  if (displayDiffType === 'diff') {
    toolTipText = <span>You cannot Annotate while viewing the Diff</span>;
  }
  if (displayDiffType === 'wsi_results_only') {
    toolTipText = <span>You cannot Annotate while viewing WSI Results only</span>;
  }
  if (changesetDoesNotBelongToUser) {
    toolTipText = (
      <span>
        You cannot Annotate a Changeset that does not belong to your User.
        <br />
        This Changeset belongs to <strong>{activeUserChanges.user}</strong>.
        <br />
        If you want to work on this changeset, please create your own copy.
      </span>
    );
  }

  return (
    <Tooltip title={toolTipText} trigger={!!shouldDisableCheckbox ? 'hover' : undefined} placement="bottom">
      <Checkbox
        id="enable-annotation-checkbox"
        onChange={handleCheckboxChange}
        checked={isAnnotatingEnabled}
        disabled={!!shouldDisableCheckbox}
      >
        Enable Annotation
      </Checkbox>
    </Tooltip>
  );
}
