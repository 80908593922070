import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from 'assets/images/mindpeak_logoform_digital_purple_borderless.png';
import { useAuth } from 'components/auth/AuthProvider';
import './style.css';

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logIn, loggingIn, loginError } = useAuth();

  let from = location.state?.from?.pathname || '/';

  function handleSubmit(values: { username: string; password: string }) {
    logIn({ username: values.username.trim(), password: values.password }, () => {
      navigate(from, { replace: true });
    });
  }

  return (
    <div>
      <div className="login" style={{ background: '#7AE1BF' }}>
        <div className="form-container">
          <Form className="login-form" initialValues={{ remember: true }} onFinish={handleSubmit} layout="vertical">
            <Card>
              <Form.Item style={{ textAlign: 'center' }}>
                <img width={360} src={logo} alt="Mindpeak Logo" />
              </Form.Item>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                  autoComplete="current-username"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  autoComplete="current-password"
                  placeholder="Password"
                  size="large"
                />
              </Form.Item>
              <Form.Item style={{ clear: 'both' }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ width: '100%', marginTop: 16 }}
                  loading={loggingIn}
                  size="large"
                >
                  {loggingIn ? 'Logging In' : 'Log in'}
                </Button>
              </Form.Item>
              {loginError && <Alert message={loginError.name} description={loginError.message} type="error" showIcon />}
            </Card>
          </Form>
        </div>
      </div>
    </div>
  );
}
