import { Card, Tooltip, Typography } from 'antd';
import AnnotationsChangeset from 'components/AnnotationsChangeset';
import { useAuth } from 'components/auth/AuthProvider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchUserChangesData,
  fetchWSIResultsData,
  selectUserChanges,
  selectWSIResultData,
  setDisplayDiffType,
} from 'redux/slices/analysis';
import { selectActiveImage } from 'redux/slices/assignments';
import { useAppDispatch } from 'utils/hooks';
import GoBackButton from './GoBackButton';
import './style.css';

const { Title } = Typography;

export default function AnnotationDetail() {
  const dispatch = useAppDispatch();
  const { accessToken } = useAuth();
  const { fineTuningWSIResultId, userChangesId } = useParams();
  const activeUserChanges = useSelector(selectUserChanges);
  const activeImage = useSelector(selectActiveImage);
  const wsiResultData = useSelector(selectWSIResultData);

  useEffect(() => {
    if (!fineTuningWSIResultId || !accessToken || !userChangesId || !activeImage) return;
    dispatch(fetchWSIResultsData({ fineTuningWSIResultId, accessToken, userChangesId }));
  }, [dispatch, fineTuningWSIResultId, accessToken, userChangesId, activeImage]);

  useEffect(() => {
    if (!userChangesId || !accessToken || !wsiResultData) return;
    if (userChangesId !== 'none') {
      dispatch(fetchUserChangesData({ userChangesId, accessToken }));
    } else {
      dispatch(setDisplayDiffType('wsi_results_only'));
      //dispatch(setAllowHeatmapRender(true));
    }
  }, [dispatch, wsiResultData, userChangesId, accessToken]);

  return (
    <Card
      id="annotation-detail-card"
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Go back to list of User Changes">
            <GoBackButton />
          </Tooltip>
          <Tooltip title={activeUserChanges?.name}>
            <Title level={4} style={{ margin: 0 }}>
              {activeUserChanges ? activeUserChanges.name : 'Unchanged WSI Results'}
            </Title>
          </Tooltip>
        </div>
      }
    >
      <AnnotationsChangeset />
    </Card>
  );
}
