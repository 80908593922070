import { Col, Divider, Empty, Row, Spin, Typography } from 'antd';
import { CaseCard } from 'components/CaseCard';
import { useEffect } from 'react';
import { useAppDispatch } from 'utils/hooks';
import { useAuth } from 'components/auth/AuthProvider';
import { fetchAssignmentsImageServer, selectCasesAndNoCase, selectIsCasesLoading } from 'redux/slices/assignments';
import { useSelector } from 'react-redux';
import ImageSearchAutoComplete from '../ImageSearchAutoComplete';

const { Title } = Typography;

export default function Cases() {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const cases = useSelector(selectCasesAndNoCase);
  const isCasesLoading = useSelector(selectIsCasesLoading);

  useEffect(() => {
    if (!auth.accessToken) return;
    dispatch(fetchAssignmentsImageServer(auth.accessToken));
  }, [dispatch, auth.accessToken]);

  return (
    <div style={{ border: 0, height: 'calc(100% - 64px)' }}>
      <div>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '8px 8px 8px 16px',
              }}
            >
              <Title level={3} style={{ margin: 0 }}>
                Case Assignments ({cases ? cases.length : 0})
              </Title>
              <ImageSearchAutoComplete />
            </div>
            <Divider style={{ marginTop: '8px', marginBottom: 0 }} />
          </Col>
        </Row>
      </div>
      <div style={{ height: '100%', width: '100%', overflow: 'auto', padding: '16px 24px 0 24px' }}>
        {isCasesLoading ? (
          <div
            className="case-grid"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Spin spinning={isCasesLoading} tip="Loading Cases..." />
          </div>
        ) : cases.length === 0 ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>You have not been assigned any Cases</span>}
            />
          </div>
        ) : (
          <Row gutter={[16, 24]} style={{ width: '100%', height: '100%' }}>
            {cases.map((caseData: Case) => (
              <CaseCard key={caseData.id} caseData={caseData} />
            ))}
          </Row>
        )}
      </div>
    </div>
  );
}
