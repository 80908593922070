import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'components/auth/AuthProvider';
import { ReactNode } from 'react';

export default function RequireAuth({ children }: { children: ReactNode }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect user to /login page, but save current location so they can be redirected after login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
