import { Card, Col, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { WSIThumbnail } from 'components/WSIThumbnail';

export const CaseCard = ({ caseData }: { caseData: Case }) => {
  if (!caseData) return null;

  const firstImage = caseData.images[0];

  return (
    <Col key={caseData.id} span={6}>
      <Link to={`case/${caseData.id}/image/${firstImage.id}`}>
        <Card
          id={caseData.id}
          size="small"
          hoverable
          cover={<WSIThumbnail height={200} image={firstImage} onClick={null} />}
        >
          <Card.Meta
            title={<Tooltip title={caseData.name}>{caseData.name}</Tooltip>}
            description={
              <div>
                <b>Images:</b> {caseData.images.length}
              </div>
            }
          />
        </Card>
      </Link>
    </Col>
  );
};
