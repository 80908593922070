import { SaveOutlined } from '@ant-design/icons';
import { Badge, Button, notification, Tooltip } from 'antd';
import { useAuth } from 'components/auth/AuthProvider';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchSaveUserChanges,
  selectChangesetWithPointer,
  selectClassifications,
  selectIsSavingChangeset,
  selectIsSavingDisabled,
} from 'redux/slices/analysis';
import { selectIsAnnotatingEnabled } from 'redux/slices/viewer';
import { useAppDispatch } from 'utils/hooks';

export default function SaveButton() {
  const dispatch = useAppDispatch();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const changeset = useSelector(selectChangesetWithPointer);
  const isSavingDisabled = useSelector(selectIsSavingDisabled);
  const isSavingChangeset = useSelector(selectIsSavingChangeset);
  const classifications = useSelector(selectClassifications);
  const { accessToken } = useAuth();
  const params = useParams();

  const handleSaveAnnotations = () => {
    if (isSavingDisabled) {
      notification['error']({
        message: 'Please wait for change action to complete before saving',
      });
      return;
    }

    if (!accessToken) return;
    dispatch(fetchSaveUserChanges({ changeset: changeset, userChangesId: params?.userChangesId, accessToken }));
  };

  return (
    <Tooltip
      title="Saving is not available until Annotations have been added, edited or removed"
      trigger={isAnnotatingEnabled && changeset.length === 0 ? 'hover' : ''}
    >
      <Button
        className="annotation-tool-save-button"
        type="primary"
        onClick={handleSaveAnnotations}
        loading={isSavingChangeset}
        disabled={isSavingDisabled || changeset.length === 0 || classifications.length === 0}
      >
        Save <SaveOutlined />
        <Badge
          className="save-button-count-badge"
          showZero
          overflowCount={999}
          count={changeset.length}
          style={{
            backgroundColor: changeset.length > 0 ? '#ff0000' : '#aaaaaa',
            color: '#ffffff',
          }}
        />
      </Button>
    </Tooltip>
  );
}
