import { Skeleton, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './style.css';
import { getThumbnailBlobPromise } from 'service/api';
import { useAuth } from 'components/auth/AuthProvider';

export const ImageSearchWSIThumbnail = ({ image }: { image: Image }) => {
  const [thumbnailObjectURL, setThumbnailObjectURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const auth = useAuth();

  useEffect(() => {
    const getThumbnail = async (image: Image) => {
      if (!image?.name) return;
      if (!auth.accessToken) return;
      try {
        const thumbnailURI = new URL(
          `${window._env_.REACT_APP_READER_API_URL}/thumbnail/${image.name}${image.file_type}`,
        );

        const thumbnailBlobPromise = (await getThumbnailBlobPromise(thumbnailURI, auth.accessToken)) as Blob;

        const thumbnailBlob = URL.createObjectURL(thumbnailBlobPromise);

        const img = new Image();
        img.src = thumbnailBlob;
        img.onload = () => {
          setThumbnailObjectURL(thumbnailBlob);
        };
      } catch (err) {
        setShowSkeleton(true);
      }
      setIsLoading(false);
    };
    getThumbnail(image);
  }, [image, auth.accessToken]);

  if (!image || showSkeleton) return <Skeleton.Image className="autocomplete-wsi-thumbnail-skeleton" />;

  return (
    <div className="autocomplete-wsi-thumbnail-container" ref={containerRef}>
      <Spin style={{ width: '100%' }} spinning={isLoading}>
        <img src={thumbnailObjectURL} ref={imageRef} style={{ width: '48px', height: '48px' }} alt="" />
      </Spin>
    </div>
  );
};
