import ImageDetail from 'components/ImageDetail';
import { useAppDispatch } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { Layout, Tooltip } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
import WSIResultDetail from '../WSIResultDetail';
import AnnotationDetail from 'components/AnnotationDetail';
import { selectIsAnnotationSidebarCollapsed, setIsAnnotationSidebarCollapsed } from 'redux/slices/userInterface';

const { Sider } = Layout;

export default function AnnotationSidebar() {
  const dispatch = useAppDispatch();
  const isAnnotationSidebarCollapsed = useSelector(selectIsAnnotationSidebarCollapsed);

  const handleOnCollapse = (collapsed: boolean) => {
    dispatch(setIsAnnotationSidebarCollapsed(collapsed));
  };

  return (
    <Sider
      collapsible
      width={360}
      collapsed={isAnnotationSidebarCollapsed}
      onCollapse={handleOnCollapse}
      collapsedWidth={40}
      theme="light"
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        borderTop: '1px solid #f0f0f0',
        borderLeft: '1px solid #f0f0f0',
        marginBottom: '48px',
      }}
      trigger={
        <Tooltip
          title={isAnnotationSidebarCollapsed ? 'Click here to expand the Sidebar' : 'Click here to hide the Sidebar'}
          placement="right"
        >
          <div id="minimise-sidebar-button" style={{ width: '100%' }}>
            {isAnnotationSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </Tooltip>
      }
    >
      <Routes>
        <Route path="/" element={<ImageDetail />} />
        <Route path="wsi_result/:fineTuningWSIResultId" element={<WSIResultDetail />} />
        <Route path="wsi_result/:fineTuningWSIResultId/userChangesId/:userChangesId" element={<AnnotationDetail />} />
      </Routes>
    </Sider>
  );
}
