import CustomRotationArrow from 'components/CustomControls/CustomRotationArrow';
import CustomRotationClockwise from 'components/CustomControls/CustomRotationClockwise';
import { ScaleLine } from 'ol/control';
import { defaults as defaultInteractions } from 'ol/interaction';
import DragRotateAndZoom from 'ol/interaction/DragRotateAndZoom';
import Map from 'ol/Map';
import 'ol/ol.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsTileLoadProgressBarActive,
  selectIsAnnotationSidebarCollapsed,
  selectIsImageSidebarCollapsed,
} from 'redux/slices/userInterface';
import './style.css';
import ViewerContext from './ViewerContext';
import { selectActiveImage } from 'redux/slices/assignments';

const Viewer = ({ children }: { children: any }) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const [map, setMap] = useState<Map | null>(null);
  const isAnnotationSidebarCollapsed = useSelector(selectIsAnnotationSidebarCollapsed);
  const isImageSidebarCollapsed = useSelector(selectIsImageSidebarCollapsed);
  const activeImage = useSelector(selectActiveImage);

  useEffect(() => {
    const interactions = defaultInteractions({
      doubleClickZoom: false,
    }).extend([new DragRotateAndZoom()]);

    const olMap = new Map({
      layers: [],
      interactions,
      controls: [],
    });
    const scaleControl = new ScaleLine({
      units: 'metric',
    });

    if (mapRef.current) {
      olMap.setTarget(mapRef.current);
    }

    setMap(olMap);

    olMap.addControl(CustomRotationArrow({ map: olMap }));
    olMap.addControl(CustomRotationClockwise({ map: olMap }));
    olMap.addControl(scaleControl);

    olMap.on('loadstart', function () {
      dispatch(setIsTileLoadProgressBarActive(true));
    });
    olMap.on('loadend', function () {
      dispatch(setIsTileLoadProgressBarActive(false));
    });

    return function cleanup() {
      olMap.setTarget(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map) return;
    //map.updateSize() doesn't work without a small delay
    setTimeout(() => {
      map.updateSize();
    }, 200);
  }, [map, isImageSidebarCollapsed, isAnnotationSidebarCollapsed, activeImage]);

  return (
    <div id="map-container">
      <ViewerContext.Provider value={{ map }}>
        <div ref={mapRef} id="map">
          {children}
        </div>
      </ViewerContext.Provider>
    </div>
  );
};

export default Viewer;
