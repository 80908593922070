import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Progress, Typography } from 'antd';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnalysisProgress, setShouldCancelAIRequest } from 'redux/slices/analysis';

export default function AnalysisProgress() {
  const dispatch = useDispatch();
  const analysisProgress = useSelector(selectAnalysisProgress);

  const cancelAIRequest = () => {
    dispatch(setShouldCancelAIRequest(true));
  };

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      cancelAIRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', __handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
    };
  });

  return (
    <Card size="small">
      Analysis Progress...
      <Progress percent={analysisProgress} showInfo={false} status="active" />
      <Button danger icon={<CloseOutlined />} onClick={cancelAIRequest}>
        Cancel Analysis
      </Button>
      <Typography.Text style={{ paddingLeft: '8px' }}>
        (Press <Typography.Text code>Esc</Typography.Text> to Cancel)
      </Typography.Text>
    </Card>
  );
}
