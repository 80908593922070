import { Outlet, Route, Routes } from 'react-router-dom';
import AuthProvider from 'components/auth/AuthProvider';
import LoginPage from 'components/Login';
import RequireAuth from 'components/auth/RequireAuth';
import ProtectedPage from 'components/ProtectedPage';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { version } from 'version';

export default function App() {
  if (process.env['NODE_ENV'] === 'production') {
    Sentry.init({
      dsn: `${window._env_.REACT_APP_SENTRY_DSN}`,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      release: version.version,
    });
  }

  return (
    <AuthProvider>
      <Routes>
        <Route element={<Outlet />}>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <ProtectedPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
