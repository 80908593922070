import { Card, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectShouldShowHeatmapLegend } from 'redux/slices/userInterface';
import './style.css';

const { Text } = Typography;

export default function HeatmapLegend() {
  const shouldShowHeatmapLegend = useSelector(selectShouldShowHeatmapLegend);

  if (!shouldShowHeatmapLegend) return null;

  return (
    <Card
      id="heatmap-legend"
      style={{ zIndex: 2, position: 'absolute', left: 8, bottom: 32, width: '280px', background: '#ffffffcc' }}
    >
      <div>
        <Text strong style={{ fontSize: '12px' }}>
          Cell Density:
        </Text>
        <div
          style={{
            height: '10px',
            width: '100%',
            background: 'linear-gradient(90deg, #00f 0%, #0ff 10%, #0f0 20%, #ff0 50%, #f00 100%)',
          }}
        />
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <Text style={{ fontSize: '10px', lineHeight: '1' }}>Low</Text>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Text style={{ fontSize: '10px', lineHeight: '1' }}>High</Text>
          </div>
        </div>
      </div>
    </Card>
  );
}
