import { Button, Card, Divider, Spin, Tooltip, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from 'utils/hooks';
import {
  fetchUserChangesListForWSIResult,
  selectIsCreatingUserChangesLoading,
  selectIsUserChangesListLoading,
} from 'redux/slices/analysis';
import { useAuth } from '../auth/AuthProvider';
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CreateNewChangesetModal from 'components/CreateNewChangesetModal';
import UserChangesList from 'components/UserChangesList';

const { Title } = Typography;

export default function WSIResultDetail() {
  const { imageId, fineTuningWSIResultId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isUserChangesListLoading = useSelector(selectIsUserChangesListLoading);
  const isCreatingUserChangesLoading = useSelector(selectIsCreatingUserChangesLoading);
  const { accessToken } = useAuth();

  useEffect(() => {
    if (!imageId || !fineTuningWSIResultId || !accessToken) return;

    dispatch(fetchUserChangesListForWSIResult({ fineTuningWSIResultId, accessToken }));
  }, [dispatch, imageId, fineTuningWSIResultId, accessToken]);

  const handleLoadUserChanges = (userChangesId: string) => {
    navigate(`userChangesId/${userChangesId}`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Go back to list of WSI Results">
              <Button onClick={handleGoBack} type="text" icon={<ArrowLeftOutlined />} style={{ marginRight: '4px' }} />
            </Tooltip>
            <Title level={4} style={{ margin: 0 }}>
              User Changes
            </Title>
          </div>
        }
      >
        {isUserChangesListLoading ? (
          <div style={{ height: '128px', width: '100%', display: 'flex', alignItems: 'center' }}>
            <Spin style={{ width: '100%' }} spinning tip="Loading Available User Changes" />
          </div>
        ) : (
          <>
            <Card
              key="Unchanged WSI Results"
              type="inner"
              title="Unchanged WSI Results"
              onClick={isCreatingUserChangesLoading ? undefined : () => handleLoadUserChanges('none')}
              hoverable={!isCreatingUserChangesLoading}
            />
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
              <Title level={5} style={{ marginTop: '8px' }}>
                Available User Changesets
              </Title>
              <CreateNewChangesetModal />
            </div>
            <UserChangesList />
          </>
        )}
      </Card>
    </>
  );
}
