import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsAnnotationsVisible,
  selectIsHeatmapEnabled,
  selectJumpToLocation,
  selectSelectedAnnotationIds,
  setIsAnnotatingEnabled,
  setIsAnnotationsVisible,
  setJumpToLocation,
} from 'redux/slices/viewer';
import { ANNOTATION_LAYER_MIN_ZOOM, HEATMAP_LAYER_MAX_ZOOM, OL_LAYER_NAME } from 'utils/constants';
import ViewerContext from 'components/Viewer/ViewerContext';
import { useAppDispatch } from 'utils/hooks';
import VectorImageLayer from 'ol/layer/VectorImage';
import {
  clearActiveWSIResult,
  decrementChangesetPointer,
  incrementChangesetPointer,
  selectAnnotationOpacity,
  selectAnnotationReviewFilters,
  selectClassifications,
  selectDisplayDiffType,
  selectIgnoredCellTypes,
  setAnnotationOpacity,
  setUndoInteraction,
} from 'redux/slices/analysis';
import { useLocation } from 'react-router-dom';
import UndoRedo from 'ol-ext/interaction/UndoRedo';
import { generateOLAnnotationStyle } from 'utils/ol';
import { store } from 'redux/store';

const MIN_ZOOM_LEVEL = 4;

const AnnotationLayer = ({ source }: AnnotationLayerProps) => {
  const { map } = useContext(ViewerContext);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const annotationLayer = useRef<VectorImageLayer<VectorSource<Point>> | null>(null);
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);
  const jumpToLocation = useSelector(selectJumpToLocation);
  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);
  const classes = useSelector(selectClassifications);
  const displayDiffType = useSelector(selectDisplayDiffType);
  const annotationOpacity = useSelector(selectAnnotationOpacity);
  const ignoredCellTypes = useSelector(selectIgnoredCellTypes);
  const annotationReviewFilters = useSelector(selectAnnotationReviewFilters);
  const isHeatmapEnabled = useSelector(selectIsHeatmapEnabled);
  //This ref holds the opacity of the annotation layer if Heatmap is enabled/disabled while its opacity is changing
  const originalOpacity = useRef<number | null>(null);

  useEffect(() => {
    if (!annotationLayer.current) return;
    annotationLayer.current?.setOpacity(annotationOpacity);
    annotationLayer.current?.changed();
  }, [annotationOpacity]);

  useEffect(() => {
    if (annotationLayer.current) {
      annotationLayer.current?.setVisible(isAnnotationsVisible);
    }
  }, [isAnnotationsVisible]);

  useEffect(() => {
    if (isHeatmapEnabled) {
      dispatch(setIsAnnotationsVisible(true));
      if (originalOpacity.current) {
        annotationLayer.current?.setOpacity(originalOpacity.current);
      }
      annotationLayer.current?.setMinZoom(MIN_ZOOM_LEVEL);
    } else {
      originalOpacity.current = annotationOpacity;
      dispatch(setIsAnnotationsVisible(true));
      dispatch(setAnnotationOpacity(1));
      annotationLayer.current?.setVisible(true);
      annotationLayer.current?.setMinZoom(1);
    }

    annotationLayer.current?.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isHeatmapEnabled]);

  useEffect(() => {
    if (!annotationLayer.current) return;

    if (displayDiffType === 'none') {
      annotationLayer.current?.setVisible(true);
    }

    if (displayDiffType === 'diff') {
      annotationLayer.current?.setVisible(true);
    }

    if (displayDiffType === 'wsi_results_only') {
      annotationLayer.current?.setVisible(false);
    }
  }, [displayDiffType]);

  useEffect(() => {
    dispatch(clearActiveWSIResult());
    dispatch(setIsAnnotatingEnabled(false));
    map?.getView().setZoom(0);
  }, [map, dispatch, location.pathname]);

  useEffect(() => {
    if (!map || !classes || classes.length === 0) return;

    const annotationLayerStyles = generateOLAnnotationStyle(classes);

    const filterOutReviewedCells = !annotationReviewFilters.includes('show_reviewed');
    const filterOutUnReviewedCells = !annotationReviewFilters.includes('show_unreviewed');

    annotationLayer.current = new VectorImageLayer({
      properties: {
        name: OL_LAYER_NAME.ANNOTATIONS,
      },
      className: OL_LAYER_NAME.ANNOTATIONS,
      source,
      zIndex: 1,
      minZoom: MIN_ZOOM_LEVEL,
      style: feature => {
        if (feature.get('is_reviewed') && filterOutReviewedCells) return undefined;
        if (!feature.get('is_reviewed') && filterOutUnReviewedCells) return undefined;

        if (ignoredCellTypes.includes(feature.get('type'))) {
          //Because we use cells directly from the source, we 'hide' the filtered cells rather than removing them
          return undefined;
        } else {
          const styleKey = `${feature.get('type')}_${feature.get('is_selected')}_${feature.get('is_reviewed')}`;
          return annotationLayerStyles[styleKey];
        }
      },
    });

    map.addLayer(annotationLayer.current);

    map.getView().on('change:resolution', event => {
      if (!annotationLayer.current) return;

      const state = store.getState();
      const isHeatmapEnabled = selectIsHeatmapEnabled(state);
      if (!isHeatmapEnabled) {
        return;
      }

      // 'Fade in' Layer as user zooms in
      const newZoom = event.target.getZoom();
      if (newZoom < HEATMAP_LAYER_MAX_ZOOM && newZoom > ANNOTATION_LAYER_MIN_ZOOM) {
        const newOpacity = 1 - (HEATMAP_LAYER_MAX_ZOOM - newZoom);
        annotationLayer.current.setOpacity(newOpacity);
      }
    });

    const undoInteraction = new UndoRedo({ layers: [annotationLayer.current] });
    dispatch(setUndoInteraction(undoInteraction));

    map.addInteraction(undoInteraction);

    undoInteraction.define(
      'ADD',
      function (s: any) {
        const existingFeature = source.getFeatureById(s.before.getId());
        source.removeFeature(existingFeature);
        dispatch(decrementChangesetPointer());
      },
      function (s: any) {
        source.addFeature(s.after);
        dispatch(incrementChangesetPointer());
      },
    );

    undoInteraction.define(
      'DELETE',
      function (s: any) {
        source.addFeature(s.before);
        dispatch(decrementChangesetPointer());
      },
      function (s: any) {
        const existingFeature = source.getFeatureById(s.after.getId());
        source.removeFeature(existingFeature);
        dispatch(incrementChangesetPointer());
      },
    );

    undoInteraction.define(
      'CHANGE_POSITION',
      function (s: any) {
        const existingFeature = source.getFeatureById(s.before.id);
        existingFeature.setGeometry(s.before.geometry);
        dispatch(decrementChangesetPointer());
      },
      function (s: any) {
        const existingFeature = source.getFeatureById(s.after.id);
        existingFeature.setGeometry(s.after.geometry);
        dispatch(incrementChangesetPointer());
      },
    );

    undoInteraction.define(
      'CHANGE_REVIEW_STATUS',
      function (s: any) {
        const existingFeature = source.getFeatureById(s.before.id);
        existingFeature.set('is_reviewed', s.before.isReviewed);
        dispatch(decrementChangesetPointer());
      },
      function (s: any) {
        const existingFeature = source.getFeatureById(s.after.id);
        existingFeature.set('is_reviewed', s.after.isReviewed);
        dispatch(incrementChangesetPointer());
      },
    );

    undoInteraction.define(
      'CHANGE_TYPE',
      function (s: any) {
        const existingFeature = source.getFeatureById(s.before.id);
        existingFeature.set('type', s.before.type);
        dispatch(decrementChangesetPointer());
      },
      function (s: any) {
        const existingFeature = source.getFeatureById(s.after.id);
        existingFeature.set('type', s.after.type);
        dispatch(incrementChangesetPointer());
      },
    );

    return () => {
      if (map && annotationLayer.current) {
        map.removeLayer(annotationLayer.current);
      }
    };
  }, [dispatch, map, source, classes, ignoredCellTypes, annotationReviewFilters]);

  useEffect(() => {
    const selectedFeatures = source.getFeatures().filter((a: Feature) => a.get('is_selected'));
    selectedFeatures.forEach((selectedFeature: Feature) => {
      if (!selectedAnnotationIds.includes(selectedFeature.getId() as string)) {
        selectedFeature.set('is_selected', 'false');
      }
    });

    selectedAnnotationIds.forEach(selectedId => {
      const sourceFeature = source.getFeatureById(selectedId);
      if (sourceFeature) {
        sourceFeature.set('is_selected', 'true');
      }
    });
  }, [source, selectedAnnotationIds]);

  useEffect(() => {
    if (!map || !jumpToLocation) return;
    const feature = source.getFeatureById(jumpToLocation);
    if (!feature) {
      dispatch(setJumpToLocation(null));
      return;
    }
    const geometry = feature.getGeometry();
    if (!geometry) return;
    let extent = geometry.getExtent();
    let keepZoom = map.getView().getZoom();
    map.getView().fit(extent, {
      maxZoom: keepZoom,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, jumpToLocation]);

  return null;
};

export default AnnotationLayer;
