import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Col, Row, Typography } from 'antd';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { selectAllImages, selectIsCasesLoading } from '../../redux/slices/assignments';
import { ImageSearchWSIThumbnail } from './ImageSearchWSIThumbnail';

const { Text } = Typography;

export default function ImageSearchAutoComplete() {
  const navigate = useNavigate();
  const images = useSelector(selectAllImages);
  const isCasesLoading = useSelector(selectIsCasesLoading);

  const [allowDisplayOptions, setAllowDisplayOptions] = useState(false);

  const options = allowDisplayOptions
    ? images.map((image, index) => {
        return {
          value: `userImage.id-${index}`,
          label: (
            <Row>
              <Col flex="auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                {image.name}
              </Col>
              <ImageSearchWSIThumbnail image={image} />
            </Row>
          ),
          search: image.name,
          data: image,
        };
      })
    : [];

  const onSearchItemSelect = (
    itemId: string,
    itemInfo: { value: string; label: ReactNode; search: string; data: any },
  ) => {
    const image = itemInfo.data;
    navigate(`case/${image.case_id}/image/${image.id}`);
  };

  return (
    <div id="autocomplete-container">
      <Text style={{ paddingRight: '8px' }}>
        <SearchOutlined style={{ marginRight: '8px' }} />
        Search:
      </Text>
      <AutoComplete
        popupMatchSelectWidth={448}
        style={{ width: 250 }}
        options={options}
        filterOption={(inputValue, option) => {
          if (!inputValue) return false;
          return option!.search.toLowerCase().includes(inputValue.toLowerCase().trim());
        }}
        onSelect={onSearchItemSelect}
        onSearch={value => {
          setAllowDisplayOptions(!!value);
        }}
        allowClear={true}
        notFoundContent={isCasesLoading ? 'Cases Loading...' : 'Nothing found matching query'}
      />
    </div>
  );
}
