import { Divider, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectCellCounts, selectScenarioInfo, selectViewLayouts } from 'redux/slices/analysis';

const { Title, Text } = Typography;

export default function Scores() {
  const viewLayouts = useSelector(selectViewLayouts);
  const scenarioInfo = useSelector(selectScenarioInfo);
  const cellCounts = useSelector(selectCellCounts);

  if (!scenarioInfo) return null;
  if (!viewLayouts || Object.keys(viewLayouts).length === 0) return null;

  const renderLayoutValueString = (value: number, displayType: string, numDecimalPlaces = 2) => {
    switch (displayType) {
      case 'percentage':
        return `${(value * 100).toFixed(numDecimalPlaces)} %`;
      default:
        try {
          return value.toFixed(numDecimalPlaces).toString();
        } catch (e) {
          return `${value}`;
        }
    }
  };

  const convertSizeStringToLevelInt = (displaySize: string) => {
    switch (displaySize) {
      case 'small':
        return 5;
      case 'medium':
        return 3;
      case 'large':
        return 1;
      default:
        return 3;
    }
  };

  const renderViewLayout = (layoutName: string) => {
    const viewLayout = viewLayouts[layoutName];
    if (!viewLayout || viewLayout.value === null || !cellCounts) return null;
    return (
      <div style={{ marginBottom: '8px', textAlign: 'center' }} key={layoutName}>
        <Title
          id={layoutName}
          style={{ marginTop: 0, marginBottom: 0 }}
          level={convertSizeStringToLevelInt(viewLayout['display_size'])}
        >
          {renderLayoutValueString(viewLayout.value as number, viewLayout.display_type, viewLayout.num_decimal_places)}
        </Title>
        <Text strong={viewLayout['display_size'] === 'large'}>{viewLayout.label}</Text>
      </div>
    );
  };

  const renderScenarioLayouts = () => {
    if (!scenarioInfo) return null;
    return scenarioInfo['layouts_order'].map(layoutName => {
      if (layoutName in viewLayouts) return renderViewLayout(layoutName);
      return null;
    });
  };

  return (
    <>
      <Divider style={{ marginBottom: '16px' }} />
      <div style={{ margin: '8px' }}>
        <Typography.Text strong>Scores</Typography.Text>
        {renderScenarioLayouts()}
      </div>
    </>
  );
}
