import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { useSelector } from 'react-redux';
import { redoAction, selectChangeset, selectChangesetPointer, undoAction } from 'redux/slices/analysis';
import { selectIsAnnotatingEnabled } from 'redux/slices/viewer';
import { useAppDispatch } from 'utils/hooks';

export default function UndoRedo() {
  const dispatch = useAppDispatch();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const changeset = useSelector(selectChangeset);
  const changesetPointer = useSelector(selectChangesetPointer);

  const isUndoEnabled = isAnnotatingEnabled && changeset.length > 0 && changesetPointer !== 0;
  const isRedoEnabled = isAnnotatingEnabled && changesetPointer !== null && changesetPointer !== changeset.length;

  return (
    <Radio.Group className="changeset-undo-redo">
      <Radio.Button
        id="undo-button"
        value="undo"
        onClick={() => {
          dispatch(undoAction());
        }}
        disabled={!isUndoEnabled}
      >
        <UndoOutlined />
      </Radio.Button>
      <Radio.Button
        id="redo-button"
        value="redo"
        onClick={() => {
          dispatch(redoAction());
        }}
        disabled={!isRedoEnabled}
      >
        <RedoOutlined />
      </Radio.Button>
    </Radio.Group>
  );
}
