import { useDispatch, useSelector } from 'react-redux';
import { selectIgnoredCellTypes, updateIgnoredCellType } from 'redux/slices/analysis';
import './style.css';
import { selectActiveAnnotationClassification, setActiveAnnotationClassification } from 'redux/slices/viewer';

export default function StatisticWithDotAnnotation({ title, value, color, cellType }: StatisticWithDotAnnotationProps) {
  const dispatch = useDispatch();
  const ignoredCellTypes = useSelector(selectIgnoredCellTypes);
  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);

  const handleStatisticOnClick = () => {
    if (!ignoredCellTypes.includes(cellType)) {
      if (activeAnnotationClassification === cellType) {
        dispatch(setActiveAnnotationClassification(''));
      }
    }
    dispatch(updateIgnoredCellType(cellType));
  };

  const disabled = ignoredCellTypes.includes(cellType);

  return (
    <div
      className={`ant-statistic custom-statistic-small custom-statistic-hand-cursor ${
        disabled ? 'custom-statistic-disabled' : ''
      }`}
      onClick={handleStatisticOnClick}
    >
      <div className="ant-statistic-title">
        <div className="icon-dot-annotation-statistic baseline">
          <svg id="svg-1" height="12" width="12">
            <circle cx="6" cy="6" r="4" stroke="black" strokeWidth="1" fill={color} />
          </svg>
        </div>
        {title}
      </div>
      <div className="ant-statistic-content">
        <span className="ant-statistic-content-value-int" data-test="cell-count-value">
          {value}
        </span>
      </div>
    </div>
  );
}
