import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { INTERACTION } from 'utils/constants';

export interface UserInterface {
  isTileLoadProgressBarActive: boolean;
  tileLoadProgressLoading: number;
  tileLoadProgressLoaded: number;
  isAnnotationSidebarCollapsed: boolean;
  isImageSidebarCollapsed: boolean;
  activeInteractions: Array<string>;
  openModal: 'create-new-changeset-modal' | 're-auth-modal' | null;
  shouldShowHeatmapLegend: boolean;
}

export const initialState = {
  isTileLoadProgressBarActive: false,
  tileLoadProgressLoading: 0,
  tileLoadProgressLoaded: 0,
  isAnnotationSidebarCollapsed: false,
  isImageSidebarCollapsed: false,
  activeInteractions: [INTERACTION.ANNOTATE],
  openModal: null,
  shouldShowHeatmapLegend: false,
} as UserInterface;

export const userInterface = createSlice({
  name: 'userInterface',
  initialState,
  reducers: {
    setIsTileLoadProgressBarActive: (state, action) => {
      state.isTileLoadProgressBarActive = action.payload;

      if (!action.payload) {
        state.tileLoadProgressLoading = 0;
        state.tileLoadProgressLoaded = 0;
      }
    },
    incrementTileLoadProgressLoading: state => {
      state.tileLoadProgressLoading += 1;
    },
    incrementTileLoadProgressLoaded: state => {
      state.tileLoadProgressLoaded += 1;
    },
    setIsAnnotationSidebarCollapsed: (state, action) => {
      state.isAnnotationSidebarCollapsed = action.payload;
    },
    setIsImageSidebarCollapsed: (state, action) => {
      state.isImageSidebarCollapsed = action.payload;
    },
    setActiveInteractions: (state, action) => {
      state.activeInteractions = action.payload;
    },
    removeFromActiveInteractions: (state, action) => {
      state.activeInteractions = state.activeInteractions.filter(interaction => interaction !== action.payload);
    },
    setOpenModal: (state, action: PayloadAction<UserInterface['openModal']>) => {
      state.openModal = action.payload;
    },
    closeOpenModal: state => {
      state.openModal = null;
    },
    setShouldShowHeatmapLegend: (state, action) => {
      state.shouldShowHeatmapLegend = action.payload;
    },
  },
});

export const {
  setIsTileLoadProgressBarActive,
  incrementTileLoadProgressLoading,
  incrementTileLoadProgressLoaded,
  setIsAnnotationSidebarCollapsed,
  setIsImageSidebarCollapsed,
  setActiveInteractions,
  removeFromActiveInteractions,
  setOpenModal,
  setShouldShowHeatmapLegend,
} = userInterface.actions;

export const selectIsTileLoadProgressBarActive = (state: RootState) => state.userInterface.isTileLoadProgressBarActive;
export const selectTileLoadProgressLoading = (state: RootState) => state.userInterface.tileLoadProgressLoading;
export const selectTileLoadProgressLoaded = (state: RootState) => state.userInterface.tileLoadProgressLoaded;

export const selectIsAnnotationSidebarCollapsed = (state: RootState) =>
  state.userInterface.isAnnotationSidebarCollapsed;
export const selectIsImageSidebarCollapsed = (state: RootState) => state.userInterface.isImageSidebarCollapsed;

export const selectIsROIToolActive = (state: RootState) =>
  state.userInterface.activeInteractions.includes(INTERACTION.DRAW_ROI);
export const selectIsAnnotatingActive = (state: RootState) =>
  state.userInterface.activeInteractions.includes(INTERACTION.ANNOTATE) ||
  state.userInterface.activeInteractions.length === 0; //Annotate should be default enabled interaction
export const selectIsEraserToolActive = (state: RootState) =>
  state.userInterface.activeInteractions.includes(INTERACTION.ERASER_TOOL);
export const selectOpenModal = (state: RootState) => state.userInterface.openModal;
export const selectShouldShowHeatmapLegend = (state: RootState) => state.userInterface.shouldShowHeatmapLegend;

export default userInterface.reducer;
