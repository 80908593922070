import { useDispatch, useSelector } from 'react-redux';
import { Button, Tag, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { selectIsAnnotatingEnabled, selectIsAnnotationsVisible, setIsAnnotationsVisible } from 'redux/slices/viewer';
import { useEffect } from 'react';
import { selectOpenModal } from 'redux/slices/userInterface';

export default function AnnotationsVisibilitySwitch() {
  const dispatch = useDispatch();
  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const openModal = useSelector(selectOpenModal);

  const handleShowHideAnnotations = () => {
    const AnnotationVisibilityButton = document.getElementById('toggle-annotation-visibility-button');
    if (AnnotationVisibilityButton) {
      AnnotationVisibilityButton.blur();
    }
    dispatch(setIsAnnotationsVisible(!isAnnotationsVisible));
  };

  const __handleKeyDown = (event: KeyboardEvent) => {
    if (openModal) return;
    event.preventDefault();
    if (event.key === 'a') {
      dispatch(setIsAnnotationsVisible(!isAnnotationsVisible));
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', __handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotationsVisible]);

  return (
    <Tooltip
      title={
        <span>
          Show/Hide Annotations
          <Tag style={{ marginLeft: 4, marginRight: 0, color: 'white' }}>a</Tag>
        </span>
      }
    >
      <Button
        id="toggle-annotation-visibility-button"
        shape="circle"
        onClick={handleShowHideAnnotations}
        icon={isAnnotationsVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        disabled={!isAnnotatingEnabled}
      />
    </Tooltip>
  );
}
