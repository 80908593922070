import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import userInterfaceReducer from 'redux/slices/userInterface';
import imageInfoReducer from 'redux/slices/imageInfo';
import assignmentsReducer from 'redux/slices/assignments';
import analysisReducer from '../slices/analysis';
import viewerReducer from '../slices/viewer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const reducer = {
  userInterface: userInterfaceReducer,
  imageInfo: imageInfoReducer,
  assignments: assignmentsReducer,
  analysis: analysisReducer,
  viewer: viewerReducer,
};

export const store = configureStore({
  reducer,
  enhancers: [sentryReduxEnhancer],
  middleware: getDefaultMiddleware => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
