import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Card, Empty, Layout, Row, Tooltip, Typography } from 'antd';
import { ImageCard } from 'components/ImageCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveCase } from 'redux/slices/assignments';
import { selectIsImageSidebarCollapsed, setIsImageSidebarCollapsed } from 'redux/slices/userInterface';
import './style.css';

const { Sider } = Layout;
const { Title } = Typography;

export default function CaseImagesSidebar() {
  const dispatch = useDispatch();
  const isImageSidebarCollapsed = useSelector(selectIsImageSidebarCollapsed);

  const activeCase = useSelector(selectActiveCase);

  const handleOnCollapse = (collapsed: boolean) => {
    dispatch(setIsImageSidebarCollapsed(collapsed));
  };

  return (
    <Sider
      collapsible
      width={360}
      collapsed={isImageSidebarCollapsed}
      onCollapse={handleOnCollapse}
      collapsedWidth={40}
      theme="light"
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        borderTop: '1px solid #f0f0f0',
        borderLeft: '1px solid #f0f0f0',
        marginBottom: '48px',
      }}
      trigger={
        <Tooltip
          title={isImageSidebarCollapsed ? 'Click here to expand the Sidebar' : 'Click here to hide the Sidebar'}
          placement="left"
        >
          <div id="minimise-sidebar-button" style={{ width: '100%' }}>
            {isImageSidebarCollapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </div>
        </Tooltip>
      }
    >
      {!isImageSidebarCollapsed && (
        <Card
          id="case-images-sidebar-card"
          title={
            <Title level={4} style={{ margin: 0 }}>
              Case Images:
            </Title>
          }
          style={{ borderRadius: 0 }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {activeCase && activeCase.images.length > 0 ? (
              <Row gutter={[16, 24]} style={{ paddingBottom: 0, marginLeft: 0, marginRight: 0, width: '100%' }}>
                {activeCase.images.map((image: Image) => (
                  <ImageCard key={image.id} image={image} />
                ))}
              </Row>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>This Case does not contain any Images</span>}
                />
              </div>
            )}
          </div>
        </Card>
      )}
    </Sider>
  );
}
