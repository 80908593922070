import concaveman from 'concaveman';
import * as turf from '@turf/helpers';

import { MultiPolygon } from 'geojson';

export const parseJwt = (token: string | null) => {
  if (!token) return null;
  try {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(
      // eslint-disable-next-line no-undef
      atob(base64Url)
        .split('')
        // eslint-disable-next-line prefer-template
        .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join(''),
    );
    return JSON.parse(base64);
  } catch (error) {
    return null;
  }
};

export const getRoundedSinglePixel = (dotCoordinate: number[]) => {
  return { x: Math.round(dotCoordinate[0]), y: Math.round(dotCoordinate[1]) };
};

export const simplifyMultiPolygon = (multiPolygonGeometry: MultiPolygon) => {
  const polygonCoordinates = multiPolygonGeometry.coordinates.reduce((allCoords, coords) => {
    return [...allCoords, ...coords[0]];
  }, [] as number[][]);
  return turf.polygon([concaveman(polygonCoordinates)]);
};

export function sliceChangesetIntoChunks(arr: CellChangeAdd[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
