import { RotateRightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Map } from 'ol';
import { Control } from 'ol/control';
import { createRoot } from 'react-dom/client';
import './style.css';

interface CustomRotationClockwiseProps {
  map: Map;
}

export default function CustomRotationClockwise({ map }: CustomRotationClockwiseProps) {
  const button = document.createElement('button');

  const arrowSpan = document.createElement('span');
  const root = createRoot(arrowSpan);
  root.render(
    <Tooltip title="Rotate 90° Clockwise" placement="right">
      <RotateRightOutlined />
    </Tooltip>,
  );
  button.appendChild(arrowSpan);

  const element = document.createElement('div');
  element.className = 'custom-rotation-clockwise ol-unselectable ol-control';
  element.style.top = '36px';
  element.appendChild(button);

  button.addEventListener('click', () => {
    const rotation_in_radians = map.getView().getRotation() + (90 * Math.PI) / 180;
    map.getView().setRotation(rotation_in_radians);
  });

  return new Control({ element: element });
}
