import { ClearOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { Control } from 'ol/control';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { selectIsAnnotatingEnabled } from 'redux/slices/viewer';
import {
  removeFromActiveInteractions,
  selectIsEraserToolActive,
  setActiveInteractions,
} from 'redux/slices/userInterface';
import ViewerContext from 'components/Viewer/ViewerContext';
import { INTERACTION } from 'utils/constants';
import { RootState } from 'redux/store';
import './style.css';

export default function CustomEraserTool() {
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const { map } = useContext(ViewerContext);
  const isEraserToolActive = useSelector(selectIsEraserToolActive);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);

  const customEraserTool = useRef<Control | null>(null);

  const createCustomEraserTool = () => {
    const button = document.createElement('button');
    button.id = 'custom-eraser-button';

    const customEraserSpan = document.createElement('span');
    const root = createRoot(customEraserSpan);
    root.render(
      <Tooltip
        title={
          <span>
            Eraser Tool
            <Tag style={{ marginLeft: '8px', marginRight: '0px' }}>e</Tag>
          </span>
        }
        placement="right"
      >
        <ClearOutlined />
      </Tooltip>,
    );
    button.appendChild(customEraserSpan);

    const element = document.createElement('div');
    element.className = 'custom-eraser-tool ol-unselectable ol-control';
    element.style.top = '94px';
    element.appendChild(button);

    button.addEventListener('click', () => {
      const state = store.getState();
      const isEraserToolActive = selectIsEraserToolActive(state);
      if (isEraserToolActive) {
        dispatch(removeFromActiveInteractions(INTERACTION.ERASER_TOOL));
      } else {
        dispatch(setActiveInteractions([INTERACTION.ERASER_TOOL]));
      }
    });
    return new Control({ element: element });
  };

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === 'e') {
      const state = store.getState();
      const isEraserToolActive = selectIsEraserToolActive(state);
      if (isEraserToolActive) {
        dispatch(removeFromActiveInteractions(INTERACTION.ERASER_TOOL));
      } else {
        dispatch(setActiveInteractions([INTERACTION.ERASER_TOOL]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map || !customEraserTool.current) return;
    if (isAnnotatingEnabled) {
      map.addControl(customEraserTool.current);
      document.addEventListener('keydown', __handleKeyDown);
    } else {
      map.removeControl(customEraserTool.current);
      //reset custom eraser tool so when control is re-added it is a 'new' instance
      customEraserTool.current = createCustomEraserTool();
      document.removeEventListener('keydown', __handleKeyDown);
      dispatch(removeFromActiveInteractions(INTERACTION.ERASER_TOOL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isAnnotatingEnabled]);

  useEffect(() => {
    if (isEraserToolActive) {
      const button = document.getElementById('custom-eraser-button');
      if (button) {
        button.id = 'custom-eraser-button-focus';
      }
    } else {
      const button = document.getElementById('custom-eraser-button-focus');
      if (button) {
        button.id = 'custom-eraser-button';
      }
    }
  }, [isEraserToolActive]);

  return null;
}
