import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useAuth } from 'components/auth/AuthProvider';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchCreateNewUserChanges,
  selectIsCreatingUserChangesLoading,
  selectUserChangesList,
} from 'redux/slices/analysis';
import { useAppDispatch } from 'utils/hooks';
import { selectOpenModal, setOpenModal } from 'redux/slices/userInterface';

const { Option } = Select;

export default function CreateNewChangesetModal() {
  const dispatch = useAppDispatch();
  const { fineTuningWSIResultId } = useParams();
  const { accessToken, user } = useAuth();
  const isCreatingUserChangesLoading = useSelector(selectIsCreatingUserChangesLoading);
  const userChangesList = useSelector(selectUserChangesList);
  const openModal = useSelector(selectOpenModal);

  const [form] = Form.useForm();

  const createNewBlankUserChanges = (values: any) => {
    if (!fineTuningWSIResultId || !accessToken) return;

    dispatch(
      fetchCreateNewUserChanges({
        fineTuningWSIResultId,
        accessToken,
        name: values.name,
        userChangesId: values.existingChangesetId,
      }),
    ).then(() => {
      dispatch(setOpenModal(null));
    });
  };

  const handleOnCancel = () => {
    dispatch(setOpenModal(null));
    form.resetFields();
  };

  return (
    <>
      <Modal
        open={!!openModal}
        title="Create a new Changeset"
        okText="Create"
        cancelText="Cancel"
        onCancel={handleOnCancel}
        onOk={() => {
          form.validateFields().then(values => {
            form.resetFields();
            createNewBlankUserChanges(values);
          });
        }}
        confirmLoading={isCreatingUserChangesLoading}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ name: `Changeset from ${user?.username}`, existingChangesetId: null }}
        >
          <Form.Item
            name="name"
            label="Changeset Name"
            rules={[{ required: true, message: 'The new Changeset must have a name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="existingChangesetId" label="Copy from Existing Changeset">
            <Select placeholder="none" disabled={userChangesList.length === 0}>
              {userChangesList.length > 0 ? (
                userChangesList.map(userChanges => (
                  <Option key={userChanges.id} value={userChanges.id}>
                    {userChanges.name}
                  </Option>
                ))
              ) : (
                <Option key="no-changesets-available" value={undefined}>
                  No Changesets Available
                </Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type="primary"
        onClick={() => {
          dispatch(setOpenModal('create-new-changeset-modal'));
        }}
        loading={isCreatingUserChangesLoading}
        icon={<PlusOutlined />}
      >
        Create
      </Button>
    </>
  );
}
