import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface ViewerSlice {
  jumpToLocation: string | null;
  scrollToAnnotationId: string | null;
  selectedAnnotationIds: Array<string>;
  isAnnotatingPrevented: boolean;
  isAnnotatingEnabled: boolean;
  isAnnotationsVisible: boolean;
  activeAnnotationClassification: string | null;
  isHeatmapEnabled: boolean;
}

export const initialState = {
  jumpToLocation: null,
  scrollToAnnotationId: null,
  selectedAnnotationIds: [],
  isAnnotatingPrevented: true,
  isAnnotatingEnabled: false,
  isAnnotationsVisible: true,
  activeAnnotationClassification: null,
  isHeatmapEnabled: true,
} as ViewerSlice;

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setIsAnnotatingEnabled: (state, action) => {
      state.isAnnotatingEnabled = action.payload;
    },
    setIsAnnotatingPrevented: (state, action) => {
      state.isAnnotatingPrevented = action.payload;
    },
    setJumpToLocation: (state, action) => {
      state.jumpToLocation = action.payload;
    },
    setScrollToAnnotationId: (state, action) => {
      state.scrollToAnnotationId = action.payload;
    },
    setSelectedAnnotationIds: (state, action) => {
      state.selectedAnnotationIds = action.payload;
    },
    appendToSelectedAnnotationIds: (state, action: PayloadAction<Array<string>>) => {
      action.payload.forEach(annotationId => {
        if (!state.selectedAnnotationIds.includes(annotationId)) {
          state.selectedAnnotationIds.push(annotationId);
        }
      });
    },
    removeFromSelectedAnnotationIds: (state, action) => {
      state.selectedAnnotationIds = state.selectedAnnotationIds.filter(annotationId => annotationId !== action.payload);
    },
    setIsAnnotationsVisible: (state, action) => {
      state.isAnnotationsVisible = action.payload;
    },
    setActiveAnnotationClassification: (state, action) => {
      state.activeAnnotationClassification = action.payload;
    },
    setIsHeatmapEnabled: (state, action) => {
      state.isHeatmapEnabled = action.payload;
    },
  },
});

export const {
  setIsAnnotatingPrevented,
  setIsAnnotatingEnabled,
  setJumpToLocation,
  setScrollToAnnotationId,
  setSelectedAnnotationIds,
  appendToSelectedAnnotationIds,
  removeFromSelectedAnnotationIds,
  setIsAnnotationsVisible,
  setActiveAnnotationClassification,
  setIsHeatmapEnabled,
} = viewerSlice.actions;

export const selectJumpToLocation = (state: RootState) => state.viewer.jumpToLocation;
export const selectSelectedAnnotationIds = (state: RootState) => state.viewer.selectedAnnotationIds;
export const selectScrollToAnnotationId = (state: RootState) => state.viewer.scrollToAnnotationId;
export const selectIsAnnotatingPrevented = (state: RootState) => state.viewer.isAnnotatingPrevented;
export const selectIsAnnotatingEnabled = (state: RootState) => state.viewer.isAnnotatingEnabled;
export const selectIsAnnotationsVisible = (state: RootState) => state.viewer.isAnnotationsVisible;
export const selectActiveAnnotationClassification = (state: RootState) => state.viewer.activeAnnotationClassification;
export const selectIsHeatmapEnabled = (state: RootState) => state.viewer.isHeatmapEnabled;

export default viewerSlice.reducer;
