import { MapBrowserEvent } from 'ol';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Circle, LineString } from 'ol/geom';
import Point from 'ol/geom/Point';
import Fill from 'ol/style/Fill';
import RegularShape from 'ol/style/RegularShape';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useContext, useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { applyChangesetAction } from 'redux/slices/analysis';
import ViewerContext from 'components/Viewer/ViewerContext';
import { selectIsEraserToolActive } from 'redux/slices/userInterface';
import { setSelectedAnnotationIds } from 'redux/slices/viewer';
import { RootState } from 'redux/store';
import { useAppDispatch } from 'utils/hooks';

const ERASER_RADIUS = 40;

const isPointInsideCircle = (checkCoordinate: number[], centerCoordinate: number[], radius: number) => {
  const xDiff = checkCoordinate[0] - centerCoordinate[0];
  const yDiff = checkCoordinate[1] - centerCoordinate[1];
  return xDiff * xDiff + yDiff * yDiff <= radius * radius;
};

const EraserToolInteraction = ({ eraserToolSource, cellSource }: EraserToolInteractionProps) => {
  const store = useStore<RootState>();
  const { map } = useContext(ViewerContext);
  const dispatch = useAppDispatch();
  const isEraserToolActive = useSelector(selectIsEraserToolActive);

  const isErasing = useRef(false);
  const shouldErase = useRef(true);
  const radiusRef = useRef(0);

  // Convert radius in browser pixels to equivalent in OpenLayers coordinates
  const getRadius = () => {
    if (!map) return 0;
    const mapCenterPixel = map.getPixelFromCoordinate(map.getView().getCenter() as Coordinate);
    const radiusCoordinateOne = map.getCoordinateFromPixel(mapCenterPixel);
    const radiusCoordinateTwo = map.getCoordinateFromPixel([mapCenterPixel[0] + ERASER_RADIUS, mapCenterPixel[1]]);
    const distanceString = new LineString([radiusCoordinateOne, radiusCoordinateTwo]);
    return distanceString.getLength();
  };

  const eraseAction = (event: MapBrowserEvent<PointerEvent>) => {
    const eraserToolCircleGeometry = new Circle(event.coordinate, radiusRef.current, 'XY');
    const extent = eraserToolCircleGeometry.getExtent();
    let changesetAddition: Array<CellChange> = [];
    cellSource.forEachFeatureInExtent(extent, (feature: Feature) => {
      const featureId = feature.getId() as string;
      if (!featureId) return;

      const geometry = feature.getGeometry() as Point;
      const featureCoords = geometry.getCoordinates();

      if (isPointInsideCircle(featureCoords, event.coordinate, radiusRef.current)) {
        changesetAddition.push({
          type: 'DELETE',
          id: featureId,
          payload: {},
        });
      }
    });
    dispatch(applyChangesetAction(changesetAddition));
    dispatch(setSelectedAnnotationIds([]));
  };

  const __pointerMoveEvent = (event: MapBrowserEvent<PointerEvent>) => {
    event.preventDefault();
    const state = store.getState();
    const isEraserToolActive = selectIsEraserToolActive(state);
    if (!isEraserToolActive) return;
    const eraserToolMask = eraserToolSource.getFeatureById('eraser-tool-mask');
    if (eraserToolMask) {
      eraserToolMask.setGeometry(new Point(event.coordinate));
      if (isErasing.current) {
        eraseAction(event);
      }
    } else {
      const newEraserToolFeature = new Feature(new Point(event.coordinate));
      const eraserToolCircleGeometry = new Style({
        image: new RegularShape({
          stroke: new Stroke({
            color: '#6300cc',
            width: 1,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.0)',
          }),
          radius: ERASER_RADIUS,
          points: 64,
        }),
      });
      newEraserToolFeature.setStyle(eraserToolCircleGeometry);
      newEraserToolFeature.setId('eraser-tool-mask');
      eraserToolSource.addFeature(newEraserToolFeature);
    }
  };

  const __pointerDownEvent = (event: MapBrowserEvent<PointerEvent>) => {
    //This check prevent pointerdown being called for left click
    if (event.originalEvent.button !== 2) return;
    if (isEraserToolActive) {
      isErasing.current = true;
      radiusRef.current = getRadius();
      const eraserToolCircleGeometry = new Circle(event.coordinate, radiusRef.current, 'XY');
      const features: Feature[] = cellSource.getFeaturesInExtent(eraserToolCircleGeometry.getExtent());
      if (features.length > 0) {
        shouldErase.current = features.some(feat => feat.get('is_erased') !== 'true');
      }
      eraseAction(event);
    }
  };

  const __pointerUpEvent = (event: MapBrowserEvent<PointerEvent>) => {
    //This check prevent pointerup being called for left click
    if (event.originalEvent.button !== 2) return;
    isErasing.current = false;
  };

  useEffect(() => {
    if (!map || !isEraserToolActive) return;

    map.getViewport().addEventListener(
      'mouseout',
      () => {
        eraserToolSource.clear();
      },
      false,
    );
    map.on('pointermove', __pointerMoveEvent);
    // using 'as any' due to 'No overload matches this call'
    map.on('pointerdown' as any, __pointerDownEvent);
    // using 'as any' due to 'No overload matches this call'
    map.on('pointerup' as any, __pointerUpEvent);

    return () => {
      if (map) {
        map.un('pointermove', __pointerMoveEvent);
        // using 'as any' due to 'No overload matches this call'
        map.un('pointerdown' as any, __pointerDownEvent);
        // using 'as any' due to 'No overload matches this call'
        map.on('pointerup' as any, __pointerUpEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEraserToolActive]);

  return null;
};

export default EraserToolInteraction;
