import { Feature, MapBrowserEvent } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Point, Polygon } from 'ol/geom';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  clearAnalysisError,
  fetchSelectionAIResult,
  selectAnalysisError,
  selectScenarioInfo,
  selectShouldCancelAIRequest,
  selectWSIResultData,
  setAnalysisError,
  setShouldCancelAIRequest,
} from 'redux/slices/analysis';
import { selectImageInfo } from 'redux/slices/imageInfo';
import { polygon as TurfPolygon, Position } from '@turf/helpers';
import ViewerContext from 'components/Viewer/ViewerContext';
import { selectActiveImage } from 'redux/slices/assignments';
import intersect from '@turf/intersect';
import { simplifyMultiPolygon } from 'utils/utils';
import { useAuth } from 'components/auth/AuthProvider';
import { selectIsAnnotatingEnabled } from 'redux/slices/viewer';
import { selectIsROIToolActive } from 'redux/slices/userInterface';
import { useAppDispatch } from '../../utils/hooks';

export default function ROISelectionInteractionProps({ source, cellSource }: ROISelectionInteractionProps) {
  const { accessToken, aiToken, user } = useAuth();
  const { map } = useContext(ViewerContext);
  const dispatch = useAppDispatch();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const activeImage = useSelector(selectActiveImage);
  const scenarioInfo = useSelector(selectScenarioInfo);
  const activeWSIResult = useSelector(selectWSIResultData);
  const analysisError = useSelector(selectAnalysisError);
  const imageInfo = useSelector(selectImageInfo);
  const shouldCancelAIRequest = useSelector(selectShouldCancelAIRequest);
  const isROIToolActive = useSelector(selectIsROIToolActive);

  const drawAnalysis = useRef<Draw | null>(null);
  const startDrawing = useRef(false);
  const promiseFetch = useRef<any | null>(null);

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (!drawAnalysis.current) return;
      drawAnalysis.current.abortDrawing();
    }
  }, []);

  const pointerUpEvent = () => {
    if (startDrawing.current) {
      if (!drawAnalysis.current) return;
      drawAnalysis.current.finishDrawing();
    }
  };

  const pointerDownEvent = (event: MapBrowserEvent<PointerEvent>) => {
    //This check prevents pointerdown being called for left click
    if (event.originalEvent.button !== 2) return;
    if (drawAnalysis.current && drawAnalysis.current.getActive()) {
      startDrawing.current = true;
    }
  };

  const handleDrawEnd = async (event: DrawEvent) => {
    startDrawing.current = false;

    if (!scenarioInfo || !imageInfo) return;

    if (!event) return;

    const geometry = event.feature.getGeometry() as Point;
    const coordinates = geometry.getCoordinates()[0] as unknown as Position[];

    const imageWidth = imageInfo.width;
    const imageHeight = -imageInfo.height;

    const imageOffset = scenarioInfo['image_offset'];

    const imagePolygon = TurfPolygon([
      [
        [imageOffset, -imageOffset],
        [imageWidth - imageOffset, -imageOffset],
        [imageWidth - imageOffset, imageHeight + imageOffset],
        [imageOffset, imageHeight + imageOffset],
        [imageOffset, -imageOffset],
      ],
    ]);

    try {
      let selectionPolygon = TurfPolygon([coordinates]);
      const intersectionPolygon = intersect(selectionPolygon, imagePolygon);
      if (intersectionPolygon) {
        if (intersectionPolygon.geometry.type === 'MultiPolygon') {
          selectionPolygon = simplifyMultiPolygon(intersectionPolygon.geometry);
          event.feature.setGeometry(new Polygon(selectionPolygon.geometry.coordinates));
        } else {
          event.feature.setGeometry(new Polygon(intersectionPolygon.geometry.coordinates as Coordinate[][]));
        }
      } else {
        event.feature.setGeometry(new Polygon(selectionPolygon.geometry.coordinates));
      }

      const eventFeaturePolygon = event.feature.getGeometry() as Polygon;
      const selectionPolygonCoordinates = eventFeaturePolygon
        .getCoordinates()[0]
        .map((coordinate: number[]) => [coordinate[0], -coordinate[1]]);

      if (!activeImage) return;

      promiseFetch.current = dispatch(
        fetchSelectionAIResult({
          accessToken,
          aiToken,
          username: user?.username,
          selectionPolygon: selectionPolygonCoordinates,
          roiFeature: event.feature as Feature<Polygon>,
          imageId: `${activeImage.name}${activeImage.file_type}`,
          aiLabId: activeWSIResult?.version.model.split('.')[1],
        }),
      ).then(() => {
        // Force source to visually update
        cellSource.changed();
      });
    } catch (e) {
      dispatch(setAnalysisError(new Error('Analysis area is too small. Please select a larger area.')));
    }
  };

  useEffect(() => {
    if (shouldCancelAIRequest && promiseFetch.current) {
      promiseFetch.current.abort();
      dispatch(setShouldCancelAIRequest(false));
      source.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCancelAIRequest]);

  useEffect(() => {
    if (!analysisError) return;
    source.clear();

    dispatch(clearAnalysisError());
  }, [source, analysisError, dispatch]);

  useEffect(() => {
    if (!map || !source || !imageInfo || !scenarioInfo || !isAnnotatingEnabled || !isROIToolActive) return;

    if (drawAnalysis.current) {
      map.removeInteraction(drawAnalysis.current);
    }

    drawAnalysis.current = new Draw({
      source: source,
      type: 'Polygon',
      condition: e => e.originalEvent.buttons === 2,
      freehandCondition: e => e.originalEvent.buttons === 2,
      style: new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({
            color: '#6300cc',
          }),
          stroke: new Stroke({
            color: '#000000',
            width: 1,
          }),
        }),
        stroke: new Stroke({
          color: '#6300cc',
          width: 3,
        }),
      }),
    });

    // using 'as any' due to 'No overload matches this call'
    map.on('pointerup' as any, pointerUpEvent);
    // using 'as any' due to 'No overload matches this call'
    map.on('pointerdown' as any, pointerDownEvent);

    drawAnalysis.current.on('drawend', handleDrawEnd);

    map.addInteraction(drawAnalysis.current);

    document.addEventListener('keydown', __handleKeyDown);

    return () => {
      if (map) {
        document.removeEventListener('keydown', __handleKeyDown);
        // using 'as any' due to 'No overload matches this call'
        map.un('pointerdown' as any, pointerDownEvent);
        // using 'as any' due to 'No overload matches this call'
        map.un('pointerup' as any, pointerUpEvent);
        if (drawAnalysis.current) {
          map.removeInteraction(drawAnalysis.current);
          drawAnalysis.current.un('drawend', handleDrawEnd);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, scenarioInfo, imageInfo, isAnnotatingEnabled, isROIToolActive]);

  useEffect(() => {
    if (!map) return;

    if (!isROIToolActive) {
      document.removeEventListener('keydown', __handleKeyDown);
      // using 'as any' due to 'No overload matches this call'
      map.un('pointerdown' as any, pointerDownEvent);
      // using 'as any' due to 'No overload matches this call'
      map.un('pointerup' as any, pointerUpEvent);
      if (drawAnalysis.current) {
        map.removeInteraction(drawAnalysis.current);
        drawAnalysis.current.un('drawend', handleDrawEnd);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isROIToolActive]);

  return null;
}
