import { SerializedError } from '@reduxjs/toolkit';
import { createContext, ReactNode, useContext, useState } from 'react';
import { backendUrl, getRequestWithAuthToken } from 'service/api';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import { parseJwt } from 'utils/utils';

interface AuthContextType {
  user: { username: string } | null;
  accessToken: string | null;
  aiToken: string | null;
  logIn: ({ username, password }: { username: string; password: string }, callback: VoidFunction) => void;
  logOut: () => void;
  loggingIn: boolean;
  loginError: SerializedError | null;
  refreshAuthentication: (errorCallback: VoidFunction) => void;
}

export let AuthContext = createContext<AuthContextType>(null!);

function getPersistentDataAsJSON() {
  const persistentDataJSON = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!persistentDataJSON) return null;
  return JSON.parse(persistentDataJSON);
}

function getInitialStateAccessToken() {
  return getPersistentDataAsJSON()?.accessToken;
}

function getInitialStateAIToken() {
  return getPersistentDataAsJSON()?.aiToken;
}

function getInitialStateUser() {
  const accessToken = getInitialStateAccessToken();
  const parsedAccessToken = parseJwt(accessToken);
  if (!parsedAccessToken) return null;
  return {
    username: parsedAccessToken.username,
  };
}

export default function AuthProvider({ children }: { children: ReactNode }) {
  let [user, setUser] = useState<{ username: string } | null>(getInitialStateUser);
  let [accessToken, setAccessToken] = useState<string | null>(getInitialStateAccessToken);
  let [aiToken, setAIToken] = useState<string | null>(getInitialStateAIToken);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<SerializedError | null>(null);

  const applyAuthData = (authResponseJSON: ImageServerUser) => {
    const parsedAccessToken = parseJwt(authResponseJSON.token);
    setUser({
      username: parsedAccessToken.username,
    });
    setAccessToken(authResponseJSON.token);
    setAIToken(authResponseJSON.id_token);
    setLoggingIn(false);
    setLoginError(null);

    const userInfo: UserInfo = {
      accessToken: authResponseJSON.token,
      aiToken: authResponseJSON.id_token,
    };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userInfo));
  };

  const logIn = async ({ username, password }: { username: string; password: string }, callback: VoidFunction) => {
    setLoggingIn(true);

    const authenticationUrl = backendUrl('user/authenticate/');

    const bodyFormData = new FormData();
    bodyFormData.append('email', username);
    bodyFormData.append('password', password);
    let response = null;
    try {
      response = await fetch(authenticationUrl, {
        method: 'POST',
        body: bodyFormData,
      });
    } catch (e) {
      setLoggingIn(false);
      setLoginError({
        name: 'Unable to connect to server',
        message: 'Please try again later',
      });
      return;
    }

    const resJson = await response.json();
    if (!response.ok) {
      setLoginError({ message: resJson?.user[0] });
      setLoggingIn(false);
      return;
    }
    const responseJson: ImageServerUser = resJson;

    applyAuthData(responseJson);
    callback();
  };

  let logOut = () => {
    setUser(null);
    window.localStorage.clear();
  };

  const refreshAuthentication = async (errorCallback: VoidFunction) => {
    const authenticationUrl = backendUrl('user/refresh_authentication/');

    let response = null;
    try {
      response = await getRequestWithAuthToken(authenticationUrl, null, accessToken);
    } catch (e) {
      errorCallback();
      return;
    }

    const resJson = await response.json();
    if (!response.ok) {
      errorCallback();
      return;
    }
    const responseJson: ImageServerUser = resJson;
    applyAuthData(responseJson);
  };

  const value = { user, accessToken, aiToken, logIn, logOut, refreshAuthentication, loggingIn, loginError };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
