import { Layout, Result } from 'antd';
import Cases from 'components/Cases';
import CaseViewer from 'components/CaseViewer';
import Header from 'components/Header';
import ReAuthDialog from 'components/ReAuthDialog';
import { Link, Route, Routes } from 'react-router-dom';

export default function ProtectedPage() {
  return (
    <Layout style={{ height: '100vh', bottom: 0, overflow: 'hidden', background: '#ffffff' }}>
      <Header />
      <Layout id="content" style={{ width: '100%', background: '#ffffff' }}>
        <Routes>
          <Route path="/case/:caseId/*" element={<CaseViewer />} />
          <Route path="/case/:caseId/image/:imageId/*" element={<CaseViewer />} />
          <Route index path="/" element={<Cases />} />
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to="/">Return to Case Assignments</Link>}
              />
            }
          />
        </Routes>
        <ReAuthDialog />
      </Layout>
    </Layout>
  );
}
