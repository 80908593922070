import { Layout, notification } from 'antd';
import ViewerToolbar from 'components/ViewerToolbar';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import CaseImagesSidebar from 'components/CaseImagesSidebar';
import ViewerContainer from 'components/ViewerContainer';
import { useEffect } from 'react';
import { useAppDispatch } from 'utils/hooks';
import { fetchActiveCaseDetails, selectActiveCaseError, fetchActiveImageDetails } from 'redux/slices/assignments';
import { useAuth } from 'components/auth/AuthProvider';
import { useSelector } from 'react-redux';
import AnnotationSidebar from 'components/AnnotationSidebar';
import ErrorPage from 'components/ErrorPage';

export default function CaseViewer() {
  const { caseId, imageId } = useParams();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const activeCaseError = useSelector(selectActiveCaseError);

  useEffect(() => {
    if (!caseId || !auth.accessToken) return;
    dispatch(fetchActiveCaseDetails({ activeCaseId: caseId, accessToken: auth.accessToken }));
  }, [dispatch, caseId, auth.accessToken]);

  useEffect(() => {
    if (!caseId || !imageId || !auth.accessToken) return;
    dispatch(fetchActiveImageDetails({ activeCaseId: caseId, activeImageId: imageId, accessToken: auth.accessToken }));
  }, [dispatch, caseId, imageId, auth.accessToken]);

  if (activeCaseError) {
    notification.error({
      message: 'Error loading Case',
      description: activeCaseError.message,
    });
    return <Navigate to="/" />;
  }

  return (
    <Layout style={{ height: '100%' }}>
      <ErrorPage />
      <AnnotationSidebar />
      <Layout>
        <Routes>
          <Route path="/" element={<ViewerToolbar />} />
          <Route path="/wsi_result/:fineTuningWSIResultId" element={<ViewerToolbar />} />
          <Route path="/wsi_result/:fineTuningWSIResultId/userChangesId/:userChangesId" element={<ViewerToolbar />} />
        </Routes>
        <ViewerContainer />
      </Layout>
      <CaseImagesSidebar />
    </Layout>
  );
}
