import { FilterFilled, FilterOutlined, FunnelPlotFilled } from '@ant-design/icons';
import { Badge, Button, Checkbox, Popover, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { selectAnnotationReviewFilters, setAnnotationReviewFilters } from 'redux/slices/analysis';
import { selectSelectedAnnotationIds } from '../../../redux/slices/viewer';

export default function AnnotationReviewFilter() {
  const dispatch = useDispatch();
  const annotationReviewFilters = useSelector(selectAnnotationReviewFilters);
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);

  const handleCheckboxGroupOnChange = (checkedValue: CheckboxValueType[]) => {
    dispatch(setAnnotationReviewFilters(checkedValue as Array<ReviewFilterTypes>));
  };

  const annotationFilterOptions = [
    { label: 'Show Unreviewed Annotations', value: 'show_unreviewed' },
    { label: 'Show Reviewed Annotations', value: 'show_reviewed' },
  ];

  let FilterButtonIcon = <FilterOutlined />;
  if (annotationReviewFilters.length === 1) {
    FilterButtonIcon = <FunnelPlotFilled />;
  } else if (annotationReviewFilters.length > 1) {
    FilterButtonIcon = <FilterFilled />;
  }

  return (
    <Popover
      placement="bottomLeft"
      title="Filter Annotations"
      content={
        <>
          <Checkbox.Group
            options={annotationFilterOptions}
            defaultValue={annotationReviewFilters}
            onChange={handleCheckboxGroupOnChange}
          />
        </>
      }
      trigger="click"
    >
      <Tooltip title={<span>{selectedAnnotationIds.length} Annotations Selected</span>}>
        <Button icon={FilterButtonIcon}>
          {' '}
          <Badge
            className="filter-button-selected-annotations-badge"
            showZero
            overflowCount={999}
            count={selectedAnnotationIds.length}
            style={{
              backgroundColor: selectedAnnotationIds.length > 0 ? '#6300cc' : '#aaaaaa',
              color: '#ffffff',
            }}
          />
        </Button>
      </Tooltip>
    </Popover>
  );
}
